<div class="col-md-3">
    <div class="form-group">
        <div class="panel panel-default">
            <div class="panel-heading">
                <label for="frameOption">{{frameOptions.TypeLayout.Description}}:</label>
            </div>
            <div class="panel-body">
                <select class="form-control" id="frameOption" [(ngModel)]="selectedFrameId" (ngModelChange)="updateSelectedFrame()">
                    <option *ngFor="let frame of frameOptions.Frames; let index = i;" [value]="frame.Id">{{frame.Name}}
                    </option>
                </select>
            </div>
        </div>
    </div>
</div>
