import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IWindowDataSet } from '../../model/interfaces/window-data-set';
import { WindowDataSetService } from '@system-select/web-services';

@Component({
    selector: 'app-window-data-set-header',
    templateUrl: './window-data-set-header.component.html',
})
export class WindowDataSetHeaderComponent implements OnInit, OnDestroy {
    windowDataSet: IWindowDataSet;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(
        private windowDataSetService: WindowDataSetService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        this.subscriptions.add(this.windowDataSetService.changeEmitted$.subscribe((windowDataSet) => {
            this.setHeader(windowDataSet);
        }));
        const id = +this.route.snapshot.paramMap.get('windowDataSetId');
        if (id > 0) {
            this.windowDataSetService.getById(id)
                .subscribe((windowDataSet) => {
                    this.setHeader(windowDataSet);
                });
        } else {
            this.header = 'Add Window Data Set';
            this.windowDataSet = this.windowDataSetService.getEmptyWindowDataSet();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(windowDataSet: IWindowDataSet): void {
        this.windowDataSet = windowDataSet;
        this.header = windowDataSet.WindowFrames && windowDataSet.WindowFrames.length > 0 && windowDataSet.WindowFrames[0].Archived ?
            `Window Data Set: ${windowDataSet.Name} (ARCHIVED)` :
            `Window Data Set: ${windowDataSet.Name}`;
    }

}
