import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FileUploadModule } from "ng2-file-upload";
import { NgxMaskModule } from "ngx-mask";

import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import { MtDocumentControlModule } from "@mt-ng2/document-control";
import {
    DynamicFormModule,
    DynamicFormModuleConfigToken,
} from "@mt-ng2/dynamic-form";
import { EntityComponentsAddressesModule } from "@mt-ng2/entity-components-addresses";
import { EntityComponentsDocumentsModule } from "@mt-ng2/entity-components-documents";
import { EntityComponentsNotesModule } from "@mt-ng2/entity-components-notes";
import { EntityComponentsPhonesModule } from "@mt-ng2/entity-components-phones";
import {
    EntityListModule,
    EntityListModuleConfigToken,
    IEntityListModuleConfig,
} from "@mt-ng2/entity-list-module";
import { MtNoteControlModule } from "@mt-ng2/note-control";
import { MtSearchFilterCheckboxModule } from "@mt-ng2/search-filter-checkbox-control";
import { MtSearchFilterDaterangeModule } from "@mt-ng2/search-filter-daterange-control";
import { MtSearchFilterSelectModule } from "@mt-ng2/search-filter-select-control";

import { BackButtonModule } from "@mt-ng2/back-button-module";
import { MtDateModule } from "@mt-ng2/date-module";
import { MtFabSpeedDialControlModule } from "@mt-ng2/fab-speed-dial-control";
import { MtManagedListControlModule } from "@mt-ng2/managed-list-control";
import { MtSearchBarControlModule } from "@mt-ng2/searchbar-control";
import { SharedEntitiesModule } from "@mt-ng2/shared-entities-module";

import { KeyboardShortcutModule } from "@mt-ng2/keyboard-shortcuts-module";
import { TypeAheadModule } from "@mt-ng2/type-ahead-control";
import { AuthEntityService } from "@system-select/web-services";
import { AuthEntityModule } from "../auth-entity/auth-entity.module";
import { ContactStatusService } from "../model/shared-entities/contacts/contact-status.service";
import { CommonService } from "./services/common.service";

import { ModalModule } from "@mt-ng2/modal-module";

import { MtPhotoControlModule } from "@mt-ng2/photo-control";
import { UnitsService } from "@system-select/common";
import { NavModule } from '@mt-ng2/nav-module';
export const entityListModuleConfig: IEntityListModuleConfig = {
    itemsPerPage: 50,
};

@NgModule({
    exports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    DynamicFormModule,
    NgxMaskModule,
    MtNoteControlModule,
    EntityComponentsNotesModule,
    EntityComponentsAddressesModule,
    MtManagedListControlModule,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    EntityComponentsPhonesModule,
    MtSearchFilterSelectModule,
    MtSearchFilterDaterangeModule,
    MtDisableDuringHttpCallsModule,
    EntityListModule,
    ModalModule,
    MtSearchBarControlModule,
    SharedEntitiesModule,
    MtSearchFilterCheckboxModule,
    BackButtonModule,
    MtDateModule,
    MtFabSpeedDialControlModule,
    KeyboardShortcutModule,
    MtPhotoControlModule,
    NavModule,
],
    imports: [
    AuthEntityModule,
    CommonModule,
    NgbModule,
    RouterModule,
    FormsModule,
    BrowserAnimationsModule,
    NgxMaskModule,
    FileUploadModule,
    MtNoteControlModule,
    EntityComponentsNotesModule,
    MtManagedListControlModule,
    EntityComponentsAddressesModule,
    ModalModule,
    MtDocumentControlModule,
    EntityComponentsDocumentsModule,
    EntityComponentsPhonesModule,
    MtSearchFilterSelectModule,
    MtSearchFilterDaterangeModule,
    MtDisableDuringHttpCallsModule,
    EntityListModule,
    MtSearchBarControlModule,
    SharedEntitiesModule,
    MtSearchFilterCheckboxModule,
    BackButtonModule,
    MtDateModule,
    MtFabSpeedDialControlModule,
    TypeAheadModule,
    KeyboardShortcutModule,
    MtPhotoControlModule,
    ModalModule,
    NavModule,
],
})
export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: EntityListModuleConfigToken,
                    useValue: entityListModuleConfig,
                },
                {
                    provide: DynamicFormModuleConfigToken,
                    useValue: { commonService: CommonService },
                },
                CommonService,
                AuthEntityService,
                ContactStatusService,
                UnitsService,
            ],
        };
    }
}
