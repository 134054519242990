<div class="row">
  <div class="col-md-7">
    <h2>Climate Zones</h2>
  </div>
</div>
<div>
  <mt-search-bar (searched)="search($event)"></mt-search-bar>
  <mt-search-filter-select [items]="categories" entity="Product Category" (selectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
  <br>
</div>

<mt-entity-list [entities]="climateZones" [total]="total" [(currentPage)]="currentPage" (pageChanged)="getClimateZones()"
  (itemSelectedEvent)="climateZoneSelected($event)"
  (columnSortedEvent)="columnSorted($event)"
  [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddClimateZone" class="fab-wrap-b-r">
  <a class="btn btn-primary btn-fab-lg" [routerLink]="['/climate-zones', 'add']">
    <span class="fa fa-plus"></span>
  </a>
</div>
