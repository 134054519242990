import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IGlass } from '../model/interfaces/glass';
import { IGlassColor } from '../model/interfaces/glass-color';
import { IGlassType } from '../model/interfaces/glass-type';
import { IInterlayerType } from '../model/interfaces/interlayer-type';
import { ISilkScreenColor } from '../model/interfaces/silk-screen-color';
import { ISilkScreenPattern } from '../model/interfaces/silk-screen-pattern';
import { IVendor } from '../model/interfaces/vendor';
import { GlassDynamicControlsPartial, IGlassDynamicControlsParametersPartial } from '../model/partials/glass.form-controls';
import { IDocument } from '../model/interfaces/document';

export class GlassDynamicConfig<T extends IGlass> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private glass: T,
        private glassTypes?: IGlassType[],
        private glassColors?: IGlassColor[],
        private documents?: IDocument[],
        private vendors?: IVendor[],
        private interlayerTypes?: IInterlayerType[],
        private silkScreenColors?: ISilkScreenColor[],
        private silkScreenPatterns?: ISilkScreenPattern[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IGlassDynamicControlsParametersPartial = {
            documents: this.documents,
            glassColors: this.glassColors,
            glassTypes: this.glassTypes,
            interlayerTypes: this.interlayerTypes,
            silkScreenColors: this.silkScreenColors,
            silkScreenPatterns: this.silkScreenPatterns,
            vendors: this.vendors,
        };
        const dynamicControls = new GlassDynamicControlsPartial(this.glass, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['IgdbId', 'Name', 'PublishDate', 'LeedPoints', 'Thickness', 'GlassTypes', 'GlassColors', 'Documents', 'Laminated', 'ColorR', 'ColorG', 'ColorB', 'VendorId', 'CanBeUsedForMonolithic', 'CanBeUsedForDoublePaneAsOutboardLayer', 'CanBeUsedForDoublePaneAsInboardLayer',
                'CanBeUsedForTriplePaneAsOutboardLayer', 'CanBeUsedForTriplePaneAsMiddleLayer', 'CanBeUsedForTriplePaneAsInboardLayer'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
