<div *ngIf="myProfile" class="row">
  <div class="col-md-7">
    <h2>My Profile</h2>
  </div>
</div>
<div *ngIf="user && user.Id > 0">
  <div class="row">
    <div class="col-md-6">
      <app-user-basic-info [user]="user" [canEdit]="canEdit"></app-user-basic-info>
      <br>
      <app-auth-user-portal-access *ngIf="user.Id !== currentUser.Id" [AuthUser]="user.AuthUser" [canEdit]="canEdit" (updateVersion)="updateVersion($event)"></app-auth-user-portal-access>
      <br>
      <app-auth-user-password [AuthUser]="user.AuthUser" [canEdit]="canEdit" (updateVersion)="updateVersion($event)"></app-auth-user-password>
    </div>
    <div class="col-md-6">
      <app-user-photo [user]="user" [canEdit]="canEdit"></app-user-photo>
      <br>
      <mt-common-addresses [canEdit]="canEdit" [address]="userAddress"
      (saved)="saveAddress($event)" (deleted)="deleteAddress()"></mt-common-addresses>
      <br>
      <mt-common-phone [PhoneArray]="user.UserPhones" (saved)=savePhones($event) [canEdit]="canEdit"></mt-common-phone>
      <br>
    </div>
  </div>
  <a routerLink="/users" class="btn btn-default">Close</a>
</div>
<div *ngIf="user && user.Id === 0">
  <div class="row">
    <div class="col-md-6">
      <app-user-basic-info [user]="user"></app-user-basic-info>
    </div>
  </div>
</div>
