<div class="row">
  <div class="col-md-7">
    <h2>Glass Products</h2>
  </div>
</div>
<div>
  <mt-search-bar (searched)="search($event)"></mt-search-bar>
  <mt-search-filter-select [items]="glassTypes" entity="Glass Type" (selectionChanged)="getGlasses()"></mt-search-filter-select>
  <mt-search-filter-select [items]="glassColors" entity="Glass Color" (selectionChanged)="getGlasses()"></mt-search-filter-select>
  <mt-search-filter-checkbox (searchCheckboxChanged)="selectionChanged($event)" [text]="includeArchivedText"></mt-search-filter-checkbox>

</div>


<mt-entity-list [entities]="glasses" [total]="total" [(currentPage)]="currentPage" (pageChanged)="getGlasses()"
  (itemSelectedEvent)="glassSelected($event)"
  (columnSortedEvent)="columnSorted($event)"
  [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddGlass" class="fab-wrap-b-r">
  <a class="btn btn-primary btn-fab-lg" [routerLink]="['/glasses', 'add']">
    <span class="fa fa-plus"></span>
  </a>
</div>
