import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IProductCategory } from '../../model/interfaces/product-category';

@Component({
    selector: 'product-sub-categories-list',
    template: `
        <table class="table table-bordered">
            <thead>
                <tr>
                    <th>Sub-Category Groups</th>
                    <th *ngIf="isEditing">
                        <button
                            class="btn btn-default"
                            (click)="$event.preventDefault(); addSubCategory()"
                        >
                            Add New <i class="fa fa-lg fa-plus"></i>
                        </button>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    product-sub-category
                    *ngFor="let s of subCategories; index as i"
                    [subCategories]="subCategories"
                    [index]="i"
                    [isEditing]="isEditing"
                    (onSubCategoriesUpdate)="onSubCategoriesUpdate($event)"
                ></tr>
            </tbody>
        </table>`,
})

export class ProductSubCategoriesListComponent implements OnChanges {
    @Input() isEditing: boolean;
    @Input() productCategory: IProductCategory;
    subCategories: IProductCategory[] = [];
    @Output() onUpdate: EventEmitter<IProductCategory[]> = new EventEmitter<IProductCategory[]>();

    constructor() { }

    ngOnChanges(): void {
        // On Cancel being clicked or the Product Category being saved, revert to the original Category list
        this.subCategories = [];
        if (this.productCategory && this.productCategory.ProductCategories) {
            this.subCategories = this.productCategory.ProductCategories.filter((pc) => pc.Id);
        }
    }

    addSubCategory(): void {
        this.subCategories.push({
            CustomSizingRuleId: this.productCategory.CustomSizingRuleId,
            Id: 0,
            IsLeed: false,
            Name: '',
            ParentId: this.productCategory.Id,
        });
    }

    onSubCategoriesUpdate(subCategories: IProductCategory[]): void {
        this.subCategories = subCategories;
        this.subCategories.filter((sc) => !sc.Name);
        this.onUpdate.emit(this.subCategories);
    }
}
