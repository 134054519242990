<div class="row">
    <div class="col-md-7">
        <h2>Data Exports</h2>
    </div>
</div>
<div class="row">
    <div class="col-md-4">
        <div class="miles-card padded">
            <h2>Glass Products</h2>
            <button class="btn btn-primary" (click)="getGlassProductsCSV()">Export</button>
        </div>
    </div>
</div><br>
<div class="row">
    <div class="col-md-4">
        <div class="miles-card padded">
            <h2>Gas Types</h2>
            <button class="btn btn-primary" (click)="getGasTypesCSV()">Export</button>
        </div>
    </div>
</div><br>
<div class="row">
    <div class="col-md-4">
        <div class="miles-card padded">
            <h2>Users</h2>
            <button class="btn btn-primary" (click)="getUsersCSV()">Export</button>
        </div>
    </div>
</div><br>
<div class="row">
    <div class="col-md-4">
        <div class="miles-card padded">
            <h2>Total Products</h2>
            <button class="btn btn-primary" (click)="getUserWindowTotalProductsCSV()">Export</button>
        </div>
    </div>
</div><br>
<div class="row">
    <div class="col-md-4">
        <div class="miles-card padded">
            <h2 class="leed-header">
                <span>LEED Reports</span>
                <mt-search-filter-daterange
                    [startDate]="startDate"
                    [endDate]="endDate"
                    entity="Select Date"
                    (selectionChanged)="onDateSelected($event)">
                </mt-search-filter-daterange>
            </h2>
            <button class="btn btn-primary" (click)="getLeedReportsCSV()">Export</button>
        </div>
    </div>
</div>
