import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { WindowDataSetDetailComponent } from './window-data-set-detail/window-data-set-detail.component';
import { WindowDataSetsComponent } from './window-data-set-list/window-data-sets.component';
import { WindowDataSetHeaderComponent } from './window-data-set-header/window-data-set-header.component';

import { ClaimTypes } from '../model/ClaimTypes';
import { WindowDataSetService } from '@system-select/web-services';

const windowDataSetEntityConfig = {
  claimType: ClaimTypes.WindowDataSets,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  entityIdParam: 'windowDataSetId',
  service: WindowDataSetService,
  title: 'WindowDataSet Detail',
};

const windowDataSetListRoleGuard = {
  claimType: ClaimTypes.WindowDataSets,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  title: 'WindowDataSets',
};

const windowDataSetAddRoleGuard = {
  claimType: ClaimTypes.WindowDataSets,
  claimValues: [ClaimValues.FullAccess],
};

const windowDataSetRoutes: Routes = [
  {
      canActivate: [AuthGuard],
      component: WindowDataSetsComponent,
      data: windowDataSetListRoleGuard,
      path: 'window-data-sets',
  },
  {
      canActivate: [AuthGuard],
      children: [
        {
          component: WindowDataSetDetailComponent,
          path: '',
          pathMatch: 'full',
        },
      ],
      component: WindowDataSetHeaderComponent,
      data: windowDataSetAddRoleGuard,
      path: 'window-data-sets/add',
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        component: WindowDataSetDetailComponent,
        path: '',
        pathMatch: 'full',
      },

    ],
    component: WindowDataSetHeaderComponent,
    data: windowDataSetEntityConfig,
    path: `window-data-sets/:${windowDataSetEntityConfig.entityIdParam}`,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild(
      windowDataSetRoutes,
    ),
  ],
})
export class WindowDataSetRoutingModule {

}
