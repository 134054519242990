import { IEntityListColumn, EntityListConfig, EntityListDeleteColumn, IEntityListConfig, EntityListColumn, SortDirection } from '@mt-ng2/entity-list-module';

import { IContact } from '../../interfaces/contact';

export class ContactListConfig extends EntityListConfig {

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: function (contact: IContact): string {
                        return `${contact.FirstName} ${contact.LastName}`;
                    },
                    name: 'Name',
                    sort: {
                        sortProperty: 'LastName',
                    },
                }),
                new EntityListColumn({
                    name: 'Title',
                }),
                new EntityListColumn({
                    linkFunction: function (contact: IContact): void {
                        window.open(`mailto:${contact.Email}`);
                    },
                    name: 'Email',
                }),
                new EntityListColumn({
                    accessors: ['ContactPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                }),
                new EntityListColumn({
                    accessors: [],
                    name: 'Address',
                    pipes: ['address'],
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessors: ['ContactStatus', 'Name'],
                    name: 'Status',
                }),
            ],
        };
        super(listConfig);
    }
}
