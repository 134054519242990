import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { CustomerRoutingModule } from './customer-routing.module';

import { CustomerBasicInfoComponent } from './customer-basic-info/customer-basic-info.component';
import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';

import { CustomerService } from '@system-select/web-services';
import { CustomerNotesService } from '@system-select/web-services';
import { CustomerSourceService } from '@system-select/web-services';
import { CustomerStatusService } from '@system-select/web-services';
import { CustomerContactService } from './shared-entities/customer-contact.service';
import { CustomerAddressesService } from '@system-select/web-services';
import { CustomerDocumentsService } from '@system-select/web-services';
import { CustomerPhonesService } from '@system-select/web-services';
import { CustomerAddComponent } from './customer-add/customer-add.component';

// TODO: Check for Shared
@NgModule({
    declarations: [
        CustomersComponent,
        CustomerHeaderComponent,
        CustomerAddComponent,
        CustomerDetailComponent,
        CustomerBasicInfoComponent,
        CustomerSettingsComponent,
    ],
    imports: [SharedModule, CustomerRoutingModule],
})
export class CustomerModule {
    static forRoot(): ModuleWithProviders<CustomerModule> {
        return {
            ngModule: CustomerModule,
            providers: [
                CustomerService,
                CustomerNotesService,
                CustomerAddressesService,
                CustomerDocumentsService,
                CustomerPhonesService,
                CustomerSourceService,
                CustomerStatusService,
                CustomerContactService,
            ],
        };
    }
}
