import { ModuleWithProviders, NgModule } from '@angular/core';

import { DataExportsComponent } from './data-exports.component';
import { SharedModule } from '../common/shared.module';
import { DataExportsRoutingModule } from './data-exports-routing.module';
import { UserWindowTotalProductService, LeedReportService } from '@system-select/web-services';

@NgModule({
    declarations: [DataExportsComponent],
    imports: [
        SharedModule,
        DataExportsRoutingModule,
    ],
})
export class DataExportsModule {
    static forRoot(): ModuleWithProviders<DataExportsModule> {
        return {
            ngModule: DataExportsModule,
            providers: [
                UserWindowTotalProductService,
                LeedReportService,
            ],
        };
    }
}
