import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IClimateZone } from '../../model/interfaces/climate-zone';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { ClimateZonesEntityListConfig } from './climate-zones.entity-list-config';
import { ClimateZoneService, ProductCategoryService } from '@system-select/web-services';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-climate-zones',
    templateUrl: './climate-zones.component.html',
})
export class ClimateZonesComponent implements OnInit {
    climateZones: IClimateZone [];
    currentPage = 1;
    query = '';
    total: number;
        entityListConfig = new ClimateZonesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddClimateZone = false;
    categories: MtSearchFilterItem[] = [];

    constructor(
        private climateZoneService: ClimateZoneService,
        private claimsService: ClaimsService,
        private router: Router,
        private productCategoryService: ProductCategoryService,
    ) { }

    ngOnInit(): void {
        this.canAddClimateZone = this.claimsService.hasClaim(ClaimTypes.ClimateZones, [ClaimValues.FullAccess]);
        this.productCategoryService.getSearchFilterItems()
            .subscribe((answer) => this.categories = answer);
        this.getClimateZones();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCategoryIds: number[] = this.getSelectedFilters(this.categories);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProductCategoryIds',
            valueArray: selectedCategoryIds,
        }));

        return _extraSearchParams;
    }

    getClimateZones(): void {

        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.climateZoneService.get(searchparams).subscribe(
            (answer) => {
                this.climateZones = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    search(query: string): void {
        this.query = query;
        this.getClimateZones();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getClimateZones();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getClimateZones();
    }

    climateZoneSelected(event: IItemSelectedEvent): void {
        window.open(`#/climate-zones/${event.entity.Id as number}`);
    }

}
