import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';

import { IExpandableObject } from '../expandable-object';
import { IWindowDataSet } from '../interfaces/window-data-set';

export interface IWindowDataSetDynamicControlsParameters {
    formGroup?: string;
}

export class WindowDataSetDynamicControls {

    formGroup: string;

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private windowdataset?: IWindowDataSet, additionalParameters?: IWindowDataSetDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'WindowDataSet';

        this.Form = {
            FileName: new DynamicField({
                formGroup: this.formGroup,
                label: 'File Name',
                name: 'FileName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.windowdataset && this.windowdataset.hasOwnProperty('FileName') && this.windowdataset.FileName !== null ? this.windowdataset.FileName.toString() : '',
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(100) ],
                validators: { 'required': true, 'maxlength': 100 },
                value: this.windowdataset && this.windowdataset.hasOwnProperty('Name') && this.windowdataset.Name !== null ? this.windowdataset.Name.toString() : '',
            }),
            PublishDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Publish Date',
                name: 'PublishDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.windowdataset && this.windowdataset.PublishDate || null,
            }),
        };

        this.View = {
            FileName: new DynamicLabel({
			    label: 'File Name',
			    value: this.windowdataset && this.windowdataset.hasOwnProperty('FileName') && this.windowdataset.FileName !== null ? this.windowdataset.FileName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.windowdataset && this.windowdataset.hasOwnProperty('Name') && this.windowdataset.Name !== null ? this.windowdataset.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            PublishDate: new DynamicLabel({
			    label: 'Publish Date',
			    value: this.windowdataset && this.windowdataset.PublishDate || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: InputTypes.Datepicker,
			        scale: null,
			    })
			}
            ),
        };

    }
}
