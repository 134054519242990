import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { GlassService } from '@system-select/web-services';
import { GlassTypeService } from '@system-select/web-services';
import { GlassColorService } from '@system-select/web-services';
import { VendorService } from '@system-select/web-services';
import { InterlayerTypeService } from '@system-select/web-services';
import { SilkScreenColorService } from '@system-select/web-services';
import { SilkScreenPatternService } from '@system-select/web-services';
import { IGlass } from '@system-select/model';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@system-select/model';
import { GlassesEntityListConfig } from './glasses.entity-list-config';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';

@Component({
    selector: 'app-glasses',
    templateUrl: './glasses.component.html',
})
export class GlassesComponent implements OnInit {
    glasses: IGlass [];
    currentPage = 1;
    query = '';
    total: number;
    glassTypes: MtSearchFilterItem[] = [];
    glassColors: MtSearchFilterItem[] = [];
    vendors: MtSearchFilterItem[] = [];
    interlayerTypes: MtSearchFilterItem[] = [];
    silkScreenColors: MtSearchFilterItem[] = [];
    silkScreenPatterns: MtSearchFilterItem[] = [];
        entityListConfig = new GlassesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddGlass = false;
    includeArchivedText = 'Include Archived';
    includeArchived: number;

    constructor(
        private glassService: GlassService,
        private glassGlassTypeService: GlassTypeService,
        private glassGlassColorService: GlassColorService,
        private glassVendorService: VendorService,
        private glassInterlayerTypeService: InterlayerTypeService,
        private glassSilkScreenColorService: SilkScreenColorService,
        private glassSilkScreenPatternService: SilkScreenPatternService,
        private claimsService: ClaimsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.canAddGlass = this.claimsService.hasClaim(ClaimTypes.Glasses, [ClaimValues.FullAccess]);
        this.glassGlassTypeService.getSearchFilterItems()
            .subscribe((answer) => this.glassTypes = answer);
        this.glassGlassColorService.getSearchFilterItems()
            .subscribe((answer) => this.glassColors = answer);
        this.glassVendorService.getSearchFilterItems()
            .subscribe((answer) => this.vendors = answer);
        this.glassInterlayerTypeService.getSearchFilterItems()
            .subscribe((answer) => this.interlayerTypes = answer);
        this.glassSilkScreenColorService.getSearchFilterItems()
            .subscribe((answer) => this.silkScreenColors = answer);
        this.glassSilkScreenPatternService.getSearchFilterItems()
            .subscribe((answer) => this.silkScreenPatterns = answer);
        this.getGlasses();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedGlassTypesIds: number[] = this.getSelectedFilters(this.glassTypes);
        const selectedGlassColorsIds: number[] = this.getSelectedFilters(this.glassColors);
        const selectedVendorsIds: number[] = this.getSelectedFilters(this.vendors);
        const selectedInterlayerTypesIds: number[] = this.getSelectedFilters(this.interlayerTypes);
        const selectedSilkScreenColorsIds: number[] = this.getSelectedFilters(this.silkScreenColors);
        const selectedSilkScreenPatternsIds: number[] = this.getSelectedFilters(this.silkScreenPatterns);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'includeArchived',
            value: this.includeArchived ? '1' : '0',
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'GlassTypeIds',
            valueArray: selectedGlassTypesIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'GlassColorIds',
            valueArray: selectedGlassColorsIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'VendorIds',
            valueArray: selectedVendorsIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'InterlayerTypeIds',
            valueArray: selectedInterlayerTypesIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'SilkScreenColorIds',
            valueArray: selectedSilkScreenColorsIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'SilkScreenPatternIds',
            valueArray: selectedSilkScreenPatternsIds,
        }));

        return _extraSearchParams;

}

    getGlasses(): void {

        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.glassService.get(searchparams).subscribe(
            (answer) => {
                this.glasses = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    search(query: string): void {
        this.query = query;
        this.getGlasses();
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value ? 1 : 0;
        this.currentPage = 1;
        this.getGlasses();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getGlasses();
    }

    glassSelected(event: IItemSelectedEvent): void {
        window.open(`#/glasses/${event.entity.Id as number}`);
    }

}
