import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { IFramingSystemDetailDropdown } from '../../../model/interfaces/custom/framing-system-detail-dropdown';
import { IFramingSystemDetail } from '../../../model/interfaces/framing-system-detail';

@Component({
    selector: 'app-framing-system-detail-dropdown',
    templateUrl: './framing-system-detail-dropdown.component.html',
})
export class FramingSystemDetailDropdownComponent implements OnInit {
    @Input() frameOptions: IFramingSystemDetailDropdown;
    @Output() updateFrameDetail: EventEmitter<IFramingSystemDetail> = new EventEmitter<IFramingSystemDetail>();
    selectedFrameId: number;

    constructor(
    ) { }

    ngOnInit(): void {
        this.selectedFrameId = this.frameOptions.SelectedFrameId;
    }

    updateSelectedFrame(): void {
        this.updateFrameDetail.emit({
            FrameId: this.selectedFrameId,
            FrameNumber: this.frameOptions.TypeLayout.FrameNumber,
            FramingSystemId: 0,
            Id: 0,
        });
    }
}
