import { Component, OnInit } from '@angular/core';
import { ExtraSearchParams, IEntitySearchParams, SearchParams } from '@mt-ng2/common-classes';
import { ProductDocumentService } from '@system-select/web-services';
import { IDocument, DocumentTypes } from '@system-select/model';
import { ProductDocumentsEntityListConfig } from './product-documents.entity-list-config';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';
import { ModalService } from '@mt-ng2/modal-module';

@Component({
    selector: 'product-documents-repository',
    templateUrl: 'product-documents-repository.component.html',
})

export class ProductDocumentsRepositoryComponent implements OnInit {
    productDocuments: IDocument[];
    total: number;
    query = '';
    currentPage = 1;
    itemsPerPage = 10;
    entityListConfig = new ProductDocumentsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    glassProductDocument: any;
    DocumentTypes = DocumentTypes;
    documentIdsToRemove: number[] = [];
    documentTypeId: number = DocumentTypes.GlassProduct;

    constructor(
        private productDocumentService: ProductDocumentService,
        private notificationsService: NotificationsService,
        private modalService: ModalService,
    ) { }

    ngOnInit(): void {
        this.getProductDocuments();
        this.entityListConfig.onItemDeleted.subscribe((docId) => {
            this.productDocumentService.delete(docId).subscribe(() => {
                this.notificationsService.success('Successfully removed Product Document');
                this.getProductDocuments();
            });
        });
        this.entityListConfig.onItemReplaced.subscribe((docId) => {
            this.replaceProductDocument(docId);
        });
    }

    getProductDocuments(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.productDocumentService.get(searchparams).subscribe(
            (answer) => {
                this.productDocuments = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    private buildSearch(): ExtraSearchParams[] {
        const _extraSearchParams: ExtraSearchParams[] = [];
        if (this.documentTypeId) {
            _extraSearchParams.push(new ExtraSearchParams({
                name: 'TypeId',
                value: this.documentTypeId.toString(),
            }));
        }

        return _extraSearchParams;
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getProductDocuments();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getProductDocuments();
    }

    updateDocumentTypeFilter(documentType: DocumentTypes): void {
        this.documentTypeId = documentType;
        this.currentPage = 1;
        this.getProductDocuments();
    }

    onFileChange(e: any, typeId: number): void {
        if (e.target.files.length) {
            this.productDocumentService.saveProductDocuments(typeId, e.target.files as File[]).subscribe(() => {
                this.notificationsService.success('Successfully uploaded Product Documents');
                this.getProductDocuments();
            });
        }
    }

    replaceProductDocument(docId: number): void {
        this.modalService.showModal({
            allowOutsideClick: false,
            confirmButtonText: 'Submit',
            input: 'file',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            title: 'Submit a file to replace',
        }).subscribe((res) => {
            if (res.value) {
                this.productDocumentService.replaceProductDocument(docId, res.value as File).subscribe(() => {
                    this.notificationsService.success('Successfully replaced Product Document');
                    this.getProductDocuments();
                });
            }
        });
    }

}
