import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ISelectionChangedEvent, MultiselectItem } from '@mt-ng2/multiselect-control';
import { IProductCategory } from '@system-select/model';
import { ProductCategoryService } from '@system-select/web-services';

@Component({
    selector: 'product-category-multiselect',
    templateUrl: 'product-category-multiselect.component.html',
})

export class ProductCategoryMultiselectComponent implements OnChanges {
    @Input() productCategory: IProductCategory;
    @Input() productCategorySubCategoryIds: number[];
    subCategories: MultiselectItem[] = [];
    selectedProductCategories: IProductCategory[];
    @Output() onSelectionChanged: EventEmitter<IProductCategory[]> = new EventEmitter<IProductCategory[]>();

    constructor(
        private productCategoryService: ProductCategoryService,
    ) {
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.productCategory) {
            this.productCategoryService.getProductCategorySubCategories(this.productCategory.Id).subscribe((sc) => {
                if (sc.length > 0) {
                    this.subCategories = sc.map((s) => {
                        return {
                            Item: s,
                            Selected: this.productCategorySubCategoryIds && this.productCategorySubCategoryIds.length ? this.productCategorySubCategoryIds.indexOf(s.Id) > -1 : false,
                        };
                    });
                } else {
                    this.subCategories = [];
                    this.selectedProductCategories = [];
                    this.onSelectionChanged.emit(this.selectedProductCategories);
                }
            });
        }
    }

    onSelectedProductCategoriesUpdate(event: ISelectionChangedEvent): void {
        this.selectedProductCategories = event.selectedItems as IProductCategory[];
        this.onSelectionChanged.emit(this.selectedProductCategories);
    }
}
