import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IGlass } from '../../model/interfaces/glass';
import { GlassService } from '@system-select/web-services';

@Component({
  selector: 'app-glass-header',
  templateUrl: './glass-header.component.html',
})
export class GlassHeaderComponent implements OnInit, OnDestroy {
  glass: IGlass;
  header: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    private glassService: GlassService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.glassService.changeEmitted$.subscribe((glass) => {
      this.setHeader(glass);
    }));
    const id = +this.route.snapshot.paramMap.get('glassId');
    if (id > 0) {
      this.glassService.getById(id)
      .subscribe((glass) => {
        this.setHeader(glass);
      });
    } else {
      this.header = 'Add Glass';
      this.glass = this.glassService.getEmptyGlass();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setHeader(glass: IGlass): void {
    this.glass = glass;
    this.header = `Glass: ${glass.Name}`;
  }

}
