<div *ngIf="customer">
    <div class="row">
        <div class="col-md-6">
            <app-customer-basic-info
                [customer]="customer"
                [canEdit]="canEdit"
            ></app-customer-basic-info>
            <br />
            <mt-common-phone
                [PhoneArray]="customer.CustomerPhones"
                (saved)="savePhones($event)"
                [canEdit]="canEdit"
            ></mt-common-phone>
            <br />
            <mt-common-addresses
                [canEdit]="canEdit"
                [service]="addressesService"
            ></mt-common-addresses>
            <br />
        </div>
        <div class="col-md-6">
            <mt-common-documents
                [canEdit]="canEdit"
                [service]="documentsService"
            ></mt-common-documents>
            <br />
            <mt-common-notes
                [canEdit]="canEdit"
                [service]="notesService"
            ></mt-common-notes>
            <br />
            <mt-shared-entities
                [sharedEntityId]="contactsSharedEntityId"
                [canEdit]="canEdit"
                [canAdd]="canAdd"
            ></mt-shared-entities>
        </div>
    </div>
    <a routerLink="/customers" class="btn btn-default">Close</a>
</div>
