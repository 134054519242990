import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { of } from "rxjs";

import { common } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";
import {
    GlassDynamicControlsPartial,
    GlassTypes,
    IDocument,
    IExpandableObject,
    IGlass,
    IGlassColor,
    IGlassType,
    IInterlayerType,
    ISilkScreenColor,
    ISilkScreenPattern,
    IVendor,
} from "@system-select/model";
import { GlassService } from "@system-select/web-services";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "glass",
    styles: [
        `
        .miles-form {
            overflow: hidden;
        }
        fieldset {
            border: 1px solid #bbb;
            padding: 15px;
            box-shadow: 0px 2px 6px 0px #ddd;
            border-radius: 3px;
        }
        legend {
            border-bottom: none;
            margin-bottom: 0;
            width: auto;
        }`,
    ],
    templateUrl: "./glass.component.html",
})
export class GlassComponent implements OnInit {
    @Input() glass: IGlass;
    @Input() glassColors: IGlassColor[];
    @Input() glassTypes: IGlassType[];
    @Input() documents: IDocument[];
    @Input() interlayerTypes: IInterlayerType[];
    @Input() silkScreenColors: ISilkScreenColor[];
    @Input() silkScreenPatterns: ISilkScreenPattern[];
    @Input() vendors: IVendor[];
    @Output() doneEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
    // abstract controls
    abstractGlassControls: IExpandableObject;

    glassForm: UntypedFormGroup;
    formCreated = false;
    silkScreen: boolean;
    laminated: boolean;

    showOpticsUpload: boolean;
    opticsFile: File;
    interlayerThicknesses = [0.03, 0.06, 0.09, 0.075, 0.1, 0.18];
    interlayerThickness: number;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private glassService: GlassService
    ) {}

    ngOnInit(): void {
        this.showOpticsUpload = !this.glass.OpticsFile;
        this.createForm();
        this.checkForSilkScreen(this.glass.GlassTypes.map((gt) => gt.Id));
        this.checkForLaminate(this.glass.Laminated);
        this.assignInterlayerThickness(this.glass.InterlayerThickness);
    }

    createForm(): void {
        this.getControls();
        this.glassForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractGlassControls = new GlassDynamicControlsPartial(
            this.glass,
            {
                documents: this.documents,
                formGroup: "Glass",
                glassColors: this.glassColors,
                glassTypes: this.glassTypes,
                interlayerTypes: this.interlayerTypes,
                silkScreenColors: this.silkScreenColors,
                silkScreenPatterns: this.silkScreenPatterns,
                vendors: this.vendors,
            }
        ).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Glass: this.fb.group({}),
        });
    }

    onFileChange(e: Event): void {
        this.opticsFile = null;
        const files = (e.target as HTMLInputElement).files;
        if (files.length) {
            this.opticsFile = files[0];
        }
    }

    formSubmitted(): void {
        if (!this.glassForm.valid) {
            common.markAllFormFieldsAsTouched(this.glassForm);
            this.error();
            return;
        }

        if (!this.glass.Id && !this.opticsFile) {
            this.notificationsService.warning("Optics file is required.");
            return;
        }

        if (
            this.glassForm.value.Glass.PublishDate &&
            !this.validatePublishDate(
                this.glassForm.value.Glass.PublishDate as Date
            )
        ) {
            this.notificationsService.error(
                "Publish Date cannot be set to a past date"
            );
            return;
        }

        for (const prop in this.glassForm.value.Glass) {
            if (prop in this.glass) {
                this.glass[prop] = this.glassForm.value.Glass[prop];
            }
        }

        if (this.interlayerThickness) {
            this.glass.InterlayerThickness = this.interlayerThickness;
        } else {
            this.glass.InterlayerThickness = null;
        }

        this.glass.InterlayerType = null;
        this.glass.SilkScreenColor = null;
        this.glass.SilkScreenPattern = null;
        this.glass.Vendor = null;
        this.glass.VendorId = this.glass.VendorId || null;

        if (!this.glass.Laminated) {
            this.glass.InterlayerThickness = null;
            this.glass.InterlayerTypeId = null;
        }

        if (this.glass.GlassTypes) {
            if (
                !this.glass.GlassTypes.some(
                    (gt: any) => gt === GlassTypes.SilkScreened
                )
            ) {
                this.glass.SilkScreenColorId = null;
                this.glass.SilkScreenPatternId = null;
            }
        } else {
            this.glass.SilkScreenColorId = null;
            this.glass.SilkScreenPatternId = null;
        }

        if (this.glass.GlassColors) {
            for (let i = 0; i < this.glass.GlassColors.length; i++) {
                const id = <any>this.glass.GlassColors[i];
                if (typeof id === "number") {
                    this.glass.GlassColors[i] = <IGlassColor>{
                        Id: id,
                        Name: "",
                    };
                }
            }
        } else {
            this.glass.GlassColors = [];
        }

        if (this.glass.GlassTypes) {
            for (let i = 0; i < this.glass.GlassTypes.length; i++) {
                const id = <any>this.glass.GlassTypes[i];
                if (typeof id === "number") {
                    this.glass.GlassTypes[i] = <IGlassType>{
                        Id: id,
                        Name: "",
                    };
                }
            }
        } else {
            this.glass.GlassTypes = [];
        }

        if (this.glass.Documents) {
            for (let i = 0; i < this.glass.Documents.length; i++) {
                const id = <any>this.glass.Documents[i];
                if (typeof id === "number") {
                    this.glass.Documents[i] = <IDocument>{
                        Id: id,
                        Name: "",
                    };
                }
            }
        } else {
            this.glass.Documents = [];
        }

        if (!this.glass.Id || this.glass.Id === 0) {
            // handle new glass save
            this.glass.OpticsFile = "";
            this.glassService
                .createWithFks(this.glass)
                .pipe(
                    switchMap((glassId) => {
                        this.glass.Id = glassId;
                        return this.glassService.uploadOpticsFile(
                            glassId,
                            this.opticsFile
                        );
                    })
                )
                .subscribe(() => {
                    void this.router.navigate([`/glasses/${this.glass.Id}`]);
                    this.success();
                    this.glassService.emitChange(this.glass);
                });
        } else {
            // handle existing glass save
            this.glassService
                .updateWithFks(this.glass)
                .pipe(
                    switchMap(() => {
                        return this.opticsFile
                            ? this.glassService.uploadOpticsFile(
                                  this.glass.Id,
                                  this.opticsFile
                              )
                            : of({});
                    })
                )
                .subscribe(() => {
                    this.success();
                    this.glassService.emitChange(this.glass);
                    this.doneEditing.emit(true);
                });
        }
    }

    validatePublishDate(publishDate: Date): boolean {
        // To fix mtDate being behind by a full day
        const publishDateCopy = new Date(publishDate);
        publishDateCopy.setDate(publishDateCopy.getDate() + 1);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return publishDateCopy >= today;
    }

    cancelClick(): void {
        this.doneEditing.emit(true);
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("Glass saved successfully.");
    }

    checkForSilkScreen(glassTypeIds: number[]): void {
        this.silkScreen =
            glassTypeIds &&
            glassTypeIds.some((gtId) => gtId === GlassTypes.SilkScreened);
    }

    checkForLaminate(laminate: boolean): void {
        this.laminated = laminate;
    }

    assignInterlayerThickness(interlayerThickness: number): void {
        if (interlayerThickness) {
            this.interlayerThickness = interlayerThickness;
        }
    }
}
