<ng-container *ngIf="subCategories">
    <label>Product Groups</label><br />
    <mt-multiselect
        [items]="subCategories"
        placeholder="Product Groups"
        [filterMode]="false"
        (selectionChanged)="onSelectedProductCategoriesUpdate($event)"
        [maxToShowInSelectedText]="3"
        [showSelectAllButtons]="false"
        [autoClose]="true"
    >
    </mt-multiselect>
</ng-container>
