<div *ngIf="glass && glass.Id > 0">
  <div class="row">
    <div class="col-md-9">
      <app-glass-basic-info [glass]="glass" [canEdit]="canEdit"></app-glass-basic-info><br>
    </div>
  </div>
  <a routerLink="/glasses" class="btn btn-default">Close</a>
  <button class="btn btn-danger" (click)="archiveGlass()">{{!glass.Archived ? 'Archive' : 'Unarchive'}}</button>
</div>
<div *ngIf="glass && glass.Id === 0">
  <div class="row">
    <div class="col-md-9">
      <app-glass-basic-info [glass]="glass"></app-glass-basic-info>
    </div>
  </div>
</div>
