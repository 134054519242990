import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { markAllFormFieldsAsTouched } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { forkJoin } from "rxjs";

import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { ICustomerPartial } from "@system-select/model";
import {
    CustomerService,
    CustomerSourceService,
    CustomerStatusService,
} from "@system-select/web-services";
import { CustomerDynamicConfig } from "../customer.dynamic-config";

@Component({
    selector: "app-customer-basic-info",
    templateUrl: "./customer-basic-info.component.html",
})
export class CustomerBasicInfoComponent implements OnInit {
    @Input() customer: ICustomerPartial;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    formFactory: CustomerDynamicConfig<ICustomerPartial>;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewCustomer(): boolean {
        return this.customer && this.customer.Id ? false : true;
    }

    constructor(
        private customerService: CustomerService,
        private customerStatusService: CustomerStatusService,
        private customerSourceService: CustomerSourceService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        forkJoin(
            this.customerStatusService.getItems(),
            this.customerSourceService.getItems()
        ).subscribe(() => this.setConfig());
    }

    setConfig(): void {
        this.formFactory = new CustomerDynamicConfig<ICustomerPartial>(
            this.customer,
            this.customerSourceService.items,
            this.customerStatusService.items
        );

        if (this.isNewCustomer) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewCustomer) {
            void this.router.navigate(["/customers"]);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.customer,
                form.value.Customer as ICustomerPartial
            );
            this.saveCustomer();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error(
                "Save failed.  Please check the form and try again."
            );
        }
    }

    private saveCustomer(): void {
        if (this.isNewCustomer) {
            this.customerService.create(this.customer).subscribe((answer) => {
                this.customer.Id = answer;
                this.success(true);
            });
        } else {
            this.customerService
                .updateVersion(this.customer)
                .subscribe((success) => {
                    this.customer.Version = success;
                    this.success();
                });
        }
    }

    private success(newCustomerSave?: boolean): void {
        if (newCustomerSave) {
            void this.router.navigate([`/customers/${this.customer.Id}`]);
        } else {
            this.setConfig();
            this.isEditing = false;
        }
        this.customerService.emitChange(this.customer);
        this.notificationsService.success("Customer saved successfully.");
    }
}
