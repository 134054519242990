import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClaimTypes } from '@system-select/model';
import { ProductDocumentsRepositoryComponent } from './product-documents-repository/product-documents-repository.component';

const productDocumentsRepositoryRoleGuard = {
    claimType: ClaimTypes.ProductDocuments,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Product Documents',
};

const productDocumentRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ProductDocumentsRepositoryComponent,
        data: productDocumentsRepositoryRoleGuard,
        path: 'product-documents',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(productDocumentRoutes)],
})
export class ProductDocumentRoutingModule { }
