import { EventEmitter, Injectable, Output } from '@angular/core';
import { EntityListConfig, SortDirection, EntityListColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { IDocument } from '@system-select/model';
import { ProductDocumentActionComponent } from './product-document-action.component';

@Injectable()
export class ProductDocumentsEntityListConfig extends EntityListConfig {
    @Output() onItemDeleted = new EventEmitter<number>();
    @Output() onItemReplaced = new EventEmitter<number>();

    constructor() {
        super(<IEntityListConfig>{
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessors: ['DocumentType', 'Name'],
                    name: 'Type',
                }),
                new EntityListColumn({
                    name: 'DateUpload',
                    pipes: ['date'],
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: function (doc: IDocument): string {
                        return doc.User ? `${doc.User.FirstName} ${doc.User.LastName}` : 'Anonymous User';
                    },
                    name: 'Uploaded By',
                    sort: {
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    component: ProductDocumentActionComponent,
                    name: 'Actions',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        });
    }
}
