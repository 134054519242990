import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';

import { UserRoleService } from '@system-select/web-services';
import { UserRoleRoutingModule } from './user-role-routing.module';
import { UserRolesComponent } from './user-role-list/user-roles.component';
import { UserRoleDetailComponent } from './user-role-detail/user-role-detail.component';
import { UserRoleHeaderComponent } from './user-role-header/user-role-header.component';
import { UserRoleBasicInfoComponent } from './user-role-basic-info/user-role-basic-info.component';
import { UserRolePermissionsComponent } from './user-role-permissions/user-role-permissions.component';
import { ClaimTypeService } from '@system-select/web-services';
import { ClaimValueService } from '@system-select/web-services';

@NgModule({
    declarations: [
        UserRolesComponent,
        UserRoleDetailComponent,
        UserRoleHeaderComponent,
        UserRoleBasicInfoComponent,
        UserRolePermissionsComponent,
    ],
    imports: [SharedModule, UserRoleRoutingModule],
})
export class UserRoleModule {
    static forRoot(): ModuleWithProviders<UserRoleModule> {
        return {
            ngModule: UserRoleModule,
            providers: [UserRoleService, ClaimTypeService, ClaimValueService],
        };
    }
}
