import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { forkJoin } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';

import { GlassService } from '@system-select/web-services';
import { IGlass, IGlassColor, IGlassType, IInterlayerType, ISilkScreenColor, ISilkScreenPattern, IVendor, IDocument, DocumentTypes } from '@system-select/model';
import { GlassDynamicConfig } from '../glass.dynamic-config';
import {
    SilkScreenColorService, SilkScreenPatternService,
    InterlayerTypeService, VendorService, GlassColorService,
    GlassTypeService, ProductDocumentService,
}
    from '@system-select/web-services';
import { DynamicField, DynamicLabel } from '@mt-ng2/dynamic-form';

@Component({
    selector: 'app-glass-basic-info',
    templateUrl: './glass-basic-info.component.html',
})
export class GlassBasicInfoComponent implements OnInit {
    @Input() glass: IGlass;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    glassForm: any;
    formFactory: GlassDynamicConfig<IGlass>;
    doubleClickIsDisabled = false;
    itemsLoaded = false;
    glassColors: IGlassColor[];
    glassTypes: IGlassType[];
    interlayerTypes: IInterlayerType[];
    silkScreenColors: ISilkScreenColor[];
    silkScreenPatterns: ISilkScreenPattern[];
    vendors: IVendor[];
    documents: IDocument[];
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private glassService: GlassService,
        private glassTypeService: GlassTypeService,
        private glassColorService: GlassColorService,
        private vendorService: VendorService,
        private interlayerTypeService: InterlayerTypeService,
        private silkScreenColorService: SilkScreenColorService,
        private silkScreenPatternService: SilkScreenPatternService,
        private notificationsService: NotificationsService,
        private router: Router,
        private productDocumentService: ProductDocumentService,
    ) { }

    ngOnInit(): void {
        this.isEditing = false;
        forkJoin([
            this.glassTypeService.getAll(),
            this.glassColorService.getAll(),
            this.vendorService.getAll(),
            this.interlayerTypeService.getAll(),
            this.silkScreenColorService.getAll(),
            this.silkScreenPatternService.getAll(),
            this.productDocumentService.getByDocumentType(DocumentTypes.GlassProduct),
        ]).subscribe(([glassTypes, glassColors, vendors, interlayerTypes, silkScreenColors, silkScreenPatterns, documents]: any) => {
            this.glassTypes = glassTypes,
                this.glassColors = glassColors,
                this.vendors = vendors,
                this.interlayerTypes = interlayerTypes,
                this.silkScreenColors = silkScreenColors,
                this.silkScreenPatterns = silkScreenPatterns,
                this.documents = documents;
            this.itemsLoaded = true;
            this.setConfig();
        });
    }

    setConfig(): void {
        this.formFactory = new GlassDynamicConfig<IGlass>(
            this.glass,
            this.glassTypes,
            this.glassColors,
            this.documents,
            this.vendors,
            this.interlayerTypes,
            this.silkScreenColors,
            this.silkScreenPatterns,
        );

        if (this.glass.Id === 0) {
            // new glass
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            // existing glass
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }
    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.glass.Id === 0) {
            void this.router.navigate(['/glasses']);
        } else {
            this.isEditing = false;
        }
    }

    endEditing(): void {
        this.isEditing = false;
        if (this.glass.Id === 0) {
            void this.router.navigate(['/glasses']);
        } else {
            this.glassService.getById(this.glass.Id).subscribe((glass) => {
                this.glass = glass;
                this.setConfig();
            });
        }
    }

    error(): void {
        this.notificationsService.error('Save failed.  Please check the form and try again.');
    }

    success(): void {
        this.notificationsService.success('Glass saved successfully.');
    }
}
