<div class="row">
    <div class="col-md-7">
        <h2>Product Documents</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar><br />
    <label>Filter Documents:</label>
    <div class="btn-group">
        <button
            type="button"
            class="btn btn-primary"
            (click)="updateDocumentTypeFilter(DocumentTypes.GlassProduct)"
            [ngClass]="{
                active: documentTypeId === DocumentTypes.GlassProduct
            }"
        >
            Glass
        </button>
        <button
            type="button"
            class="btn btn-primary"
            (click)="updateDocumentTypeFilter(DocumentTypes.MetalProduct)"
            [ngClass]="{
                active: documentTypeId === DocumentTypes.MetalProduct
            }"
        >
            Metal
        </button>
    </div>
</div>
<br />

<mt-entity-list
    [entities]="productDocuments"
    [total]="total"
    [(currentPage)]="currentPage"
    [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getProductDocuments()"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div class="row">
    <div class="col-md-3">
        <div>
            <label>Upload Glass Product Documents:</label>
            <input
                multiple
                id="glassProductDocuments"
                type="file"
                (change)="onFileChange($event, DocumentTypes.GlassProduct)"
            />
        </div>
    </div>
    <div class="col-md-3">
        <div>
            <label>Upload Metal Product Documents:</label>
            <input
                multiple
                id="metalProductDocuments"
                type="file"
                (change)="onFileChange($event, DocumentTypes.MetalProduct)"
            />
        </div>
    </div>
</div>
