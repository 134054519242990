import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from '@mt-ng2/auth-module';
import { PrintModeService } from '@mt-ng2/entity-list-module';
import { KeyboardShortcutHelpWindowService } from '@mt-ng2/keyboard-shortcuts-module';
import { NavService } from '@mt-ng2/nav-module';
import { environment } from './environments/environment';

@Component({
    selector: 'app-root',
    template: `
        <div
            [hidden]="appReady === null"
            class="skin-blue sidebar-mini"
            [class.sidebar-open]="!sidebarCollapsed && showNav"
            [class.sidebar-collapse]="sidebarCollapsed || !showNav"
            [class.sidebar-mini]="showNav"
        >
            <div class="wrapper">
                <mt-nav></mt-nav>
                <div
                    class="container-fluid content-wrapper"
                    [style.min-height]="showFooter ? null : '100vh'"
                >
                    <ng-progress></ng-progress>
                    <router-outlet></router-outlet>
                    <br />
                </div>
                <mt-footer></mt-footer>
            </div>
        </div>
        <div [hidden]="appReady !== null" [style.position]="'relative'">
            <div class="pulse">
                <span></span>

                <img class="logo-img " src="{{ logoFull }}" alt="Logo" />
            </div>
        </div>
    `,
})
export class AppComponent implements OnInit, OnDestroy {
    title = 'app';

    sidebarCollapsed: boolean;
    showNav: boolean ;
    showFooter: boolean;
    appReady: boolean = null;
    logoFull = `${environment.imgPath}logo-full.png`;
    subscriptions: Subscription = new Subscription();

    constructor(
        private navService: NavService,
        private authService: AuthService,
        private keyboardShortcutHelpWindowService: KeyboardShortcutHelpWindowService,
        private printModeService: PrintModeService,
    ) {
    }

    ngOnInit(): void {
        this.subscriptions.add(
            this.printModeService.printMode.subscribe((inPrintMode) => {
                this.navService.showNav.next(!inPrintMode);
                this.navService.showFooter.next(!inPrintMode);
            }),
        );
        this.subscriptions.add(
            this.authService.appReady.subscribe((answer) => {
                this.appReady = answer;
            }),
        );
        this.sidebarCollapsed = this.navService.sidebarCollapsed.getValue();
        this.subscriptions.add(
            this.navService.sidebarCollapsed.subscribe(
                (sidebarCollapsed: boolean) => {
                    this.sidebarCollapsed = sidebarCollapsed;
                },
            ),
        );

        this.showNav = this.navService.showNav.getValue();
        this.subscriptions.add(
            this.navService.showNav.subscribe((showNav: boolean) => {
                setTimeout(() => {this.showNav = showNav;});
            }),
        );

        this.showFooter = this.navService.showFooter.getValue();
        this.subscriptions.add(
            this.navService.showFooter.subscribe((showFooter: boolean) => {
                this.showFooter = showFooter;
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
