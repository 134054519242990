import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@mt-ng2/auth-module';
import { DataExportsComponent } from './data-exports.component';

const dataExportRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: DataExportsComponent,
        path: 'data-exports',
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            dataExportRoutes,
        ),
    ],
})
export class DataExportsRoutingModule {

}
