import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ICustomerPartial } from '@system-select/model';
import { CustomerService } from '@system-select/web-services';

@Component({
    templateUrl: './customer-header.component.html',
})
export class CustomerHeaderComponent implements OnInit, OnDestroy {
    customer: ICustomerPartial;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(
        private customerService: CustomerService,
        private route: ActivatedRoute,
    ) { }

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('customerId');
        // set the header based on the id
        if (id > 0) {
            this.customerService.getById(id)
                .subscribe((customer) => {
                    this.setHeader(customer);
                });
        } else {
            this.setHeader(this.customerService.getEmptyCustomer());
        }
        // subscribe to any changes in the customer service
        // which should update the header accordingly
        this.subscriptions.add(this.customerService.changeEmitted$.subscribe((customer) => {
            this.setHeader(customer);
        }));
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(customer: ICustomerPartial): void {
        this.customer = customer;
        this.header = customer && customer.Id ? `Customer: ${customer.Name}` : 'Add Customer';
    }

}
