import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { GlassRoutingModule } from './glass-routing.module';

import { GlassBasicInfoComponent } from './glass-basic-info/glass-basic-info.component';
import { GlassDetailComponent } from './glass-detail/glass-detail.component';
import { GlassesComponent } from './glass-list/glasses.component';
import { GlassHeaderComponent } from './glass-header/glass-header.component';

import { GasTypeService } from '@system-select/web-services';
import { GlassService } from '@system-select/web-services';
import { GlassColorService } from '@system-select/web-services';
import { GlassTypeService } from '@system-select/web-services';
import { InterlayerTypeService } from '@system-select/web-services';
import { SilkScreenColorService } from '@system-select/web-services';
import { SilkScreenPatternService } from '@system-select/web-services';
import { VendorService } from '@system-select/web-services';
import { GlassComponent } from './glass/glass.component';
import { GlazingSystemComponent } from './glazing-system/glazing-system.component';
import { GlazingSystemService } from '@system-select/web-services';
import { TotalWindowService } from '@system-select/web-services';
import { NgxLoadingModule } from 'ngx-loading';
import { FrameModule } from '../frames/frame.module';

@NgModule({
  declarations: [
    GlassesComponent,
    GlassHeaderComponent,
    GlassDetailComponent,
    GlassBasicInfoComponent,
    GlassComponent,
    GlazingSystemComponent,
  ],
  imports: [
    SharedModule,
    GlassRoutingModule,
    NgxLoadingModule,
    FrameModule,
  ],
})
export class GlassModule {
  static forRoot(): ModuleWithProviders<GlassModule> {
    return {
      ngModule: GlassModule,
      providers: [
        GasTypeService,
        GlassService,
        GlassColorService,
        GlassTypeService,
        GlazingSystemService,
        InterlayerTypeService,
        SilkScreenColorService,
        SilkScreenPatternService,
        TotalWindowService,
        VendorService,
    ],
    };
  }
}
