<div class="row">
  <div class="col-md-7">
    <h2>Users</h2>
  </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <br>
</div>
<mt-entity-list [entities]="users" [total]="total" [(currentPage)]="currentPage" (pageChanged)="getUsers()"
    (itemSelectedEvent)="userSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddUser" class="fab-wrap-b-r">
  <a class="btn btn-primary btn-fab-lg" [routerLink]="['/users', 'add']">
    <span class="fa fa-plus"></span>
  </a>
</div>
