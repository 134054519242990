import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { forkJoin, of } from "rxjs";
import { switchMap } from "rxjs/operators";

import { markAllFormFieldsAsTouched } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";

import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import {
    CustomSizingRuleService,
    ProductCategoryService,
} from "@system-select/web-services";
import { ICustomSizingRule } from "../../model/interfaces/custom-sizing-rule";
import { IProductCategory } from "../../model/interfaces/product-category";
import { ProductCategoryDynamicConfig } from "../product-category.dynamic-config";

@Component({
    selector: "app-product-category-basic-info",
    templateUrl: "./product-category-basic-info.component.html",
})
export class ProductCategoryBasicInfoComponent implements OnInit {
    @Input() productCategory: IProductCategory;
    @Input() canEdit: boolean;

    isEditing = false;
    isHovered: boolean;
    formFactory: ProductCategoryDynamicConfig<IProductCategory>;

    customSizingRules: ICustomSizingRule[];
    subCategories: IProductCategory[];

    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    get isNewProductCategory(): boolean {
        return this.productCategory && this.productCategory.Id ? false : true;
    }

    constructor(
        private productCategoryService: ProductCategoryService,
        private customSizingRuleService: CustomSizingRuleService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        forkJoin(this.customSizingRuleService.getItems()).subscribe(
            (answers) => {
                const [customSizingRules] = answers;
                this.customSizingRules = customSizingRules;
                this.subCategories = this.productCategory.ProductCategories;
                this.setConfig();
            }
        );
    }

    setConfig(): void {
        this.formFactory = new ProductCategoryDynamicConfig<IProductCategory>(
            this.productCategory,
            this.customSizingRules
        );

        if (this.isNewProductCategory) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewProductCategory) {
            void this.router.navigate(["/product-categories"]);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.productCategory,
                form.value.ProductCategory as IProductCategory
            );
            this.saveProductCategory();
        } else {
            markAllFormFieldsAsTouched(form);
            this.notificationsService.error(
                "Save failed.  Please check the form and try again."
            );
        }
    }

    private saveProductCategory(): void {
        if (this.isNewProductCategory) {
            this.productCategoryService
                .create(this.productCategory)
                .pipe(
                    switchMap((answer) => {
                        this.productCategory.Id = answer;
                        if (this.subCategories && this.subCategories.length) {
                            this.updateProductSubCategories(
                                this.productCategory,
                                this.subCategories
                            );
                            return this.productCategoryService.updateProductCategorySubCategories(
                                this.productCategory.Id,
                                this.subCategories
                            );
                        } else {
                            return of({});
                        }
                    })
                )
                .subscribe(() => {
                    this.success(true);
                });
        } else {
            this.productCategoryService
                .update(this.productCategory)
                .pipe(
                    switchMap(() => {
                        if (this.subCategories && this.subCategories.length) {
                            this.updateProductSubCategories(
                                this.productCategory,
                                this.subCategories
                            );
                            return this.productCategoryService.updateProductCategorySubCategories(
                                this.productCategory.Id,
                                this.subCategories
                            );
                        } else {
                            return this.productCategoryService.updateProductCategorySubCategories(
                                this.productCategory.Id,
                                []
                            );
                        }
                    })
                )
                .subscribe(() => {
                    this.success();
                });
        }
    }

    updateProductSubCategories(
        parent: IProductCategory,
        subCategories: IProductCategory[]
    ): void {
        if (subCategories && subCategories.length > 0) {
            subCategories.forEach((s) => {
                s.ParentId = parent.Id;
                s.CustomSizingRuleId = parent.CustomSizingRuleId;
            });
        }
    }

    private success(newProductCategorySave?: boolean): void {
        if (newProductCategorySave) {
            void this.router.navigate([
                `/product-categories/${this.productCategory.Id}`,
            ]);
        } else {
            this.productCategoryService
                .getById(this.productCategory.Id)
                .subscribe((pc) => {
                    this.productCategory = pc;
                    this.setConfig();
                    this.isEditing = false;
                });
        }
        this.productCategoryService.emitChange(this.productCategory);
        this.notificationsService.success(
            "Product Category saved successfully."
        );
    }

    onProductSubCategoriesUpdate(subCategories: IProductCategory[]): void {
        this.subCategories = subCategories;
    }
}
