import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { GlassDetailComponent } from './glass-detail/glass-detail.component';
import { GlassesComponent } from './glass-list/glasses.component';
import { GlassHeaderComponent } from './glass-header/glass-header.component';

import { ClaimTypes } from '../model/ClaimTypes';
import { GlazingSystemComponent } from './glazing-system/glazing-system.component';
import { GlassService } from '@system-select/web-services';

const glassEntityConfig = {
  claimType: ClaimTypes.Glasses,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  entityIdParam: 'glassId',
  service: GlassService,
  title: 'Glass Detail',
};

const glassListRoleGuard = {
  claimType: ClaimTypes.Glasses,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  title: 'Glasses',
};

const glassAddRoleGuard = {
  claimType: ClaimTypes.Glasses,
  claimValues: [ClaimValues.FullAccess],
};

const glassRoutes: Routes = [
  {
      canActivate: [AuthGuard],
      component: GlassesComponent,
      data: glassListRoleGuard,
      path: 'glasses',
  },
  {
      canActivate: [AuthGuard],
      children: [
        {
          component: GlassDetailComponent,
          path: '',
          pathMatch: 'full',
        },
      ],
      component: GlassHeaderComponent,
      data: glassAddRoleGuard,
      path: 'glasses/add',
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        component: GlassDetailComponent,
        path: '',
        pathMatch: 'full',
      },

    ],
    component: GlassHeaderComponent,
    data: glassEntityConfig,
    path: `glasses/:${glassEntityConfig.entityIdParam}`,
  },
  {
    canActivate: [AuthGuard],
    component: GlazingSystemComponent,
    data: glassListRoleGuard,
    path: 'glazing-system-performance',
},
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild(
      glassRoutes,
    ),
  ],
})
export class GlassRoutingModule {

}
