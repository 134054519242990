import { ICustomer } from '../interfaces/customer';
import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes, IDynamicFieldType, IDynamicField, DynamicLabel, SelectInputTypes, NumericInputTypes } from '@mt-ng2/dynamic-form';
import { ClaimTypes } from '../ClaimTypes';
import { GlassDynamicControls, IGlassDynamicControlsParameters } from '../form-controls/glass.form-controls';
import { IGlass } from '../interfaces/glass';
import { nameof } from '../shared-methods/shared-methods';
import { IGlassType } from '../interfaces/glass-type';
import { IGlassColor } from '../interfaces/glass-color';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IDocument } from '../interfaces/document';

export function numericDisplayFunction(value: number): string {
    return value.toString();
}
export interface IGlassDynamicControlsParametersPartial extends IGlassDynamicControlsParameters {
    glassColors: IGlassColor[],
    glassTypes: IGlassType[],
    documents: IDocument[],
}

export class GlassDynamicControlsPartial extends GlassDynamicControls {

    constructor(
        glass?: IGlass,
        additionalParameters?: IGlassDynamicControlsParametersPartial,
    ) {
        super(glass, additionalParameters);

        if (glass) {
            (<DynamicField>this.View.CanBeUsedForMonolithic).label = 'Monolithic';
            (<DynamicField>this.View.CanBeUsedForDoublePaneAsOutboardLayer).label = 'Double Pane (Outboard)';
            (<DynamicField>this.View.CanBeUsedForDoublePaneAsInboardLayer).label = 'Double Pane (Inboard)';
            (<DynamicField>this.View.CanBeUsedForTriplePaneAsOutboardLayer).label = 'Triple Pane (Outboard)';
            (<DynamicField>this.View.CanBeUsedForTriplePaneAsMiddleLayer).label = 'Triple Pane (Middle)';
            (<DynamicField>this.View.CanBeUsedForTriplePaneAsInboardLayer).label = 'Triple Pane (Inboard)';

            (<DynamicField>this.Form.CanBeUsedForMonolithic).labelHtml = '<label>Monolithic</label>';
            (<DynamicField>this.Form.CanBeUsedForMonolithic).label = 'Monolithic';

            (<DynamicField>this.Form.CanBeUsedForDoublePaneAsOutboardLayer).labelHtml = '<label>Double Pane (Outboard)</label>';
            (<DynamicField>this.Form.CanBeUsedForDoublePaneAsOutboardLayer).label = 'Double Pane (Outboard)';

            (<DynamicField>this.Form.CanBeUsedForDoublePaneAsInboardLayer).labelHtml = '<label>Double Pane (Inboard)</label>';
            (<DynamicField>this.Form.CanBeUsedForDoublePaneAsInboardLayer).label = 'Double Pane (Inboard)';

            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsOutboardLayer).labelHtml = '<label>Triple Pane (Outboard)</label>';
            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsOutboardLayer).label = 'Triple Pane (Outboard)';

            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsMiddleLayer).labelHtml = '<label>Triple Pane (Middle)</label>';
            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsMiddleLayer).label = 'Triple Pane (Middle)';

            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsInboardLayer).labelHtml = '<label>Triple Pane (Inboard)</label>';
            (<DynamicField>this.Form.CanBeUsedForTriplePaneAsInboardLayer).label = 'Triple Pane (Inboard)';

            (<DynamicField>this.View.LeedPoints).label = 'LEED Points';

            this.Form.IgdbId = new DynamicField({
                formGroup: this.formGroup,
                label: 'IGDB Id',
                name: 'IgdbId',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    numericFunctions: {
                        numberFormatDisplay: numericDisplayFunction,
                    },
                } as IDynamicFieldType),
                value: glass && glass.hasOwnProperty(nameof<IGlass>('IgdbId')) ? glass.IgdbId : null,
            } as IDynamicField);

            (<DynamicField>this.View.IgdbId).label = 'IGDB Id';

            (<DynamicField>this.Form.PublishDate).type = new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            });

            (<DynamicField>this.View.PublishDate).type = new DynamicFieldType({
                fieldType: DynamicFieldTypes.Input,
                inputType: InputTypes.DateInput,
            });

            if (productIsPublished(glass.PublishDate) && glass.Id) {
                (<DynamicField>this.Form.PublishDate).disabled = true;
            }

            this.Form.SilkScreenColorId = new DynamicField({
                formGroup: this.formGroup,
                label: 'Silk-screened Color',
                value: glass && glass.hasOwnProperty('SilkScreenColorId') && glass.SilkScreenColorId !== null ? glass.SilkScreenColorId : null,
                // tslint:disable-next-line:object-literal-sort-keys
                name: 'SilkScreenColorId',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                }),
                options: additionalParameters.silkScreenColors,
            });

            this.Form.SilkScreenPatternId = new DynamicField({
                formGroup: this.formGroup,
                label: 'Silk-screened Pattern',
                value: glass && glass.hasOwnProperty('SilkScreenPatternId') && glass.SilkScreenPatternId !== null ? glass.SilkScreenPatternId : null,
                // tslint:disable-next-line:object-literal-sort-keys
                name: 'SilkScreenPatternId',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                }),
                options: additionalParameters.silkScreenPatterns,
            });

            this.Form.GlassTypes = new DynamicField({
                formGroup: this.formGroup,
                label: 'Type',
                value: glass && glass.hasOwnProperty('GlassTypes') && glass.GlassTypes !== null ? glass.GlassTypes.map((gt) => gt.Id) : null,
                // tslint:disable-next-line:object-literal-sort-keys
                name: 'GlassTypes',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                options: additionalParameters.glassTypes,
            });

            this.Form.GlassColors = new DynamicField({
                formGroup: this.formGroup,
                label: 'Color',
                value: glass && glass.hasOwnProperty('GlassColors') && glass.GlassColors !== null ? glass.GlassColors.map((gc) => gc.Id) : null,
                // tslint:disable-next-line:object-literal-sort-keys
                name: 'GlassColors',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                }),
                options: additionalParameters.glassColors,
            });

            this.Form.Documents = new DynamicField({
                formGroup: this.formGroup,
                label: 'Documents',
                value: glass && glass.hasOwnProperty('Documents') && glass.Documents !== null ? glass.Documents.map((d) => d.Id) : null,
                // tslint:disable-next-line:object-literal-sort-keys
                name: 'Documents',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: SelectInputTypes.MultiselectDropdown,
                    maxToShowInSelectedText: 1,
                }),
                options: additionalParameters.documents,
            });

            this.View.GlassTypes = new DynamicLabel({
			    label: 'Types',
			    value: glass && glass.hasOwnProperty('GlassTypes') && glass.GlassTypes !== null
			        ? glass.GlassTypes.map((gt) => getMetaItemValue(additionalParameters.glassTypes, gt.Id)).join(', ')
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: SelectInputTypes.MultiselectDropdown,
			    })
			}
            );

            this.View.GlassColors = new DynamicLabel({
			    label: 'Colors',
			    value: glass && glass.hasOwnProperty('GlassColors') && glass.GlassColors !== null
			        ? glass.GlassColors.map((gc) => getMetaItemValue(additionalParameters.glassColors, gc.Id)).join(', ')
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: SelectInputTypes.MultiselectDropdown,
			    })
			}
            );

            this.View.Documents = new DynamicLabel({
			    label: 'Documents',
			    value: glass && glass.hasOwnProperty('Documents') && glass.Documents !== null
			        ? glass.Documents.map((d) => getMetaItemValue(additionalParameters.documents, d.Id)).join(', ')
			        : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: SelectInputTypes.MultiselectDropdown,
			    })
			}
            );

            this.Form.LeedPoints = new DynamicField({
                formGroup: this.formGroup,
                label: 'LEED Points',
                name: 'LeedPoints',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Float,
                    scale: 1,
                } as IDynamicFieldType),
                value: glass && glass.hasOwnProperty(nameof<IGlass>('LeedPoints')) ? glass.LeedPoints : null,
            } as IDynamicField);
        }
    }

}

function productIsPublished(publishDate: Date): boolean {
    return !publishDate || new Date(publishDate) < new Date();
}
