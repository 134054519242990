import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@system-select/model';
import { FrameService } from '@system-select/web-services';
import { IFrame } from '@system-select/model';

@Component({
  selector: 'app-frame-detail',
  templateUrl: './frame-detail.component.html',
})
export class FrameDetailComponent implements OnInit {
  frame: IFrame;
  editingComponent: Subject<any> = new Subject();
  canEdit: boolean;
  canAdd: boolean;
  id: number;

  constructor(
    private frameService: FrameService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    // check claims
    this.canEdit = this.claimsService.hasClaim(ClaimTypes.Frames, [ClaimValues.FullAccess]);
    this.canAdd = this.canEdit;
    // get current id from route
    this.id = +this.route.snapshot.paramMap.get('frameId');
    // try load if id > 0
    if (this.id > 0) {
      this.getFrameById(this.id);
    } else {
      // set frame to emptyFrame
      this.frame = this.frameService.getEmptyFrame();
    }
    this.editingComponent.next('');
  }

  getFrameById(id: number): void {
    this.frameService.getById(id)
      .subscribe((frame) => {
        this.frame = frame;
      });
  }
}
