import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import swal from 'sweetalert2';

import { IGlass } from '../../model/interfaces/glass';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { GlassService } from '@system-select/web-services';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { SweetAlertOptions } from 'sweetalert2';

@Component({
  selector: 'app-glass-detail',
  templateUrl: './glass-detail.component.html',
})
export class GlassDetailComponent implements OnInit {
  glass: IGlass;
  editingComponent: Subject<any> = new Subject();
  canEdit: boolean;
  canAdd: boolean;
  id: number;

  constructor(
    private glassService: GlassService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // check claims
    this.canEdit = this.claimsService.hasClaim(ClaimTypes.Glasses, [ClaimValues.FullAccess]);
    this.canAdd = this.canEdit;
    // get current id from route
    this.id = +this.route.snapshot.paramMap.get('glassId');
    // try load if id > 0
    if (this.id > 0) {
      this.getGlassById(this.id);
    } else {
      // set glass to emptyGlass
      this.glass = this.glassService.getEmptyGlass();
    }
    this.editingComponent.next('');
  }

  getGlassById(id: number): void {
    this.glassService.getById(id)
      .subscribe((glass) => {
        this.glass = glass;
      });
  }

  archiveGlass(): void {
    void swal.fire(<SweetAlertOptions>{
        focusCancel: false,
        focusConfirm: false,
        showCancelButton: true,
        showCloseButton: true,
        text: `Are you sure you want to ${this.glass.Archived ? 'unarchive' : 'archive'} this glass?`,
        title: 'Confirm',
        type: 'warning',
    }).then((answer) => {
        if (answer.value) {
            this.glass.Archived = !this.glass.Archived;
            this.glassService.updateWithFks(this.glass).subscribe((success) => {
                this.notificationsService.success(`Successfully updated glass.`);
                void this.router.navigate(['/glasses']);
            });
        }
    });
  }
}
