import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

import { NotificationsService } from "@mt-ng2/notifications-module";

import { FormGroup } from "@angular/forms";
import { common } from "@mt-ng2/common-functions";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { IAuthUser, IUserRole } from "@system-select/model";
import { AuthEntityService } from "@system-select/web-services";
import { AuthUserDynamicConfig } from "../auth-user.dynamic-config";

@Component({
    selector: "app-auth-user-portal-access",
    templateUrl: "./auth-user-portal-access.html",
})
export class AuthUserPortalAccessComponent implements OnInit {
    @Input("AuthUser") authUser: IAuthUser;
    @Input("canEdit") canEdit: boolean;
    @Output("updateVersion") updateVersion: EventEmitter<string> =
        new EventEmitter<string>();
    isHovered = false;
    isEditing: boolean;
    roles: IUserRole[];
    userId: number;
    formFactory: AuthUserDynamicConfig<IAuthUser>;
    configControls: string[] = ["Username", "RoleId"];
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private route: ActivatedRoute,
        private authEntitiyService: AuthEntityService,
        private notificationsService: NotificationsService
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.authEntitiyService.getAllRoles().subscribe((answer) => {
            this.roles = answer.body as unknown as IUserRole[];
            this.setConfig();
        });
        this.userId = +this.route.snapshot.params.userId;
    }

    setConfig(): void {
        this.formFactory = new AuthUserDynamicConfig<IAuthUser>(
            this.authUser,
            this.roles,
            this.configControls
        );
        const config = this.formFactory.getForUpdate();
        this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
        this.formObject = config.formObject?.map((x) => new DynamicField(x));
    }

    formSubmitted(form: FormGroup): void {
        if (form.valid) {
            this.authEntitiyService
                .updatePortalAccess(
                    this.authUser.Id,
                    form.value.AuthUser.Username as string,
                    form.value.AuthUser.RoleId as number
                )
                .subscribe(() => {
                    this.success();
                    this.authUser.RoleId = form.value.AuthUser.RoleId;
                    this.authUser.Username = form.value.AuthUser.Username;
                    this.setConfig();
                    this.isEditing = false;
                });
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    changeAccess(): void {
        this.authUser.HasAccess = !this.authUser.HasAccess;
        this.authEntitiyService
            .changeAccess(this.authUser.Id, this.authUser.HasAccess)
            .subscribe(() => {
                this.isEditing = false;
                this.success();
            });
    }
    edit(): void {
        this.isEditing = true;
    }

    cancel(): void {
        this.isEditing = false;
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("Saved Successfully");
    }
}
