<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && glass.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Glass Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
    <div
        *ngIf="glass.InterlayerThickness"
        class="display-padded ng-star-inserted"
    >
        <b>Interlayer Thickness:</b
        ><span> {{ glass.InterlayerThickness }}"</span>
    </div>
    <div *ngIf="glass.InterlayerType" class="display-padded ng-star-inserted">
        <b>Interlayer Type:</b><span> {{ glass.InterlayerType.Name }}</span>
    </div>
    <div *ngIf="glass.SilkScreenColor" class="display-padded ng-star-inserted">
        <b>Silk-screened Color:</b
        ><span> {{ glass.SilkScreenColor.Name }}</span>
    </div>
    <div
        *ngIf="glass.SilkScreenPattern"
        class="display-padded ng-star-inserted"
    >
        <b>Silk-screened Pattern:</b
        ><span> {{ glass.SilkScreenPattern.Name }}</span>
    </div>
</div>
<glass
    *ngIf="isEditing && glass && itemsLoaded"
    [glass]="glass"
    (doneEditing)="endEditing()"
    [glassTypes]="glassTypes"
    [glassColors]="glassColors"
    [documents]="documents"
    [vendors]="vendors"
    [interlayerTypes]="interlayerTypes"
    [silkScreenColors]="silkScreenColors"
    [silkScreenPatterns]="silkScreenPatterns"
>
</glass>
