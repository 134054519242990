import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IProductCategory } from '../interfaces/product-category';
import { ICustomSizingRule } from '../interfaces/custom-sizing-rule';

export interface IProductCategoryDynamicControlsParameters {
    formGroup?: string;
    customSizingRules?: ICustomSizingRule[];
}

export class ProductCategoryDynamicControls {

    formGroup: string;
    customSizingRules: ICustomSizingRule[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private productcategory?: IProductCategory, additionalParameters?: IProductCategoryDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ProductCategory';
        this.customSizingRules = additionalParameters && additionalParameters.customSizingRules || undefined;

        this.Form = {
            CustomSizingRuleId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Custom Sizing Rule',
                name: 'CustomSizingRuleId',
                options: this.customSizingRules,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.productcategory && this.productcategory.CustomSizingRuleId || null,
            }),
            IsLeed: new DynamicField({
                formGroup: this.formGroup,
                label: 'Is Leed',
                name: 'IsLeed',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.productcategory && this.productcategory.hasOwnProperty('IsLeed') && this.productcategory.IsLeed !== null ? this.productcategory.IsLeed : false,
            }),
            Name: new DynamicField({
                formGroup: this.formGroup,
                label: 'Name',
                name: 'Name',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(150) ],
                validators: { 'required': true, 'maxlength': 150 },
                value: this.productcategory && this.productcategory.hasOwnProperty('Name') && this.productcategory.Name !== null ? this.productcategory.Name.toString() : '',
            }),
            ParentId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Parent',
                name: 'ParentId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.productcategory && this.productcategory.ParentId || null,
            }),
        };

        this.View = {
            CustomSizingRuleId: new DynamicLabel({
			    label: 'Custom Sizing Rule',
			    value: getMetaItemValue(this.customSizingRules as unknown as IMetaItem[], this.productcategory && this.productcategory.hasOwnProperty('CustomSizingRuleId') && this.productcategory.CustomSizingRuleId !== null ? this.productcategory.CustomSizingRuleId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            IsLeed: new DynamicLabel({
			    label: 'Is Leed',
			    value: this.productcategory && this.productcategory.hasOwnProperty('IsLeed') && this.productcategory.IsLeed !== null ? this.productcategory.IsLeed : false,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Checkbox,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Name: new DynamicLabel({
			    label: 'Name',
			    value: this.productcategory && this.productcategory.hasOwnProperty('Name') && this.productcategory.Name !== null ? this.productcategory.Name.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            ParentId: new DynamicLabel({
			    label: 'Parent',
			    value: this.productcategory && this.productcategory.ParentId || null,
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Numeric,
			        inputType: NumericInputTypes.Integer,
			        scale: null,
			    })
			}
            ),
        };

    }
}
