import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { IClimateZone } from '../../model/interfaces/climate-zone';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { ClimateZoneService } from '@system-select/web-services';

@Component({
  selector: 'app-climate-zone-detail',
  templateUrl: './climate-zone-detail.component.html',
})
export class ClimateZoneDetailComponent implements OnInit {
  climateZone: IClimateZone;
  editingComponent: Subject<any> = new Subject();
  canEdit: boolean;
  canAdd: boolean;
  id: number;

  constructor(
    private climateZoneService: ClimateZoneService,
    private claimsService: ClaimsService,
    private route: ActivatedRoute,
    private notificationsService: NotificationsService,
  ) {}

  ngOnInit(): void {
    // check claims
    this.canEdit = this.claimsService.hasClaim(ClaimTypes.ClimateZones, [ClaimValues.FullAccess]);
    this.canAdd = this.canEdit;
    // get current id from route
    this.id = +this.route.snapshot.paramMap.get('climateZoneId');
    // try load if id > 0
    if (this.id > 0) {
      this.getClimateZoneById(this.id);
    } else {
      // set climateZone to emptyClimateZone
      this.climateZone = this.climateZoneService.getEmptyClimateZone();
    }
    this.editingComponent.next('');
  }

  getClimateZoneById(id: number): void {
    this.climateZoneService.getById(id)
      .subscribe((climateZone) => {
        this.climateZone = climateZone;
      });
  }
}
