import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { NotificationsService } from "@mt-ng2/notifications-module";

import { common } from "@mt-ng2/common-functions";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { IUserRole } from "@system-select/model";
import { UserRoleService } from "@system-select/web-services";
import { UserRoleDynamicConfig } from "../user-role.dynamic-config";

@Component({
    selector: "app-user-role-basic-info",
    templateUrl: "./user-role-basic-info.component.html",
})
export class UserRoleBasicInfoComponent implements OnInit {
    @Input() userRole: IUserRole;
    @Input() canEdit: boolean;
    roles: IUserRole[];
    isEditing: boolean;
    isHovered: boolean;
    userRoleForm: UntypedFormGroup;
    formFactory: UserRoleDynamicConfig<IUserRole>;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private userRoleService: UserRoleService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.setConfig();
    }

    setConfig(): void {
        this.formFactory = new UserRoleDynamicConfig<IUserRole>(this.userRole);
        if (this.userRole.Id === 0) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.userRole.Id === 0) {
            void this.router.navigate(["/roles"]);
        } else {
            this.isEditing = false;
        }
    }
    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.userRole,
                form.value.UserRole as IUserRole
            );
            this.userRoleService
                .saveUserRole(this.userRole)
                .subscribe((answer) => {
                    this.success();
                    this.userRoleService.emitChange(this.userRole);
                    if (this.userRole.Id === 0) {
                        void this.router.navigate([`/roles/${answer.Id}`]);
                    } else {
                        this.isEditing = false;
                        this.setConfig();
                    }
                });
        } else {
            common.markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("User Role saved successfully.");
    }
}
