import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IClimateZone } from '../../model/interfaces/climate-zone';
import { ClimateZoneService } from '@system-select/web-services';

@Component({
  selector: 'app-climate-zone-header',
  templateUrl: './climate-zone-header.component.html',
})
export class ClimateZoneHeaderComponent implements OnInit, OnDestroy {
  climateZone: IClimateZone;
  header: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    private climateZoneService: ClimateZoneService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.climateZoneService.changeEmitted$.subscribe((climateZone) => {
      this.setHeader(climateZone);
    }));
    const id = +this.route.snapshot.paramMap.get('climateZoneId');
    if (id > 0) {
      this.climateZoneService.getById(id)
      .subscribe((climateZone) => {
        this.setHeader(climateZone);
      });
    } else {
      this.header = 'Add Climate Zone';
      this.climateZone = this.climateZoneService.getEmptyClimateZone();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setHeader(climateZone: IClimateZone): void {
    this.climateZone = climateZone;
    this.header = `Climate Zone: ${climateZone.Name}`;
  }

}
