<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing && windowDataSet.Id > 0"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Window Data Set Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
    <div class="display-padded">
        <span><b>LEED Points:</b> {{ leedPoints | number : "1.2-2" }}</span
        ><br />
        <span><b>COG Product:</b>{{ getCOGLabel() }}</span
        ><br />
        <span><b>Custom Sizing Available:</b>{{ getCustomSizingLabel() }}</span
        ><br />
        <span><b>Documents:</b> {{ getDocumentsLabel() }}</span
        ><br />
        <span><b>Product Category:</b>{{ getProductCategoryLabel() }}</span
        ><br />
        <span><b>Groups: </b>{{ getProductCategorySubCategoriesLabel() }}</span>
        <br />
        <span
            ><b>System Depth: </b>{{ getItemLabel(selectedSystemDepth) }}</span
        >
        <br />
        <span><b>Sightline: </b>{{ getItemLabel(selectedSightline) }}</span
        ><br />
        <span
            ><b>Site Link:</b>
            <a href="{{ siteLink }}" target="_blank">{{ siteLink }}</a></span
        ><br />
    </div>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Window Data Set Info</h4>
    <mt-dynamic-form
        [config]="formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
    >
        <div>
            <label>LEED Points</label>
            <input
                class="form-control"
                type="number"
                [(ngModel)]="leedPoints"
            />
        </div>
        <div>
            <label>COG Product</label><br />
            <div class="btn-group">
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': !isCOG,
                        'btn-primary active': isCOG
                    }"
                    (click)="toggleIsCOG(true)"
                >
                    Yes
                </button>
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': isCOG,
                        'btn-primary active': !isCOG
                    }"
                    (click)="toggleIsCOG(false)"
                >
                    No
                </button>
            </div>
        </div>
        <div>
            <label>Custom Sizing Available</label><br />
            <div class="btn-group">
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': !hasCustomSizing,
                        'btn-primary active': hasCustomSizing
                    }"
                    (click)="toggleHasCustomSizing(true)"
                >
                    On
                </button>
                <button
                    type="button"
                    class="btn"
                    [ngClass]="{
                        'btn-default': hasCustomSizing,
                        'btn-primary active': !hasCustomSizing
                    }"
                    (click)="toggleHasCustomSizing(false)"
                >
                    Off
                </button>
            </div>
        </div>
        <div>
            <label>Documents</label><br />
            <mt-multiselect
                [items]="documents"
                placeholder="Documents"
                [filterMode]="false"
                (selectionChanged)="onSelectedProductDocumentsUpdate($event)"
                [maxToShowInSelectedText]="1"
                [showSelectAllButtons]="false"
                [autoClose]="true"
            >
            </mt-multiselect>
        </div>
        <div>
            <label>Product Category</label>
            <select class="form-control" [(ngModel)]="selectedProductCategory">
                <option
                    *ngFor="let category of filteredProductCategories"
                    [ngValue]="category"
                >
                    {{ category.Name }} {{ category.IsLeed ? "(LEED)" : "" }}
                </option>
            </select>
        </div>
        <div *ngIf="selectedProductCategory">
            <product-category-multiselect
                [productCategory]="selectedProductCategory"
                [productCategorySubCategoryIds]="productCategorySubCategoryIds"
                (onSelectionChanged)="onSelectedProductCategoriesUpdate($event)"
            ></product-category-multiselect>
        </div>
        <div>
            <label>System Depth</label>
            <select class="form-control" [(ngModel)]="selectedSystemDepth">
                <option *ngFor="let depth of systemDepths" [ngValue]="depth">
                    {{ depth.Name }}
                </option>
            </select>
        </div>
        <div>
            <label>Sightline</label>
            <select class="form-control" [(ngModel)]="selectedSightline">
                <option
                    *ngFor="let sightline of sightlines"
                    [ngValue]="sightline"
                >
                    {{ sightline.Name }}
                </option>
            </select>
        </div>
        <div>
            <label>Site Link</label>
            <input class="form-control" type="text" [(ngModel)]="siteLink" />
        </div>
        <br />
        <div *ngIf="showDbUpload">
            <input id="db" type="file" (change)="onFileChange($event)" />
        </div>
        <div *ngIf="!showDbUpload && windowDataSet.FileName">
            <label>MDB File</label>
            <div class="btn-group">
                <button
                    class="btn btn-default"
                    (click)="$event.preventDefault(); downloadMDBFile()"
                >
                    Download
                </button>
                <button
                    class="btn btn-danger"
                    (click)="$event.preventDefault(); removeMDBFile()"
                >
                    Remove
                </button>
            </div>
        </div>
        <div *ngIf="!showDbUpload">
            <label>Update MDB</label>
            <input
                id="db"
                type="file"
                (change)="promptMDBFileReplacement($event)"
            />
        </div>
        <br />
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Save
        </button>
        <button
            type="button"
            Class="btn btn-flat btn-default"
            (click)="cancelClick()"
        >
            Cancel
        </button>
    </mt-dynamic-form>
</div>
