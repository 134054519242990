import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { FrameDynamicControls, IFrameDynamicControlsParameters } from '../model/form-controls/frame.form-controls';
import { IFrame } from '../model/interfaces/frame';
import { IFrameType } from '../model/interfaces/frame-type';

export class FrameDynamicConfig<T extends IFrame> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private frame: T,
        private frameTypes?: IFrameType[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IFrameDynamicControlsParameters = {
            frameTypes: this.frameTypes,
        };
        const dynamicControls = new FrameDynamicControls(this.frame, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'FrameTypeId', 'Pfd', 'UValue', 'UEdge', 'Shgc'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }
    
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
