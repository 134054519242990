import { Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { SearchParams, ExtraSearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { UserService, UserRoleService } from '@system-select/web-services';
import { IUser } from '@system-select/model';
import { ClaimTypes } from '@system-select/model';
import { UsersEntityListConfig } from './users.entity-list-config';
import { entityListModuleConfig } from '../../common/shared.module';
import { IEntitySearchParams } from '@mt-ng2/common-classes';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
})
export class UsersComponent implements OnInit {
    searchControl = new UntypedFormControl();
    users: IUser[];
    currentPage = 1;
    query = '';
    total: number;
    canAddUser = false;

    entityListConfig = new UsersEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();
    userRoles: MtSearchFilterItem[] = [];

    constructor(
        private userService: UserService,
        private claimsService: ClaimsService,
        private router: Router,
        private userRoleService: UserRoleService,
    ) { }

    ngOnInit(): void {
        this.canAddUser = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        this.userRoleService.getAll()
        .subscribe((answer) => this.userRoles = answer.map((userRole) => {
            return {
                Item: {
                    Id: userRole.Id,
                    Name: userRole.Name,
                },
                Selected: false,
            };
        }));
        this.getUsers();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedUserRoleIds: number[] = this.getSelectedFilters(this.userRoles);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'UserRoleIds',
            valueArray: selectedUserRoleIds,
        }));
        return _extraSearchParams;
    }

    getUsers(): void {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();
        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take:  entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);

        this.userService.get(searchparams).subscribe(
            (answer) => {
                this.users = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    search(query: string): void {
        this.query = query;
        this.getUsers();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getUsers();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getUsers();
    }

    userSelected(event: IItemSelectedEvent): void {
        window.open(`#/users/${event.entity.Id as number}`);
    }

}
