import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { appNavMenu } from './app-nav-menu.config';
import { NavSidebarContentContainer, INavSidebarService, NavSidebarHeaderItem } from '@mt-ng2/nav-module';

@Injectable()
export class NavSidebarService implements INavSidebarService {
    content: BehaviorSubject<NavSidebarContentContainer[]>;

    navigationMenu: NavSidebarContentContainer;

    constructor() {
        this.navigationMenu = new NavSidebarContentContainer({
            header: new NavSidebarHeaderItem({ content: `NAVIGATION` }),
            rows: appNavMenu,
        });

        this.content = new BehaviorSubject([this.navigationMenu]);
    }
}
