<div *ngIf="framingSystem">
    <div class="row">
        <div class="col-md-7">
            <h2>Manage Framing System Detail</h2>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="name">Name:</label>
                <input
                    type="text"
                    class="form-control"
                    id="name"
                    [(ngModel)]="framingSystem.Name"
                />
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="framingSystemType">Framing System Type:</label>
                <select
                    [disabled]="framingSystem.Id > 0"
                    class="form-control"
                    id="framingSystemType"
                    [(ngModel)]="framingSystem.FramingSystemTypeId"
                    (ngModelChange)="framingSystemTypeChange($event)"
                >
                    <option
                        *ngFor="let type of framingSystemTypes"
                        [value]="type.Id"
                    >
                        {{ type.Name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <div class="form-group">
                <label for="presetCategory">Preset Category:</label>
                <select
                    class="form-control"
                    id="presetCategory"
                    [(ngModel)]="selectedPresetCategoryId"
                    (ngModelChange)="presetCategoryChange($event)"
                >
                    <option
                        *ngFor="
                            let parentCategory of framingSystemParentCategories
                        "
                        [value]="parentCategory.Id"
                    >
                        {{ parentCategory.Name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="presetSubCategory">Preset Sub Category:</label>
                <select
                    class="form-control"
                    id="presetSubCategory"
                    [disabled]="!selectedPresetCategoryId"
                    [(ngModel)]="selectedPresetSubCategoryId"
                    (ngModelChange)="presetSubCategoryChange($event)"
                >
                    <option
                        *ngFor="
                            let subCategory of filteredFramingSystemSubCategories
                        "
                        [value]="subCategory.Id"
                    >
                        {{ subCategory.Name }}
                    </option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label for="framingSystemCategory"
                    >Framing System Category:</label
                >
                <select
                    class="form-control"
                    id="framingSystemCategory"
                    [disabled]="!selectedPresetSubCategoryId"
                    [(ngModel)]="framingSystem.FramingSystemCategoryId"
                >
                    <option
                        *ngFor="let category of filteredFramingSystemCategories"
                        [value]="category.Id"
                    >
                        {{ category.Name }}
                    </option>
                </select>
            </div>
        </div>
    </div>
    <div class="row">
        <div
            *ngIf="
                filteredFramingSystemTypeLayouts &&
                filteredFramingSystemTypeLayouts.length > 0 &&
                framingSystemDetailDropdownOptions.length > 0
            "
        >
            <div
                *ngFor="
                    let dropdown of framingSystemDetailDropdownOptions;
                    let i = index
                "
            >
                <app-framing-system-detail-dropdown
                    [frameOptions]="dropdown"
                    (updateFrameDetail)="updateFramingSystemDetail($event)"
                ></app-framing-system-detail-dropdown>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-3">
            <button
                type="submit"
                mtDisableClickDuringHttpCalls
                Class="btn btn-flat btn-success"
                (click)="saveFramingSystem()"
            >
                Save
            </button>
            <button
                type="button"
                Class="btn btn-flat btn-default"
                (click)="cancelClick()"
            >
                Cancel
            </button>
        </div>
    </div>
</div>
