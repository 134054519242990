import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { IWindowDataSet } from '../model/interfaces/window-data-set';
import { WindowDataSetDynamicControlsPartial } from '../model/partials/window-data-set.form-controls';

export class WindowDataSetDynamicConfig<T extends IWindowDataSet> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private windowDataSet: T,
        private configControls?: string[],
    ) {
        super();
        const dynamicControls = new WindowDataSetDynamicControlsPartial(this.windowDataSet);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'PublishDate'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
