import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { FrameService } from '@system-select/web-services';

import { ClaimTypes } from '@system-select/model';
import { FramesComponent } from './frame-list/frames.component';
import { FrameDetailComponent } from './frame-detail/frame-detail.component';
import { FrameHeaderComponent } from './frame-header/frame-header.component';

const frameEntityConfig = {
    claimType: ClaimTypes.Frames,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'frameId',
    service: FrameService,
    title: 'Frame Detail',
};

const frameListRoleGuard = {
    claimType: ClaimTypes.Frames,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Frames',
};

const frameAddRoleGuard = {
    claimType: ClaimTypes.Frames,
    claimValues: [ClaimValues.FullAccess],
};

const frameRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: FramesComponent,
        data: frameListRoleGuard,
        path: 'frames',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: FrameDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: FrameHeaderComponent,
        data: frameAddRoleGuard,
        path: 'frames/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            {
                component: FrameDetailComponent,
                path: '',
                pathMatch: 'full',
            },
        ],
        component: FrameHeaderComponent,
        data: frameEntityConfig,
        path: `frames/:${frameEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            frameRoutes,
        ),
    ],
})
export class FrameRoutingModule {

}
