import {
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
} from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, of } from "rxjs";

import { common } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";
import {
    FrameDynamicControls,
    IExpandableObject,
    IFrame,
} from "@system-select/model";
import { FrameService, FrameTypeService } from "@system-select/web-services";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "frame-product",
    templateUrl: "./frame.component.html",
})
export class FrameComponent implements OnInit {
    @Input() frame: IFrame;
    @Output() doneEditing: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() updatedFrame: EventEmitter<IFrame> = new EventEmitter<IFrame>();
    // abstract controls
    abstractFrameControls: IExpandableObject;

    frameForm: UntypedFormGroup;
    formCreated = false;

    showThermUpload: boolean;
    thermFile: File;

    showMeshUpload: boolean;
    meshFile: File;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private fb: UntypedFormBuilder,
        private cdr: ChangeDetectorRef,
        private notificationsService: NotificationsService,
        private frameTypeService: FrameTypeService,
        private frameService: FrameService
    ) {}

    ngOnInit(): void {
        this.showThermUpload = !this.frame.Id;
        this.showMeshUpload = false;

        this.checkForMeshData();

        forkJoin(this.frameTypeService.getItems()).subscribe(() => {
            this.createForm();
        });
    }

    createForm(): void {
        this.getControls();
        this.frameForm = this.assignFormGroups();
        this.formCreated = true;
        this.cdr.detectChanges();
    }

    getControls(): void {
        this.abstractFrameControls = new FrameDynamicControls(this.frame, {
            formGroup: "Frame",
            frameTypes: this.frameTypeService.items,
        }).Form;
    }

    assignFormGroups(): UntypedFormGroup {
        return this.fb.group({
            Frame: this.fb.group({}),
        });
    }

    onThermFileChange(e: Event): void {
        this.thermFile = null;
        const files = (e.target as HTMLInputElement).files;
        if (files.length) {
            this.thermFile = files[0];
        }
    }

    onMeshFileChange(e: Event): void {
        this.meshFile = null;
        const files = (e.target as HTMLInputElement).files;
        if (files.length) {
            this.meshFile = files[0];
        }
    }

    checkForMeshData(): void {
        this.frameService.meshDataExists(this.frame.Id).subscribe(
            () => {
                this.showMeshUpload = false;
            },
            () => {
                this.showMeshUpload = true;
            }
        );
    }

    formSubmitted(): void {
        if (!this.frameForm.valid) {
            common.markAllFormFieldsAsTouched(this.frameForm);
            this.error();
        }

        if (!this.frame.Id && !this.thermFile) {
            this.notificationsService.warning("THERM file is required.");
            return;
        }

        for (const prop in this.frameForm.value.Frame) {
            if (prop in this.frame) {
                this.frame[prop] = this.frameForm.value.Frame[prop];
            }
        }

        if (!this.frame.Id || this.frame.Id === 0) {
            // handle new frame save
            this.frameService
                .create(this.frame)
                .pipe(
                    switchMap((frameId) => {
                        this.frame.Id = frameId;
                        return this.frameService.uploadThermFile(
                            frameId,
                            this.thermFile
                        );
                    })
                )
                .pipe(
                    switchMap(() => {
                        return this.meshFile
                            ? this.frameService.uploadMeshFile(
                                  this.frame.Id,
                                  this.meshFile
                              )
                            : of({});
                    })
                )
                .subscribe(() => {
                    void this.router.navigate([`/frames/${this.frame.Id}`]);
                    this.success();
                    this.frameService.emitChange(this.frame);
                });
        } else {
            // handle existing frame save
            this.frameService
                .update(this.frame)
                .pipe(
                    switchMap(() => {
                        return this.thermFile
                            ? this.frameService.uploadThermFile(
                                  this.frame.Id,
                                  this.thermFile
                              )
                            : of({});
                    })
                )
                .pipe(
                    switchMap(() => {
                        return this.meshFile
                            ? this.frameService.uploadMeshFile(
                                  this.frame.Id,
                                  this.meshFile
                              )
                            : of({});
                    })
                )
                .subscribe((updatedFrame: IFrame) => {
                    this.success();
                    // Explicitly return the updated frame to update the THERM file values on the front end
                    if (this.thermFile) {
                        this.frame = updatedFrame;
                    }
                    this.updatedFrame.emit(this.frame);
                    this.frameService.emitChange(this.frame);
                    this.doneEditing.emit(true);
                });
        }
    }

    cancelClick(): void {
        void this.router.navigate(["../"], { relativeTo: this.route });
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("Frame saved successfully.");
    }
}
