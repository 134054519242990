<div
    class="miles-editable miles-card padded"
    *ngIf="!isEditing"
    (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false"
    (click)="edit()"
    [style.cursor]="canEdit ? 'pointer' : 'default'"
>
    <h4>
        Password
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <i>
        <b *ngIf="canEdit"> Click to Edit Password </b>
        <b *ngIf="!canEdit"> You do not have access to this feature.</b>
    </i>
</div>
<div *ngIf="isEditing" class="miles-form padded">
    <h4>Password</h4>
    <mt-dynamic-form
        [config]="formObject"
        ([isEditing])="(isEditing)"
        (submitted)="formSubmitted($event)"
    >
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-flat btn-default"
            (click)="this.isEditing = false"
        >
            Cancel
        </button>
    </mt-dynamic-form>
    <br />
</div>
