import { DynamicField, DynamicFieldType, DynamicFieldTypes, InputTypes } from '@mt-ng2/dynamic-form';
import { IWindowDataSetDynamicControlsParameters, WindowDataSetDynamicControls } from '../form-controls/window-data-set.form-controls';
import { IWindowDataSet } from '../interfaces/window-data-set';

export class WindowDataSetDynamicControlsPartial extends WindowDataSetDynamicControls {

    constructor(
        windowDataSet?: IWindowDataSet,
        additionalParameters?: IWindowDataSetDynamicControlsParameters,
    ) {
        super(windowDataSet, additionalParameters);

        (<DynamicField>this.Form.PublishDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });

        (<DynamicField>this.View.PublishDate).type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Input,
            inputType: InputTypes.DateInput,
        });

        if (productIsPublished(windowDataSet.PublishDate) && windowDataSet.Id) {
            (<DynamicField>this.Form.PublishDate).disabled = true;
        }
    }

}

function productIsPublished(publishDate: Date): boolean {
    return !publishDate || new Date(publishDate) < new Date();
}
