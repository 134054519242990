<div class="row">
    <div class="col-md-7">
      <h2>Frames</h2>
    </div>
  </div>
  <div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="frameTypes" entity="Frame Type" (selectionChanged)="getFrames()"></mt-search-filter-select>
  </div>

  <mt-entity-list [entities]="frames" [total]="total" [(currentPage)]="currentPage" (pageChanged)="getFrames()"
    (itemSelectedEvent)="frameSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
  </mt-entity-list>

  <div *ngIf="canAddFrame" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/frames', 'add']">
      <span class="fa fa-plus"></span>
    </a>
  </div>
