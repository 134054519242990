<div>
  <div class="row">
    <div class="col-md-7">
        <h2>
            <mt-route-back-button></mt-route-back-button>
            {{header}}
        </h2>
    </div>
  </div>
</div>
<router-outlet></router-outlet>
