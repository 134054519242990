import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IFramingSystem } from '../../model/interfaces/framing-system';

export class FramingSystemEntityListConfig extends EntityListConfig {

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
              new EntityListColumn({
                  name: 'Name',
              }),
              new EntityListColumn({
                  accessors: ['FramingSystemCategory', 'Name'],
                  name: 'Framing System Category',
              }),
              new EntityListColumn({
                accessorFunction: function (framingSystem: IFramingSystem): string {
                    return `${framingSystem.FramingSystemType.Name}`;
                },
                name: 'Preset Type',
            }),
            ],
        };
        super(listConfig);
    }
}
