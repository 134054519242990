<div class="row">
    <div class="col-md-7">
        <h2>Window Data Sets</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select
        [items]="categories"
        entity="Product Category"
        (selectionChanged)="filterSelectionChanged()"
    ></mt-search-filter-select>
    <mt-search-filter-select
        [items]="groups"
        entity="Product Group"
        (selectionChanged)="filterSelectionChanged()"
    ></mt-search-filter-select>
    <mt-search-filter-checkbox
        (searchCheckboxChanged)="selectionChanged($event)"
        [text]="includeArchivedText"
    ></mt-search-filter-checkbox>
    <br />
</div>

<mt-entity-list
    [entities]="windowDataSets"
    [total]="total"
    [(currentPage)]="currentPage"
    (pageChanged)="getWindowDataSets()"
    (itemSelectedEvent)="windowDataSetSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig"
>
</mt-entity-list>

<div *ngIf="canAddWindowDataSet" class="fab-wrap-b-r">
    <a
        class="btn btn-primary btn-fab-lg"
        [routerLink]="['/window-data-sets', 'add']"
    >
        <span class="fa fa-plus"></span>
    </a>
</div>
