import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';

import { IUser } from '@system-select/model';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { UserService } from '@system-select/web-services';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IAddress } from '@system-select/model';

import { ClaimTypes } from '@system-select/model';
import { AuthService } from '@mt-ng2/auth-module';
import { IPhone } from '../../../app/model/public-api';

@Component({
    selector: 'app-user-detail',
    templateUrl: './user-detail.component.html',
})

export class UserDetailComponent implements OnInit {
    user: IUser;
    currentUser;
    editingComponent: Subject<any> = new Subject();
    canEdit = true;
    userAddress: IAddress;
    canAdd: boolean;
    myProfile: boolean;

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.Users, [ClaimValues.FullAccess]);
        // get current id from route
        let id = +this.route.snapshot.paramMap.get('userId');
        // check if this is the my-profile path
        if (this.route.snapshot.routeConfig.path === 'my-profile') {
            this.myProfile = true;
            id = this.currentUser = this.authService.currentUser.getValue().Id;
        }
        // try load if id > 0
        if (id > 0) {
            this.getUserById(id);
        } else {
            // set user to emptyUser
            this.user = this.userService.getEmptyUser();
        }

        this.editingComponent.next('');
    }

    getUserById(id: number): void {
        this.userService.getById(id)
            .subscribe((user) => {
                this.user = user;
                this.canEdit = this.user.AuthUser && this.user.AuthUser.IsEditable ? this.canEdit : false;
                this.currentUser = this.authService.currentUser.getValue();
                if (user.Address) {
                    this.userAddress = user.Address;
                } else {
                    this.userAddress = null;
                }
            });
    }

    saveAddress(address: IAddress): void {
        this.userService.saveAddress(this.user.Id, address)
            .subscribe((answer) => {
                address.Id = answer;
                this.notificationsService.success('Address Saved Successfully');
                this.user.Address = address;
                this.userAddress = address;
            });
    }

    deleteAddress(): void {
        this.userService.deleteAddress(this.user.Id)
            .subscribe(() => {
                this.notificationsService.success('Address Deleted Successfully');
                this.userAddress = null;
                this.user.Address = null;
            });
    }

    savePhones(phoneCollection: any): void {
        this.userService.savePhones(this.user.Id, phoneCollection as IPhone)
            .subscribe(() => {
                this.notificationsService.success('Phones Saved Successfully');
                this.user.UserPhones = phoneCollection.Phones;
                this.editingComponent.next('');
            });
    }

    updateVersion(version): void {
        this.user.Version = version;
    }

}
