import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '@system-select/model';
import { FrameTypeService } from '@system-select/web-services';
import { FrameService } from '@system-select/web-services';
import { FramesEntityListConfig } from './frames.entity-list-config';
import { IFrame } from '@system-select/model';

@Component({
    selector: 'app-frames',
    templateUrl: './frames.component.html',
})
export class FramesComponent implements OnInit {
    frames: IFrame [];
    currentPage = 1;
    query = '';
    total: number;
    frameTypes: MtSearchFilterItem[] = [];
    entityListConfig = new FramesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddFrame = false;

    constructor(
        private frameService: FrameService,
        private claimsService: ClaimsService,
        private frameTypeService: FrameTypeService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.canAddFrame = this.claimsService.hasClaim(ClaimTypes.Frames, [ClaimValues.FullAccess]);
        this.frameTypeService.getSearchFilterItems()
            .subscribe((answer) => this.frameTypes = answer);
        this.getFrames();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const frameTypeIds: number[] = this.getSelectedFilters(this.frameTypes);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'FrameTypeIds',
            valueArray: frameTypeIds,
        }));

        return _extraSearchParams;
}

    getFrames(): void {

        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.frameService.get(searchparams).subscribe(
            (answer) => {
                this.frames = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    search(query: string): void {
        this.query = query;
        this.getFrames();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getFrames();
    }

    frameSelected(event: IItemSelectedEvent): void {
        void this.router.navigate(['/frames', event.entity.Id]);
    }

}
