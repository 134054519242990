<h2>Customer Settings</h2>
<div>
  <div class="row">
    <div class="col-md-6">
      <mt-meta-item-managed-list [service]="customerSourceService" [dynamicForm]="customerSourceDynamicForm"
        [canAdd]="canAdd" [canRemove]="canRemove"></mt-meta-item-managed-list>
    </div>

    <div class="col-md-6">
    </div>
  </div>
</div>
<br/>
<a routerLink="./customers" class="btn btn-default btn-flat">Close</a>
