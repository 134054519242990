import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '@system-select/model';
import { FramingSystemsComponent } from './framing-system-list/framing-system.component';
import { FramingSystemService } from '@system-select/web-services';
import { FramingSystemDetailComponent } from './framing-system-detail/framing-system-detail.component';

const framingSystemEntityConfig = {
    claimType: ClaimTypes.FramingSystems,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'framingSystemId',
    service: FramingSystemService,
    title: 'Framing System Detail',
};

const framingSystemListRoleGuard = {
    claimType: ClaimTypes.FramingSystems,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Framing Systems',
};

const framingSystemRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: FramingSystemsComponent,
        data: framingSystemListRoleGuard,
        path: 'framing-systems',
    },
    { path: `framing-systems-detail/:${framingSystemEntityConfig.entityIdParam}`, component: FramingSystemDetailComponent, canActivate: [AuthGuard] },
    { path: `framing-systems-detail`, component: FramingSystemDetailComponent, canActivate: [AuthGuard] },
];

@NgModule({
    exports: [RouterModule],
    imports: [
        RouterModule.forChild(
            framingSystemRoutes,
        ),
    ],
})
export class FramingSystemRoutingModule {

}
