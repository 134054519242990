import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';

import { ClimateZoneDynamicControls, IClimateZoneDynamicControlsParameters } from '../model/form-controls/climate-zone.form-controls';
import { IClimateZone } from '../model/interfaces/climate-zone';
import { IProductCategory } from '@system-select/model';

export class ClimateZoneDynamicConfig<T extends IClimateZone> extends DynamicConfig<T> implements IDynamicConfig<T> {

    constructor(
        private climateZone: T,
        private productCategories: IProductCategory[],
        private configControls?: string[],
    ) {
        super();
        const additionalParams: IClimateZoneDynamicControlsParameters = {
            productCategories: this.productCategories,
        };
        const dynamicControls = new ClimateZoneDynamicControls(this.climateZone, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'ProductCategoryId', 'UFactorLow', 'UFactorHigh', 'ShgcLow', 'ShgcHigh', 'VisibleTransmittanceLow', 'VisibleTransmittanceHigh', 'CondensationResistanceLow', 'CondensationResistanceHigh'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
