import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { IFrame } from '@system-select/model';
import { FrameService } from '@system-select/web-services';

@Component({
  selector: 'app-frame-header',
  templateUrl: './frame-header.component.html',
})
export class FrameHeaderComponent implements OnInit, OnDestroy {
  frame: IFrame;
  header: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    private frameService: FrameService,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(this.frameService.changeEmitted$.subscribe((frame) => {
      this.setHeader(frame);
    }));
    const id = +this.route.snapshot.paramMap.get('frameId');
    if (id > 0) {
      this.frameService.getById(id)
      .subscribe((frame) => {
        this.setHeader(frame);
      });
    } else {
      this.header = 'Add Frame';
      this.frame = this.frameService.getEmptyFrame();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setHeader(frame: IFrame): void {
    this.frame = frame;
    this.header = `Frame: ${frame.Name}`;
  }

}
