import { Component, OnInit } from '@angular/core';
import { forkJoin } from 'rxjs';
import { GlassService } from '@system-select/web-services';
import { GasTypeService } from '@system-select/web-services';
import { IGlass } from '@system-select/model';
import { IGasType } from '@system-select/model';
import { GlazingSystemService, IGlazingPerformanceRequest, IGlazingPerformance, IGlazingGap } from '@system-select/web-services';
import { UnitsService } from '@system-select/common';
import { ITotalWindowPerformance, TotalWindowService, ITotalWindowPerformanceRequest } from '@system-select/web-services';
import { finalize } from 'rxjs/operators';
import { FramingSystemService } from '@system-select/web-services';
import { IFramingSystem } from '@system-select/model';

@Component({
    selector: 'app-glazing-system',
    styles: [`
        .panel-body label {
            color: #777;
        }
        pre.results {
            max-height: 500px;
            overflow-y: auto;
            margin: 0;
        }
        table {
            margin: 0;
        }
        table tr {
            cursor: auto;
        }
        table.glazing-table th, table.glazing-table td,
        table.total-window-table th, table.total-window-table td {
            text-align: center;
        }
        .panel-body {
            overflow-x: auto;
        }
        .glazing-header-panel {
            padding-bottom: 0;
        }
        .glazing-detail-panel {
            max-height: 445px;
            overflow-y: auto;
            padding-top: 0;
        }
        .results-panel {
            position: relative;
        }
    `],
    templateUrl: './glazing-system.component.html',
})
export class GlazingSystemComponent implements OnInit {

    MAX_LAYERS = 10;
    STANDARD_GAP_WIDTH = 12.7;

    glasses: IGlass[];
    gasTypes: IGasType[];
    framingSystems: IFramingSystem[];

    numberOfLayers = 2;
    layers: number[];
    repeatableLayers: number[];

    height = 1000;
    width = 1000;

    glassLayers: IGlass[] = [];
    gapLayerTypes: IGasType[] = [];
    gapLayerWidths: number[] = [];
    framingSystem: IFramingSystem;

    loadingResult = false;

    glazingResult: IGlazingPerformance;
    totalWindowResult: ITotalWindowPerformance;

    constructor(
        private framingSystemService: FramingSystemService,
        private glassService: GlassService,
        private gasTypeService: GasTypeService,
        private glazingSystemService: GlazingSystemService,
        private totalWindowService: TotalWindowService,
        private unitsService: UnitsService,
    ) { }

    ngOnInit(): void {
        this.layers = Array(this.numberOfLayers).fill(0).map((x, i) => i + 1);
        this.repeatableLayers = this.layers.slice(1);
        this.glassLayers.push(null);

        for (let i = 0; i < this.repeatableLayers.length; i++) {
            this.glassLayers.push(null);
            this.gapLayerTypes.push(null);
            this.gapLayerWidths[i] = this.STANDARD_GAP_WIDTH;
        }

        forkJoin(
            this.glassService.getAll(),
            this.gasTypeService.getAll(),
            this.framingSystemService.getAll(),
        ).subscribe((answer) => {
            this.glasses = answer[0];
            this.gasTypes = answer[1];
            this.framingSystems = answer[2];
        });
    }

    addLayer(): void {
        if (this.numberOfLayers === this.MAX_LAYERS) {
            return;
        }

        this.numberOfLayers++;
        const lastLayerNum = this.layers[this.layers.length - 1];
        this.layers.push(lastLayerNum + 1);
        this.repeatableLayers.push(lastLayerNum + 1);

        this.glassLayers.push(null);
        this.gapLayerTypes.push(null);
        this.gapLayerWidths.push(this.STANDARD_GAP_WIDTH);
    }

    removeLayer(): void {
        if (this.numberOfLayers === 1) {
            return;
        }

        this.numberOfLayers--;
        this.layers.pop();
        this.repeatableLayers.pop();
        this.glassLayers.pop();
        this.gapLayerTypes.pop();
        this.gapLayerWidths.pop();
    }

    tryCalculate(): void {
        if (
            this.width
            && this.height
            && this.glassLayers.every((x) => x !== null)
            && this.gapLayerTypes.every((x) => x !== null)
            && this.gapLayerWidths.every((x) => x > 0)
            && this.framingSystem
        ) {
            this.calculate();
        } else {
            this.glazingResult = null;
            this.totalWindowResult = null;
        }
    }

    calculate(): void {
        const glassIds: number[] = this.glassLayers.map((glass) => glass.Id);
        const gapData: IGlazingGap[] = this.repeatableLayers
            .map((layerNumber, i) => {
                return {
                    GasTypeId: this.gapLayerTypes[i].Id,
                    Width: this.gapLayerWidths[i],
                };
            });

        const glazingPerformanceRequest: IGlazingPerformanceRequest = {
            GapData: gapData,
            GlassIds: glassIds,
            Height: this.height,
            SpandrelColor: null,
            SpandrelTypeId: null,
            Width: this.width,
        };

        const totalWindowRequset: ITotalWindowPerformanceRequest = {
            FramingSystemId: this.framingSystem.Id,
            GlazingPerformanceRequests: [glazingPerformanceRequest, glazingPerformanceRequest],
        };

        this.loadingResult = true;
        forkJoin(
                this.glazingSystemService.getPerformance(glazingPerformanceRequest),
                this.totalWindowService.getPerformance(totalWindowRequset),
            )
            .pipe(finalize(() => this.loadingResult = false))
            .subscribe((answer) => {
                this.glazingResult = answer[0];
                this.totalWindowResult = answer[1];
            });
    }

    mmToIn(mm: number): number {
        return this.unitsService.mmToIn(mm);
    }

}
