import { IEntityListConfig, EntityListConfig, EntityListColumn, SortDirection, SelectTypes } from '@mt-ng2/entity-list-module';
import { IProductCategory } from '../../model/interfaces/product-category';

export class ProductCategoriesEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessorFunction: (x: IProductCategory) => x.IsLeed ? 'Yes' : '',
                    name: 'Leed',
                    sort: {
                        disableSort: true,
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
