import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { ProductCategoryService } from '@system-select/web-services';
import { IProductCategory } from '../../model/interfaces/product-category';

@Component({
    templateUrl: './product-category-header.component.html',
})
export class ProductCategoryHeaderComponent implements OnInit, OnDestroy {
    productCategory: IProductCategory;
    header: string;
    subscriptions: Subscription = new Subscription();

    constructor(private productCategoryService: ProductCategoryService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        // get the id from the route
        const id = +this.route.snapshot.paramMap.get('productCategoryId');
        // set the header based on the id
        if (id > 0) {
            this.productCategoryService.getById(id).subscribe((productCategory) => {
                this.setHeader(productCategory);
            });
        } else {
            this.setHeader(this.productCategoryService.getEmptyProductCategory());
        }
        // subscribe to any changes in the productCategory service
        // which should update the header accordingly
        this.subscriptions.add(
            this.productCategoryService.changeEmitted$.subscribe((productCategory) => {
                this.setHeader(productCategory);
            }),
        );
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    setHeader(productCategory: IProductCategory): void {
        this.productCategory = productCategory;
        this.header = productCategory && productCategory.Id ? `Product Category: ${productCategory.Name}` : 'Add ProductCategory';
    }
}
