import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { IWindowDataSet } from '@system-select/model';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';
import { WindowDataSetsEntityListConfig } from './window-data-sets.entity-list-config';
import { WindowDataSetService, ProductCategoryService } from '@system-select/web-services';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { ISearchFilterCheckboxValueChangedEvent } from '@mt-ng2/search-filter-checkbox-control';

@Component({
    selector: 'app-window-data-sets',
    templateUrl: './window-data-sets.component.html',
})
export class WindowDataSetsComponent implements OnInit {
    windowDataSets: IWindowDataSet[];
    currentPage = 1;
    query = '';
    total: number;
    entityListConfig = new WindowDataSetsEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddWindowDataSet = false;
    categories: MtSearchFilterItem[] = [];
    includeArchivedText = 'Include Archived';
    includeArchived: number;
    groups: MtSearchFilterItem[] = [];

    constructor(
        private windowDataSetService: WindowDataSetService,
        private claimsService: ClaimsService,
        private router: Router,
        private productCategoryService: ProductCategoryService,
    ) { }

    ngOnInit(): void {
        this.canAddWindowDataSet = this.claimsService.hasClaim(ClaimTypes.WindowDataSets, [ClaimValues.FullAccess]);
        this.productCategoryService.getSearchFilterItems().subscribe((categories) => {
            this.categories = categories.filter((pc) => pc.Item.ParentId === null);
            this.groups = categories.filter((pc) => pc.Item.ParentId !== null);
        });
        this.getWindowDataSets();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj
            .filter((item) => item.Selected)
            .map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCategoryIds: number[] = this.getSelectedFilters(this.categories);
        const selectedGroupIds: number[] = this.getSelectedFilters(this.groups);
        const _extraSearchParams: ExtraSearchParams[] = [];
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'includeArchived',
            value: this.includeArchived ? '1' : '0',
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProductCategoryIds',
            valueArray: selectedCategoryIds,
        }));
        _extraSearchParams.push(new ExtraSearchParams({
            name: 'ProductGroupIds',
            valueArray: selectedGroupIds,
        }));

        return _extraSearchParams;
    }

    getWindowDataSets(): void {

        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: (search && search.length > 0 ? search : ''),
            skip: (this.currentPage - 1) * entityListModuleConfig.itemsPerPage,
            take: entityListModuleConfig.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        this.windowDataSetService.get(searchparams).subscribe(
            (answer) => {
                this.windowDataSets = answer.body;
                this.total = +answer.headers.get('X-List-Count');
            },
        );
    }

    search(query: string): void {
        this.query = query;
        this.getWindowDataSets();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getWindowDataSets();
    }

    selectionChanged(event: ISearchFilterCheckboxValueChangedEvent): void {
        this.includeArchived = event.value ? 1 : 0;
        this.currentPage = 1;
        this.getWindowDataSets();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getWindowDataSets();
    }

    windowDataSetSelected(event: IItemSelectedEvent): void {
        window.open(`#/window-data-sets/${event.entity.Id as number}`);
    }

}
