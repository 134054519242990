import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { UserRoutingModule } from './user-routing.module';

import { UserBasicInfoComponent } from './user-basic-info/user-basic-info.component';
import { UserDetailComponent } from './user-detail/user-detail.component';
import { UsersComponent } from './user-list/users.component';
import { UserHeaderComponent } from './user-header/user-header.component';
import { UserPhotoComponent } from './user-photo/user-photo.component';

import { UserService } from '@system-select/web-services';

@NgModule({
  declarations: [
    UsersComponent,
    UserHeaderComponent,
    UserDetailComponent,
    UserBasicInfoComponent,
    UserPhotoComponent,
  ],
  imports: [SharedModule, UserRoutingModule],
})
export class UserModule {
  static forRoot(): ModuleWithProviders<UserModule> {
    return {
      ngModule: UserModule,
      providers: [UserService],
    };
  }
}
