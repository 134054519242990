import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IProductCategory } from '../../model/public-api';

@Component({
    selector: '[product-sub-category]',
    template: `
        <ng-container *ngIf="isEditing">
            <td>
                <input
                    type="text"
                    class="form-control"
                    id="sub-category-name"
                    [(ngModel)]="subCategories[index].Name"
                    (ngModelChange)="onUpdate()"
                    [ngModelOptions]="{ standalone: true }"
                />
            </td>
            <td>
                <button class="btn btn-default" (click)="removeSubCategory(index)">
                    <i class="fa fa-lg fa-trash"></i>
                </button>
            </td>
    </ng-container>
    <ng-container *ngIf="!isEditing">
        <td>{{subCategories[index].Name}}</td>
    </ng-container>

`,
})

export class ProductSubCategoryComponent {
    @Input() subCategories: IProductCategory[];
    @Input() index: number;
    @Input() isEditing: boolean;
    @Output() onSubCategoriesUpdate: EventEmitter<IProductCategory[]> = new EventEmitter<IProductCategory[]>();
    constructor() { }

    onUpdate(): void {
        this.onSubCategoriesUpdate.emit(this.subCategories);
    }

    removeSubCategory(index: number): void {
        this.subCategories.splice(index, 1);
        this.onUpdate();
    }
}
