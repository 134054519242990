import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { forkJoin } from "rxjs";

import { NotificationsService } from "@mt-ng2/notifications-module";

import { IFrame } from "@system-select/model";
import { FrameDynamicConfig } from "../frame.dynamic-config";
import { FrameService } from "@system-select/web-services";
import { FrameTypeService } from "@system-select/web-services";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";

@Component({
    selector: "app-frame-basic-info",
    templateUrl: "./frame-basic-info.component.html",
})
export class FrameBasicInfoComponent implements OnInit {
    @Input() frame: IFrame;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    formFactory: FrameDynamicConfig<IFrame>;
    doubleClickIsDisabled = false;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private frameTypeService: FrameTypeService,
        private notificationsService: NotificationsService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        forkJoin(this.frameTypeService.getItems()).subscribe(() =>
            this.setConfig()
        );
    }

    setConfig(): void {
        this.formFactory = new FrameDynamicConfig<IFrame>(
            this.frame,
            this.frameTypeService.items
        );

        if (this.frame.Id === 0) {
            // new frame
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        } else {
            // existing frame
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map((x) => new DynamicField(x));
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.frame.Id === 0) {
            void this.router.navigate(["/frames"]);
        } else {
            this.isEditing = false;
        }
    }

    endEditing(): void {
        this.isEditing = false;
    }

    updateFrame(updatedFrame: IFrame): void {
        this.frame = updatedFrame;
        this.setConfig();
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("Frame saved successfully.");
    }
}
