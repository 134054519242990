import { Component, OnInit } from '@angular/core';

import { IProductCategory } from '../../model/interfaces/product-category';
import { ProductCategoryService } from '@system-select/web-services';

@Component({
    templateUrl: './product-category-add.component.html',
})
export class ProductCategoryAddComponent implements OnInit {
    productCategory: IProductCategory;
    canEdit = true; // route guard ensures this component wouldn't be loaded if user didn't have permission already

    constructor(private productcategoryService: ProductCategoryService) {}

    ngOnInit(): void {
        this.productCategory = this.productcategoryService.getEmptyProductCategory();
    }
}
