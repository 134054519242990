import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject } from "rxjs";

import { ClaimsService, ClaimValues } from "@mt-ng2/auth-module";
import { NotificationsService } from "@mt-ng2/notifications-module";

import {
    ClaimTypes,
    IFrame,
    IFramingSystem,
    IFramingSystemDetail,
    IFramingSystemDetailDropdown,
    IFramingSystemType,
    IFramingSystemTypeLayout,
} from "@system-select/model";
import {
    FrameService,
    FramingSystemCategoryService,
    FramingSystemService,
    FramingSystemTypeLayoutService,
    FramingSystemTypeService,
} from "@system-select/web-services";

@Component({
    selector: "app-framing-system-detail",
    templateUrl: "./framing-system-detail.component.html",
})
export class FramingSystemDetailComponent implements OnInit {
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    framingSystem: IFramingSystem;
    framingSystemParentCategories = [];
    framingSystemSubCategories = [];
    filteredFramingSystemSubCategories = [];
    framingSystemCategories = [];
    filteredFramingSystemCategories = [];
    selectedPresetCategoryId: number;
    selectedPresetSubCategoryId: number;
    framingSystemTypes: IFramingSystemType[];
    framingSystemTypeLayouts: IFramingSystemTypeLayout[];
    filteredFramingSystemTypeLayouts: IFramingSystemTypeLayout[];
    framingSystemDetailDropdownOptions: IFramingSystemDetailDropdown[] = [];
    frames: IFrame[];
    framingSystemDetails: IFramingSystemDetail[] = [];

    constructor(
        private frameService: FrameService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private framingSystemService: FramingSystemService,
        private framingSystemCategoryService: FramingSystemCategoryService,
        private framingSystemTypeService: FramingSystemTypeService,
        private framingSystemTypeLayoutService: FramingSystemTypeLayoutService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.FramingSystems, [
            ClaimValues.FullAccess,
        ]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get("framingSystemId");
        // try load if id > 0
        if (this.id > 0) {
            this.framingSystemService
                .getById(this.id)
                .subscribe((framingSystem) => {
                    this.framingSystem = framingSystem;
                    this.framingSystemDetails =
                        framingSystem.FramingSystemDetails;
                    this.initialzeDropdownOptions();
                });
        } else {
            this.framingSystem =
                this.framingSystemService.getEmptyFramingSystem();
            this.initialzeDropdownOptions();
        }
    }

    initialzeDropdownOptions(): void {
        this.initializeFramingSystemTypes();
        this.initializeFramingSystemCategories();
        this.initializeFrames();
    }

    presetCategoryChange(id: number): void {
        this.filteredFramingSystemSubCategories =
            this.framingSystemSubCategories.filter((sc) => {
                return sc.ParentId === id * 1;
            });
        if (this.framingSystem.Id > 0) {
            this.selectedPresetSubCategoryId = null;
            this.framingSystem.FramingSystemCategoryId = null;
        }
    }

    presetSubCategoryChange(id: number): void {
        this.filteredFramingSystemCategories =
            this.framingSystemCategories.filter((sc) => {
                return sc.ParentId === id * 1;
            });
    }

    framingSystemTypeChange(event: any): void {
        this.framingSystemDetails = [];
        this.framingSystemDetailDropdownOptions = [];
        this.filteredFramingSystemTypeLayouts =
            this.framingSystemTypeLayouts.filter((tl) => {
                return tl.FramingSystemTypeId === event * 1;
            });
        this.filteredFramingSystemTypeLayouts.forEach((layout) => {
            this.framingSystemDetailDropdownOptions.push({
                Frames: this.filterFramesByTypeLayout(layout),
                SelectedFrameId: null,
                TypeLayout: layout,
            });
        });
    }

    updateFramingSystemDetail(event: IFramingSystemDetail): void {
        const existingFramingDetail = this.framingSystemDetails.find((fd) => {
            return fd.FrameNumber === event.FrameNumber;
        });
        if (existingFramingDetail) {
            this.framingSystemDetails.splice(
                this.framingSystemDetails.indexOf(existingFramingDetail),
                1
            );
            event.FramingSystemId = this.framingSystem.Id;
        }
        this.framingSystemDetails.push(event);
    }

    saveFramingSystem(): void {
        this.framingSystem.FramingSystemDetails = this.framingSystemDetails;
        if (
            this.framingSystemDetailDropdownOptions.length ===
            this.framingSystem.FramingSystemDetails.length
        ) {
            if (this.framingSystem.Id > 0) {
                this.framingSystemService
                    .updateWithFks(this.framingSystem)
                    .subscribe(() => {
                        this.notificationsService.success(
                            "Framing System Created"
                        );
                        void this.router.navigate([`/framing-systems`]);
                    });
            } else {
                this.framingSystemService
                    .create(this.framingSystem, { nullOutFKs: false })
                    .subscribe(() => {
                        this.notificationsService.success(
                            "Framing System Updated"
                        );
                        void this.router.navigate([`/framing-systems`]);
                    });
            }
        } else {
            this.notificationsService.error(
                "Error occured. All parts of the preset require a selection."
            );
        }
    }

    cancelClick(): void {
        void this.router.navigate(["/framing-systems"]);
    }

    filterFramesByTypeLayout(layout: IFramingSystemTypeLayout): IFrame[] {
        return this.frames.filter((frame) => {
            return frame.FrameTypeId === layout.FrameTypeId;
        });
    }

    frameDropdownSort(
        a: IFramingSystemDetailDropdown,
        b: IFramingSystemDetailDropdown
    ): number {
        if (a.TypeLayout.Description > b.TypeLayout.Description) {
            return 1;
        } else if (a.TypeLayout.Description < b.TypeLayout.Description) {
            return -1;
        }
        return 0;
    }

    // Initialization Values for Dropdowns //
    // ================================= //

    initializeFramingSystemTypes(): void {
        this.framingSystemTypeService.getAll().subscribe((types) => {
            this.framingSystemTypes = types;
        });
    }

    initializeFrames(): void {
        this.frameService.getAll().subscribe((frames) => {
            this.frames = frames;
            this.initializeFramingSystemTypeLayouts();
        });
    }

    initializeFramingSystemTypeLayouts(): void {
        this.framingSystemTypeLayoutService
            .getFramingSystemTypeLayouts()
            .subscribe((layouts) => {
                this.framingSystemTypeLayouts = layouts;
                this.filteredFramingSystemTypeLayouts =
                    this.framingSystemTypeLayouts.slice();
                if (this.framingSystem.Id > 0) {
                    this.assignSelectedFramingSystemTypeLayouts();
                }
            });
    }

    initializeFramingSystemCategories(): void {
        this.framingSystemCategoryService
            .getFramingSystemCategoryTreeView()
            .subscribe((treeview) => {
                treeview.forEach((parentCategory) => {
                    this.framingSystemParentCategories.push(parentCategory);
                    this.mapFramingSystemSubCategories(parentCategory);
                });

                this.filteredFramingSystemSubCategories =
                    this.framingSystemSubCategories.slice();
                this.filteredFramingSystemCategories =
                    this.framingSystemCategories.slice();
                if (this.framingSystem.Id > 0) {
                    this.assignSelectedFramingSystemCategories();
                }
            });
    }

    mapFramingSystemSubCategories(treeview): void {
        treeview.ChildViews.forEach((subCategory) => {
            this.framingSystemSubCategories.push(subCategory);
            this.mapFramingSystemCategories(subCategory);
        });
    }

    mapFramingSystemCategories(treeview): void {
        treeview.ChildViews.forEach((category) => {
            this.framingSystemCategories.push(category);
        });
    }

    // End Initialization for Dropdowns //
    // =============================== //

    // Logic for Updating Existing System //
    // ================================= //
    assignSelectedFramingSystemTypeLayouts(): void {
        this.framingSystem.FramingSystemDetails.forEach((frameDetail) => {
            const typeLayout = this.framingSystemTypeLayouts.find(
                (layout) => layout.FrameNumber === frameDetail.FrameNumber
            );
            this.framingSystemDetailDropdownOptions.push({
                Frames: this.filterFramesByTypeLayout(typeLayout),
                SelectedFrameId: frameDetail.FrameId,
                TypeLayout: typeLayout,
            });
        });
        this.framingSystemDetailDropdownOptions.sort((a, b) =>
            this.frameDropdownSort(a, b)
        );
    }

    assignSelectedFramingSystemCategories(): void {
        const selectedPresetSubCategory =
            this.filteredFramingSystemSubCategories.find((sc) => {
                return (
                    sc.Id === this.framingSystem.FramingSystemCategory.ParentId
                );
            });

        const selectedPresetCategory = this.framingSystemParentCategories.find(
            (pc) => {
                return pc.Id === selectedPresetSubCategory.ParentId;
            }
        );

        this.selectedPresetCategoryId = selectedPresetCategory.Id;
        this.filteredFramingSystemSubCategories =
            this.filteredFramingSystemSubCategories.filter((sc) => {
                return sc.ParentId === this.selectedPresetCategoryId;
            });
        this.selectedPresetSubCategoryId = selectedPresetSubCategory.Id;
        this.filteredFramingSystemCategories =
            this.filteredFramingSystemCategories.filter((sc) => {
                return sc.ParentId === this.selectedPresetSubCategoryId;
            });
    }
}
