<div class="row">
    <div class="col-md-7">
        <h2>Product Categories</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="customSizingRules" entity="Custom Sizing Rule" (selectionChanged)="filterSelectionChanged()">
    </mt-search-filter-select>

</div>

<mt-entity-list [entities]="productCategories" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage"
    (pageChanged)="getProductCategories()" (itemSelectedEvent)="productCategorySelected($event)" (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddProductCategory" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/product-categories', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
