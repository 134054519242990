import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { markAllFormFieldsAsTouched } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";

import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { UnitsService } from "@system-select/common";
import {
    ClimateZoneService,
    ProductCategoryService,
} from "@system-select/web-services";
import { IClimateZone } from "../../model/interfaces/climate-zone";
import { ClimateZoneDynamicConfig } from "../climate-zone.dynamic-config";

@Component({
    selector: "app-climate-zone-basic-info",
    templateUrl: "./climate-zone-basic-info.component.html",
})
export class ClimateZoneBasicInfoComponent implements OnInit {
    @Input() climateZone: IClimateZone;
    @Input() canEdit: boolean;

    isEditing: boolean;
    isHovered: boolean;
    climateZoneForm: FormGroup;
    formFactory: ClimateZoneDynamicConfig<IClimateZone>;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private climateZoneService: ClimateZoneService,
        private notificationsService: NotificationsService,
        private router: Router,
        private productCategoryService: ProductCategoryService,
        private unitsService: UnitsService
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        this.productCategoryService.getItems().subscribe(() => {
            this.setConfig();
        });
    }

    setConfig(): void {
        // Convert metric stored in DB back to Imperial for display
        if (this.climateZone.Id > 0) {
            this.convertUFactorsToImperial(this.climateZone);
        }
        this.formFactory = new ClimateZoneDynamicConfig<IClimateZone>(
            this.climateZone,
            this.productCategoryService.items
        );

        if (this.climateZone.Id === 0) {
            // new climateZone
            this.isEditing = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            // existing climateZone
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.climateZone.Id === 0) {
            void this.router.navigate(["/climate-zones"]);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            this.formFactory.assignFormValues(
                this.climateZone,
                form.value.ClimateZone as IClimateZone
            );
            if (!this.climateZone.Id || this.climateZone.Id === 0) {
                // handle new climateZone
                this.convertUFactorsToMetric(this.climateZone);
                this.climateZoneService
                    .create(this.climateZone)
                    .subscribe((answer) => {
                        void this.router.navigate([`/climate-zones/${answer}`]);
                        this.success();
                        this.climateZoneService.emitChange(this.climateZone);
                    });
            } else {
                // handle existing climateZone save
                this.convertUFactorsToMetric(this.climateZone);
                this.climateZoneService
                    .update(this.climateZone)
                    .subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.climateZoneService.emitChange(this.climateZone);
                        this.setConfig();
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    convertUFactorsToMetric(climateZone: IClimateZone): void {
        climateZone.UFactorHigh = this.unitsService.uFactorImpToMetric(
            climateZone.UFactorHigh
        );
        climateZone.UFactorLow = this.unitsService.uFactorImpToMetric(
            climateZone.UFactorLow
        );
    }

    convertUFactorsToImperial(climateZone: IClimateZone): void {
        climateZone.UFactorHigh = +this.unitsService
            .uFactorMetricToImp(climateZone.UFactorHigh)
            .toFixed(4);
        climateZone.UFactorLow = +this.unitsService
            .uFactorMetricToImp(climateZone.UFactorLow)
            .toFixed(4);
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success("Climate Zone saved successfully.");
    }
}
