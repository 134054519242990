import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import {
    SharedEntitiesListComponent,
    SharedEntitiesInfoComponent,
} from '@mt-ng2/shared-entities-module';
import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';
import { CommonNotesListComponent } from '@mt-ng2/entity-components-notes';
import { CommonAddressesListComponent } from '@mt-ng2/entity-components-addresses';
import { CommonDocumentsListComponent } from '@mt-ng2/entity-components-documents';

import { CustomerDetailComponent } from './customer-detail/customer-detail.component';
import { CustomersComponent } from './customer-list/customers.component';
import { CustomerHeaderComponent } from './customer-header/customer-header.component';
import { CustomerSettingsComponent } from './customer-settings/customer-settings.component';
import { ClaimTypes } from '@system-select/model';
import { contactsSharedEntity } from './shared-entities/customer.shared-entities';
import { CustomerService } from '@system-select/web-services';
import { CustomerNotesService } from '@system-select/web-services';
import { CustomerAddressesService } from '@system-select/web-services';
import { CustomerDocumentsService } from '@system-select/web-services';
import { CustomerAddComponent } from './customer-add/customer-add.component';

const customerEntityConfig = {
    addressesPath: 'addresses',
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    documentsPath: 'documents',
    entityIdParam: 'customerId',
    notesPath: 'notes',
    service: CustomerService,
    sharedEntities: [contactsSharedEntity],
    title: 'Customer Detail',
};

const customerListRoleGuard: any = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'Customers',
};

const customerAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.Customers,
    claimValues: [ClaimValues.FullAccess],
};

// TODO LAS: Bad Routes give a generic error notification.  It's really unhelpful, so we should investigate why that happens

const customerRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: CustomersComponent,
        data: customerListRoleGuard,
        path: 'customers',
    },
    {
        canActivate: [AuthGuard],
        component: CustomerSettingsComponent,
        path: 'customers/settings',
        pathMatch: 'full',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CustomerAddComponent, pathMatch: 'full' },
        ],
        component: CustomerHeaderComponent,
        data: customerAddRoleGuard,
        path: 'customers/add',
    },
    {
        canActivate: [AuthGuard],
        children: [
            { path: '', component: CustomerDetailComponent, pathMatch: 'full' },
            {
                component: CommonNotesListComponent,
                data: {
                    service: CustomerNotesService,
                    title: 'Customer Notes',
                },
                path: customerEntityConfig.notesPath,
                pathMatch: 'full',
            },
            {
                component: CommonAddressesListComponent,
                data: {
                    service: CustomerAddressesService,
                    title: 'Customer Addresses',
                },
                path: customerEntityConfig.addressesPath,
                pathMatch: 'full',
            },
            {
                component: CommonDocumentsListComponent,
                data: {
                    service: CustomerDocumentsService,
                    title: 'Customer Documents',
                },
                path: customerEntityConfig.documentsPath,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesListComponent,
                data: { title: 'Customer Contacts' },
                path: contactsSharedEntity.path,
                pathMatch: 'full',
            },
            {
                component: SharedEntitiesInfoComponent,
                path: `${contactsSharedEntity.path}/:${
                    contactsSharedEntity.entityIdParam
                }`,
                pathMatch: 'full',
            },
        ],
        component: CustomerHeaderComponent,
        data: customerEntityConfig,
        path: `customers/:${customerEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(customerRoutes)],
})
export class CustomerRoutingModule {}
