<div class="row">
    <div class="col-md-7">
        <h2>Customers</h2>
    </div>
</div>
<div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="sources" entity="Source" (selectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
    <mt-search-filter-select [items]="statuses" entity="Status" (selectionChanged)="filterSelectionChanged()"></mt-search-filter-select>
</div>

<mt-entity-list [entities]="customers" [total]="total" [(currentPage)]="currentPage" [(itemsPerPage)]="itemsPerPage" (pageChanged)="getCustomers()"
    (itemSelectedEvent)="customerSelected($event)" (columnSortedEvent)="columnSorted($event)" [entityListConfig]="entityListConfig">
</mt-entity-list>

<div *ngIf="canAddCustomer" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/customers', 'add']">
        <span class="fa fa-plus"></span>
    </a>
</div>
