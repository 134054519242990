import { ICustomer } from '../interfaces/customer';
import { DynamicField, DynamicFieldType, DynamicFieldTypes } from '@mt-ng2/dynamic-form';
import { IUser } from '../interfaces/user';
import { Validators } from '@angular/forms';
import { IUserDynamicControlsParameters, UserDynamicControls } from '../form-controls/user.form-controls';
import { nameof } from '../shared-methods/shared-methods';

export class UserDynamicControlsPartial extends UserDynamicControls {

    constructor(
        user?: IUser,
        additionalParameters?: IUserDynamicControlsParameters,
    ) {
        super(user, additionalParameters);
        this.setYesNo(this.Form.IsObeUser, user, nameof<IUser>('IsObeUser'), 'Is this an OBE User?');
    }

    private setYesNo(field: DynamicField, value: IUser, key: string, label: string): void {
        field.labelHtml = `<span class="dynamic-field-label"><label>${label}</label></span><span class="errortext ng-star-inserted"> *</span>`;
        field.options = [{ Id: 1, Name: 'Yes' }, { Id: 0, Name: 'No' }];
        field.value = value && value.Id && value.hasOwnProperty(key) && value[key] !== null ? (value[key] ? 1 : 0) : null;
        field.type = new DynamicFieldType({
            fieldType: DynamicFieldTypes.Select,
            inputType: null,
            scale: null,
        });
        field.validation = [Validators.required];
        field.validators = { 'required': true };
    }

}
