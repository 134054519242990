import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import { DynamicFormModule } from "@mt-ng2/dynamic-form";
import { AuthUserPasswordComponent } from "./auth-user/auth-user-password/auth-user-password.component";
import { AuthUserPortalAccessComponent } from "./auth-user/auth-user-portal-access/auth-user-portal-access.component";

@NgModule({
    bootstrap: [],
    declarations: [AuthUserPortalAccessComponent, AuthUserPasswordComponent],
    exports: [AuthUserPortalAccessComponent, AuthUserPasswordComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        DynamicFormModule,
        MtDisableDuringHttpCallsModule,
    ],
})
export class AuthEntityModule {}
