import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, IRoleGuarded, ClaimValues } from '@mt-ng2/auth-module';

import { ProductCategoryAddComponent } from './product-category-add/product-category-add.component';
import { ProductCategoryDetailComponent } from './product-category-detail/product-category-detail.component';
import { ProductCategoriesComponent } from './product-category-list/product-categories.component';
import { ProductCategoryService } from '@system-select/web-services';
import { ProductCategoryHeaderComponent } from './product-category-header/product-category-header.component';
import { ClaimTypes } from '../model/ClaimTypes';

const productCategoryEntityConfig = {
    claimType: ClaimTypes.ProductCategories,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    entityIdParam: 'productCategoryId',
    service: ProductCategoryService,
    title: 'ProductCategory Detail',
};

const productCategoryListRoleGuard: any = {
    claimType: ClaimTypes.ProductCategories,
    claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
    title: 'ProductCategories',
};

const productCategoryAddRoleGuard: IRoleGuarded = {
    claimType: ClaimTypes.ProductCategories,
    claimValues: [ClaimValues.FullAccess],
};

export const productCategoryPaths = {
    productCategories: 'product-categories',
    productCategoriesAdd: 'product-categories/add',
};

const productCategoryRoutes: Routes = [
    {
        canActivate: [AuthGuard],
        component: ProductCategoriesComponent,
        data: productCategoryListRoleGuard,
        path: productCategoryPaths.productCategories,
    },
    {
        canActivate: [AuthGuard],
        children: [{component: ProductCategoryAddComponent, path: '', pathMatch: 'full'}],
        component: ProductCategoryHeaderComponent,
        data: productCategoryAddRoleGuard,
        path: productCategoryPaths.productCategoriesAdd,
    },
    {
        canActivate: [AuthGuard],
        children: [
            {component: ProductCategoryDetailComponent, path: '', pathMatch: 'full'},

        ],
        component: ProductCategoryHeaderComponent,
        data: productCategoryEntityConfig,
        path: `product-categories/:${productCategoryEntityConfig.entityIdParam}`,
    },
];

@NgModule({
    exports: [RouterModule],
    imports: [RouterModule.forChild(productCategoryRoutes)],
})
export class ProductCategoryRoutingModule {}
