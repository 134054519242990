import { Component } from '@angular/core';
import { IEntity, IEntityListComponentMembers, IEntityListDynamicCellComponent } from '@mt-ng2/entity-list-module';
import { IDocument } from '@system-select/model';
import { ProductDocumentService } from '@system-select/web-services';
import { ProductDocumentsEntityListConfig } from './product-documents.entity-list-config';

@Component({
    styles: [`
        .ib-container {
            white-space: nowrap;
        }
    `],
    template: `
        <div class="ib-container p-sm">
            <button (click)="replaceProductDocument(item)"class="btn btn-default" title="Replace">
                Replace
            </button><button
                (click)="deleteProductDocument(item)" class="btn btn-default" title="Delete"
            >Delete
            </button>
        </div>
    `,
})
export class ProductDocumentActionComponent implements IEntityListDynamicCellComponent {
    item: IDocument;
    entityListComponentMembers: IEntityListComponentMembers;

    constructor(
        private productDocumentService: ProductDocumentService,
    ) {
    }

    set entity(value: IEntity) {
        this.item = value as IDocument;
    }

    deleteProductDocument(item: IDocument): void {
        (<ProductDocumentsEntityListConfig>this.entityListComponentMembers.entityListConfig).onItemDeleted.emit(item.Id);
    }

    replaceProductDocument(item: IDocument): void {
        (<ProductDocumentsEntityListConfig>this.entityListComponentMembers.entityListConfig).onItemReplaced.emit(item.Id);
    }
}
