import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { SharedEntitiesService } from '@mt-ng2/shared-entities-module';
import { IAddressContainer } from '@mt-ng2/dynamic-form';

import { IContact } from '../../interfaces/contact';
import { catchError } from 'rxjs/operators';
export const emptyContact: IContact = {
    Email: null,
    FirstName: '',
    Id: 0,
    LastName: '',
    StatusId: null,
    Title: '',
};

@Injectable()
export class ContactService extends SharedEntitiesService<IContact> {
    constructor(public baseEntityUrl: string, public http: HttpClient) {
        super(baseEntityUrl, '/contacts', http);
    }

    savePhones(
        baseEntityId: number,
        contactId: number,
        phoneCollection: any,
    ): any {
        return this.http
            .put(
                `${
                    this.baseEntityUrl
                }/${baseEntityId}/contacts/${contactId}/phones`,
                phoneCollection,
                { responseType: 'text' as 'json' },
            )
            .pipe(catchError(this.handleError));
    }

    saveAddress(
        baseEntityId: number,
        contactId: number,
        address: IAddressContainer,
    ): any {
        if (address.AddressId > 0) {
            return this.http.put(
                `${
                    this.baseEntityUrl
                }/${baseEntityId}/contacts/${contactId}/addresses`,
                address,
            );
        } else {
            return this.http.post(
                `${
                    this.baseEntityUrl
                }/${baseEntityId}/contacts/${contactId}/address`,
                address,
            );
        }
    }

    deleteAddress(
        baseEntityId: number,
        contactId: number,
        address: IAddressContainer,
    ): any {
        return this.http.delete(
            `${
                this.baseEntityUrl
            }/${baseEntityId}/contacts/${contactId}/addresses/${
                address.AddressId
            }`,
        );
    }
}
