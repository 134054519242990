import { IDynamicConfig, IDynamicFormConfig, DynamicConfig } from '@mt-ng2/dynamic-form';
import { IProductCategory } from '../model/interfaces/product-category';
import { ICustomSizingRule } from '../model/interfaces/custom-sizing-rule';
import { IProductCategoryDynamicControlsParameters, ProductCategoryDynamicControls } from '../model/form-controls/product-category.form-controls';

export class ProductCategoryDynamicConfig<T extends IProductCategory> extends DynamicConfig<T> implements IDynamicConfig<T> {
    constructor(private productCategory: T, private customSizingRules: ICustomSizingRule[], private configControls?: string[]) {
        super();
        const additionalParams: IProductCategoryDynamicControlsParameters = {
            customSizingRules: this.customSizingRules,
        };
        const dynamicControls = new ProductCategoryDynamicControls(this.productCategory, additionalParams);
        // default form implementation can be overridden at the component level
        if (!configControls) {
            this.configControls = ['Name', 'CustomSizingRuleId', 'IsLeed'];
        }
        this.setControls(this.configControls, dynamicControls);
    }

    getForUpdate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
            viewOnly: this.DynamicLabels,
        };
    }

    getForCreate(additionalConfigs?: any[]): IDynamicFormConfig {
        return {
            formObject: this.getFormObject(additionalConfigs),
        };
    }
}
