import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { switchMap, tap } from "rxjs/operators";

import { markAllFormFieldsAsTouched } from "@mt-ng2/common-functions";
import { NotificationsService } from "@mt-ng2/notifications-module";

import { IMetaItem } from "@mt-ng2/base-service";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import { ModalService } from "@mt-ng2/modal-module";
import {
    ISelectionChangedEvent,
    MultiselectItem,
} from "@mt-ng2/multiselect-control";
import { FileService } from "@system-select/common";
import {
    DocumentTypes,
    IDocument,
    IProductCategory,
    ISightline,
    ISystemDepth,
    IWindowDataSet,
    IWindowDatasetPayload,
} from "@system-select/model";
import {
    ProductCategoryService,
    ProductDocumentService,
    SightlineService,
    SystemDepthService,
    WindowDataSetService,
} from "@system-select/web-services";
import { Observable, forkJoin } from "rxjs";
import swal, { SweetAlertOptions } from "sweetalert2";
import {
    ICreateWindowDatasetPayload,
    IWindowDatasetBasePayload,
} from "../../model/public-api";
import { WindowDataSetDynamicConfig } from "../window-data-set.dynamic-config";

@Component({
    selector: "app-window-data-set-basic-info",
    styles: [
        `
        .miles-form {
            overflow: hidden;
        }
        .btn-group {
            margin-bottom: 5px;
        }`,
    ],
    templateUrl: "./window-data-set-basic-info.component.html",
})
export class WindowDataSetBasicInfoComponent implements OnInit {
    @Input() windowDataSet: IWindowDataSet;
    @Input() canEdit: boolean;
    @Output() onPayloadUpdate: EventEmitter<IWindowDatasetPayload> =
        new EventEmitter<IWindowDatasetPayload>();

    isEditing: boolean;
    isHovered: boolean;
    formFactory: WindowDataSetDynamicConfig<IWindowDataSet>;
    showDbUpload: boolean;
    dbUpload: File;
    validExtension = "mdb";
    isCOG: boolean;
    hasCustomSizing: boolean;
    selectedProductCategory: IProductCategory = null;
    productCategories: IProductCategory[];
    isArchived: boolean;
    productCategorySubCategoryIds: number[];
    filteredProductCategories: IProductCategory[];
    systemDepths: ISystemDepth[];
    selectedSystemDepth: ISystemDepth;
    sightlines: ISightline[];
    selectedSightline: ISightline;
    leedPoints: number;
    siteLink: string;
    documents: MultiselectItem[] = [];
    selectedProductDocuments: IDocument[];
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private windowDataSetService: WindowDataSetService,
        private notificationsService: NotificationsService,
        private router: Router,
        private productCategoryService: ProductCategoryService,
        private modalService: ModalService,
        private systemDepthService: SystemDepthService,
        private sightlineService: SightlineService,
        private productDocumentService: ProductDocumentService
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        if (this.windowDataSet.WindowFrames) {
            this.leedPoints =
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].LeedPoints
                    : null;
            this.siteLink =
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].SiteLink
                    : null;
            this.isCOG =
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].IsCog
                    : false;
            this.hasCustomSizing =
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].HasCustomSizing
                    : false;
            this.isArchived =
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].Archived
                    : false;
            // Flatten the array of arrays to get the current sub category Ids then filter out duplicates
            this.productCategorySubCategoryIds =
                this.getSelectedProductCategorySubCategoryIds(
                    this.windowDataSet
                );
        }

        forkJoin([
            this.systemDepthService.getAll(),
            this.sightlineService.getAll(),
            this.productCategoryService.getAll(),
            this.productDocumentService.getByDocumentType(
                DocumentTypes.MetalProduct
            ),
        ]).subscribe(([depths, sightlines, productCategories, documents]) => {
            this.systemDepths = depths;
            this.selectedSystemDepth =
                this.windowDataSet.WindowFrames &&
                this.windowDataSet.WindowFrames.length > 0
                    ? this.getSelectedItem(
                          this.windowDataSet.WindowFrames[0].SystemDepthId,
                          this.systemDepths
                      )
                    : null;
            this.sightlines = sightlines;
            this.selectedSightline =
                this.windowDataSet.WindowFrames &&
                this.windowDataSet.WindowFrames.length > 0
                    ? this.getSelectedItem(
                          this.windowDataSet.WindowFrames[0].SightlineId,
                          this.sightlines
                      )
                    : null;
            this.productCategories = productCategories;
            this.selectedProductDocuments =
                this.windowDataSet.WindowFrames &&
                this.windowDataSet.WindowFrames.length > 0
                    ? this.windowDataSet.WindowFrames[0].Documents
                    : [];
            this.documents = documents.map((d) => {
                return {
                    Item: d,
                    Selected: this.selectedProductDocuments.some(
                        (pd) => pd.Id === d.Id
                    ),
                };
            });
            // Use only the top-level categories in the select
            this.filteredProductCategories = productCategories.filter(
                (pc) => pc.ParentId === null
            );
            if (this.windowDataSet.WindowFrames) {
                this.selectedProductCategory =
                    this.windowDataSet.WindowFrames.length > 0
                        ? this.getSelectedProductCategory(
                              this.windowDataSet.WindowFrames[0]
                                  .ProductCategoryId
                          )
                        : null;
                this.onPayloadUpdate.emit({
                    Archived: this.isArchived,
                    Documents: this.selectedProductDocuments,
                    HasCustomSizing: this.hasCustomSizing,
                    IsCOG: this.isCOG,
                    LEEDPoints: this.leedPoints,
                    ProductCategoryId: this.selectedProductCategory
                        ? this.selectedProductCategory.Id
                        : null,
                    ProductCategorySubCategoryIds:
                        this.productCategorySubCategoryIds,
                    SightlineId: this.selectedSightline
                        ? this.selectedSightline.Id
                        : null,
                    SiteLink: this.siteLink,
                    SystemDepthId: this.selectedSystemDepth
                        ? this.selectedSystemDepth.Id
                        : null,
                    WindowDataSet: this.windowDataSet,
                });
            }
            this.setConfig();
        });
    }

    private getSelectedProductCategorySubCategoryIds(
        windowDataSet: IWindowDataSet
    ): number[] {
        return windowDataSet.WindowFrames.map((wf) =>
            wf.WindowFrameProductCategories.map(
                (wfpc) => wfpc.ProductCategoryId
            )
        )
            .reduce((acc, w) => [...acc, ...w], [])
            .filter((id, index, self) => self.indexOf(id) === index);
    }

    private getSelectedProductCategory(categoryId: number): IProductCategory {
        return this.productCategories.find((pc) => pc.Id === categoryId);
    }

    private getSelectedItem(id: number, items: IMetaItem[]): IMetaItem {
        return items.find((e) => e.Id === id);
    }

    setConfig(): void {
        this.formFactory = new WindowDataSetDynamicConfig<IWindowDataSet>(
            this.windowDataSet
        );

        if (this.windowDataSet.Id === 0) {
            // new windowDataSet
            this.isEditing = true;
            this.showDbUpload = true;
            const config = this.formFactory.getForCreate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            // existing windowDataSet
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
            this.showDbUpload = false;
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    onSelectedProductDocumentsUpdate(event: ISelectionChangedEvent): void {
        this.selectedProductDocuments = event.selectedItems as IDocument[];
    }

    onFileChange(e: Event): void {
        this.dbUpload = null;
        const files = (e.target as HTMLInputElement).files;
        if (files.length) {
            const file = files[0];
            const extension = file.name.substring(
                file.name.length - 3,
                file.name.length
            );
            if (extension !== this.validExtension) {
                this.notificationsService.warning(
                    "File must be a valid Microsoft Access File."
                );
                return;
            } else {
                this.dbUpload = files[0];
            }
        }
    }

    cancelClick(): void {
        if (this.windowDataSet.Id === 0) {
            void this.router.navigate(["/window-data-sets"]);
        } else {
            this.isEditing = false;
            this.productCategorySubCategoryIds =
                this.getSelectedProductCategorySubCategoryIds(
                    this.windowDataSet
                );
        }
    }

    formSubmitted(form: UntypedFormGroup): void {
        if (form.valid) {
            if (
                form.value.WindowDataSet.PublishDate &&
                !this.validatePublishDate(
                    form.value.WindowDataSet.PublishDate as Date
                )
            ) {
                this.notificationsService.error(
                    "Publish Date cannot be set to a past date"
                );
                return;
            }
            this.formFactory.assignFormValues(
                this.windowDataSet,
                form.value.WindowDataSet as IWindowDataSet
            );

            if (!this.windowDataSet.Id || this.windowDataSet.Id === 0) {
                let apiCall: () => Observable<number>;
                const dbUploadIsRequired =
                    !this.selectedProductCategory ||
                    !this.selectedProductCategory.IsLeed;

                if (dbUploadIsRequired && !this.dbUpload) {
                    this.notificationsService.warning(
                        "DB upload file is required."
                    );
                    return;
                }

                if (this.dbUpload) {
                    // upload the DB file then update
                    apiCall = () =>
                        this.windowDataSetService.uploadDB(this.dbUpload).pipe(
                            switchMap((windowDataSetId: number) => {
                                this.windowDataSet.Id = windowDataSetId;
                                return this.windowDataSetService.updateWindowDataSet(
                                    this.buildWindowDataSetPayload(
                                        this.windowDataSet,
                                        false
                                    )
                                );
                            })
                        );
                } else {
                    // just create the data
                    apiCall = () =>
                        this.windowDataSetService
                            .createWindowDataSet(
                                this.buildCreateWindowDataSetPayload(
                                    this.windowDataSet,
                                    false
                                )
                            )
                            .pipe(
                                tap((windowDataSetId: number) => {
                                    this.windowDataSet.Id = windowDataSetId;
                                })
                            );
                }

                apiCall().subscribe(() => {
                    void this.router.navigate([
                        `/window-data-sets/${this.windowDataSet.Id}`,
                    ]);
                    this.success();
                    this.windowDataSetService.emitChange(this.windowDataSet);
                });
            } else {
                if (
                    !this.windowDataSet.FileName &&
                    this.windowDataSet.WindowFrames[0].ProductCategory &&
                    this.windowDataSet.WindowFrames[0].ProductCategory.IsLeed &&
                    !this.selectedProductCategory.IsLeed
                ) {
                    // they are changing from a LEED category to a non-LEED category
                    // and we don't have MDB data yet, so the MDB file is required
                    this.notificationsService.warning(
                        "DB upload file is required."
                    );
                    return;
                }

                // handle existing windowDataSet save
                this.windowDataSetService
                    .updateWindowDataSet(
                        this.buildWindowDataSetPayload(
                            this.windowDataSet,
                            false
                        )
                    )
                    .subscribe(() => {
                        this.isEditing = false;
                        this.success();
                        this.windowDataSetService.emitChange(
                            this.windowDataSet
                        );
                        this.windowDataSetService
                            .getById(this.windowDataSet.Id)
                            .subscribe((wds) => {
                                this.windowDataSet = wds;
                                this.setConfig();
                            });
                    });
            }
        } else {
            markAllFormFieldsAsTouched(form);
            this.error();
        }
    }

    validatePublishDate(publishDate: Date): boolean {
        // To fix mtDate being behind by a full day
        const publishDateCopy = new Date(publishDate);
        publishDateCopy.setDate(publishDateCopy.getDate() + 1);
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        return publishDateCopy >= today;
    }

    toggleIsCOG(isCog: boolean): void {
        this.isCOG = isCog;
    }

    toggleHasCustomSizing(hasCustomSizing: boolean): void {
        this.hasCustomSizing = hasCustomSizing;
    }

    getCOGLabel(): string {
        return this.isCOG ? " Yes" : " No";
    }

    getCustomSizingLabel(): string {
        return this.hasCustomSizing ? " Yes" : " No";
    }

    getDocumentsLabel(): string {
        return this.selectedProductDocuments
            ? this.selectedProductDocuments.map((d) => d.Name).join(", ")
            : "";
    }

    getProductCategoryLabel(): string {
        return this.selectedProductCategory
            ? " " + this.selectedProductCategory.Name
            : "";
    }

    getProductCategorySubCategoriesLabel(): string {
        return this.productCategorySubCategoryIds && this.productCategories
            ? " " + this.buildSubCategoriesLabel()
            : "";
    }

    getItemLabel(item: IMetaItem): string {
        return item ? item.Name : "";
    }

    private buildSubCategoriesLabel(): string {
        const items = this.productCategories.filter(
            (pc) => this.productCategorySubCategoryIds.indexOf(pc.Id) > -1
        );
        return items.map((pc) => pc.Name).join(", ");
    }

    onSelectedProductCategoriesUpdate(
        productCategories: IProductCategory[]
    ): void {
        this.productCategorySubCategoryIds = productCategories.map(
            (pc) => pc.Id
        );
    }

    downloadMDBFile(): void {
        this.windowDataSetService
            .getMDBFile(this.windowDataSet.Id)
            .subscribe((answer: string) => {
                FileService.save(
                    answer,
                    this.windowDataSet.FileName,
                    "application/mdb",
                    true
                );
            });
    }

    removeMDBFile(): void {
        this.modalService
            .showModal({
                cancelButtonText: "No",
                confirmButtonText: "Yes",
                showCancelButton: true,
                text: "This will permanently remove the file from the admin portal and you will no longer be able to download it.",
                title: "Are you sure?",
                icon: "warning",
            })
            .subscribe((res) => {
                if (res.isConfirmed) {
                    this.windowDataSetService
                        .deleteMDBFile(this.windowDataSet.Id)
                        .subscribe(() => {
                            this.windowDataSet.FileName = null;
                            this.isEditing = false;
                            this.notificationsService.success(
                                "Successfully removed MDB file"
                            );
                        });
                }
            });
    }

    promptMDBFileReplacement(e: Event): void {
        this.onFileChange(e);
        void swal
            .fire(<SweetAlertOptions>{
                focusCancel: false,
                focusConfirm: false,
                showCancelButton: true,
                showCloseButton: true,
                text: `Are you sure you want to update this Dataset?`,
                title: "Confirm",
                type: "warning",
            })
            .then((answer) => {
                if (answer.isConfirmed) {
                    this.onMDBReplace();
                }
            });
    }

    /**
     * Archives the current Window Data Set and creates a copy of it with the new MDB upload
     */
    onMDBReplace(): void {
        const newWindowDataSet = Object.assign({}, this.windowDataSet);
        this.windowDataSetService
            .updateWindowDataSet(
                this.buildWindowDataSetPayload(this.windowDataSet, true)
            )
            .subscribe(() => {
                this.windowDataSetService
                    .uploadDB(this.dbUpload)
                    .pipe(
                        switchMap((windowDataSetId: number) => {
                            newWindowDataSet.Id = windowDataSetId;
                            return this.windowDataSetService.updateWindowDataSet(
                                this.buildWindowDataSetPayload(
                                    newWindowDataSet,
                                    false
                                )
                            );
                        })
                    )
                    .subscribe(() => {
                        void this.router.navigate(["/window-data-sets"]);
                        this.success();
                    });
            });
    }

    private buildWindowDataSetBasePayload(
        windowDataSet: IWindowDataSet,
        archive: boolean
    ): IWindowDatasetBasePayload {
        return {
            Archived: archive,
            Documents: this.selectedProductDocuments,
            HasCustomSizing: this.hasCustomSizing,
            IsCOG: this.isCOG,
            LEEDPoints: this.leedPoints,
            ProductCategoryId: this.selectedProductCategory
                ? this.selectedProductCategory.Id
                : null,
            ProductCategorySubCategoryIds: this.productCategorySubCategoryIds,
            SightlineId: this.selectedSightline
                ? this.selectedSightline.Id
                : null,
            SiteLink: this.siteLink,
            SystemDepthId: this.selectedSystemDepth
                ? this.selectedSystemDepth.Id
                : null,
        };
    }

    private buildWindowDataSetPayload(
        windowDataSet: IWindowDataSet,
        archive: boolean
    ): IWindowDatasetPayload {
        return {
            WindowDataSet: windowDataSet,
            ...this.buildWindowDataSetBasePayload(windowDataSet, archive),
        };
    }

    private buildCreateWindowDataSetPayload(
        windowDataSet: IWindowDataSet,
        archive: boolean
    ): ICreateWindowDatasetPayload {
        return {
            Name: windowDataSet.Name,
            PublishDate: windowDataSet.PublishDate,
            ...this.buildWindowDataSetBasePayload(windowDataSet, archive),
        };
    }

    error(): void {
        this.notificationsService.error(
            "Save failed.  Please check the form and try again."
        );
    }

    success(): void {
        this.notificationsService.success(
            "Window Data Set saved successfully."
        );
    }
}
