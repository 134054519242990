import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { WindowDataSetRoutingModule } from './window-data-set-routing.module';

import { WindowDataSetBasicInfoComponent } from './window-data-set-basic-info/window-data-set-basic-info.component';
import { WindowDataSetDetailComponent } from './window-data-set-detail/window-data-set-detail.component';
import { WindowDataSetsComponent } from './window-data-set-list/window-data-sets.component';
import { WindowDataSetHeaderComponent } from './window-data-set-header/window-data-set-header.component';
import { WindowDataSetService, ProductCategoryService, SightlineService, SystemDepthService } from '@system-select/web-services';
import { ProductCategoryMultiselectComponent } from './window-data-set-basic-info/product-category-multiselect/product-category-multiselect.component';
import { MultiselectControlModule } from '@mt-ng2/multiselect-control';

@NgModule({
    declarations: [
        WindowDataSetsComponent,
        WindowDataSetHeaderComponent,
        WindowDataSetDetailComponent,
        WindowDataSetBasicInfoComponent,
        ProductCategoryMultiselectComponent,
    ],
    imports: [
        SharedModule,
        WindowDataSetRoutingModule,
        MultiselectControlModule,
    ],
})
export class WindowDataSetModule {
    static forRoot(): ModuleWithProviders<WindowDataSetModule> {
        return {
            ngModule: WindowDataSetModule,
            providers: [
                WindowDataSetService, ProductCategoryService, SightlineService, SystemDepthService,
            ],
        };
    }
}
