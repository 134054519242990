import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { ClimateZoneRoutingModule } from './climate-zone-routing.module';

import { ClimateZoneBasicInfoComponent } from './climate-zone-basic-info/climate-zone-basic-info.component';
import { ClimateZoneDetailComponent } from './climate-zone-detail/climate-zone-detail.component';
import { ClimateZonesComponent } from './climate-zone-list/climate-zones.component';
import { ClimateZoneHeaderComponent } from './climate-zone-header/climate-zone-header.component';
import { ClimateZoneService, ProductCategoryService } from '@system-select/web-services';

@NgModule({
  declarations: [
    ClimateZonesComponent,
    ClimateZoneHeaderComponent,
    ClimateZoneDetailComponent,
    ClimateZoneBasicInfoComponent,
  ],
  imports: [
    SharedModule,
    ClimateZoneRoutingModule,
  ],
})
export class ClimateZoneModule {
  static forRoot(): ModuleWithProviders<ClimateZoneModule> {
    return {
      ngModule: ClimateZoneModule,
      providers: [
        ClimateZoneService,
        ProductCategoryService,
    ],
    };
  }
}
