import { EntityListColumn, EntityListConfig, IEntityListConfig, IEntitySortProperty } from '@mt-ng2/entity-list-module';
import { IGlass } from '@system-select/model';

export class GlassesEntityListConfig extends EntityListConfig {

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
              new EntityListColumn({
                  accessorFunction: (item: IGlass) => item.IgdbId.toString(),
                  name: 'IGDB ID',
                  sort: <IEntitySortProperty> {
                      isDefaultSort: true,
                      sortProperty: 'IgdbId',
                  },
              }),
              new EntityListColumn({
                  accessorFunction: (item: IGlass) => item.Name,
                  name: 'Name',
              }),
              new EntityListColumn({
                  accessorFunction: (item: IGlass) => item.GlassTypes.map((gt) => gt.Name).join(', '),
                  name: 'Glass Type',
                  sort: <IEntitySortProperty> {
                    disableSort: true,
                },
              }),
              new EntityListColumn({
                  accessorFunction: (item: IGlass) => item.GlassColors.map((gt) => gt.Name).join(', '),
                  name: 'Glass Color',
                  sort: <IEntitySortProperty> {
                    disableSort: true,
                },
              }),
              new EntityListColumn({
                  accessorFunction: (item: IGlass) => item.Vendor != null ? item.Vendor.Name : '',
                  name: 'Vendor.Name',
              }),
            ],
        };
        super(listConfig);
    }
}
