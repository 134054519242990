import { IEntityListConfig, EntityListConfig, EntityListColumn, IEntitySortProperty } from '@mt-ng2/entity-list-module';
import { IWindowDataSet } from '@system-select/model';

export class WindowDataSetsEntityListConfig extends EntityListConfig {

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    accessorFunction: (dataset: IWindowDataSet) => {
                        if (dataset.WindowFrames.length > 0 && dataset.WindowFrames[0].Archived) {
                            return dataset.Name + ' (ARCHIVED)';
                        }
                        return dataset.Name;
                    },
                    name: 'Name',
                }),
                new EntityListColumn({
                    accessorFunction: (dataset: IWindowDataSet) => {
                        if (dataset.WindowFrames.length > 0 && dataset.WindowFrames[0].ProductCategory) {
                            return dataset.WindowFrames[0].ProductCategory.Name;
                        }
                        return '';
                    },
                    name: 'Product Category',
                    sort: <IEntitySortProperty>{
                        isDefaultSort: true,
                        sortProperty: 'productcategory',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (dataset: IWindowDataSet) => {
                        if (dataset.WindowFrames.length > 0 && dataset.WindowFrames[0].WindowFrameProductCategories) {
                            return dataset.WindowFrames[0].WindowFrameProductCategories.map((wfpc) => {
                                return wfpc.ProductCategory.Name;
                            }).join(', ');
                        }
                        return '';
                    },
                    name: 'Product Groups',
                    sort: <IEntitySortProperty>{
                        disableSort: true,
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (dataset: IWindowDataSet) => {
                        if (dataset.WindowFrames.length > 0 && dataset.WindowFrames[0].Sightline) {
                            return dataset.WindowFrames[0].Sightline.Name;
                        }
                        return '';
                    },
                    name: 'Sightline',
                    sort: <IEntitySortProperty>{
                        isDefaultSort: true,
                        sortProperty: 'sightline',
                    },
                }),
                new EntityListColumn({
                    accessorFunction: (dataset: IWindowDataSet) => {
                        if (dataset.WindowFrames.length > 0 && dataset.WindowFrames[0].SystemDepth) {
                            return dataset.WindowFrames[0].SystemDepth.Name;
                        }
                        return '';
                    },
                    name: 'System Depth',
                    sort: <IEntitySortProperty>{
                        isDefaultSort: true,
                        sortProperty: 'systemDepth',
                    },
                }),
            ],
        };
        super(listConfig);
    }
}
