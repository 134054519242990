import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';

import { IWindowDataSet } from '../../model/interfaces/window-data-set';
import { NotificationsService } from '@mt-ng2/notifications-module';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';

import { ClaimTypes } from '../../model/ClaimTypes';
import { WindowDataSetService } from '@system-select/web-services';
import { SweetAlertOptions } from 'sweetalert2';
import swal from 'sweetalert2';
import { IWindowDatasetPayload } from '@system-select/model';

@Component({
    selector: 'app-window-data-set-detail',
    templateUrl: './window-data-set-detail.component.html',
})
export class WindowDataSetDetailComponent implements OnInit {
    windowDataSet: IWindowDataSet;
    editingComponent: Subject<any> = new Subject();
    canEdit: boolean;
    canAdd: boolean;
    id: number;
    payload: IWindowDatasetPayload;

    constructor(
        private windowDataSetService: WindowDataSetService,
        private claimsService: ClaimsService,
        private route: ActivatedRoute,
        private notificationsService: NotificationsService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        // check claims
        this.canEdit = this.claimsService.hasClaim(ClaimTypes.WindowDataSets, [ClaimValues.FullAccess]);
        this.canAdd = this.canEdit;
        // get current id from route
        this.id = +this.route.snapshot.paramMap.get('windowDataSetId');
        // try load if id > 0
        if (this.id > 0) {
            this.getWindowDataSetById(this.id);
        } else {
            // set windowDataSet to emptyWindowDataSet
            this.windowDataSet = this.windowDataSetService.getEmptyWindowDataSet();
        }
        this.editingComponent.next('');
    }

    getWindowDataSetById(id: number): void {
        this.windowDataSetService.getById(id)
            .subscribe((windowDataSet) => {
                this.windowDataSet = windowDataSet;
            });
    }

    updatePayload(payload: IWindowDatasetPayload): void {
        this.payload = payload;
    }

    archiveDataSet(): void {
        void swal.fire(<SweetAlertOptions>{
            focusCancel: false,
            focusConfirm: false,
            showCancelButton: true,
            showCloseButton: true,
            text: `Are you sure you want to ${this.payload.Archived ? 'unarchive' : 'archive'} this Dataset?`,
            title: 'Confirm',
            type: 'warning',
        }).then((answer) => {
            if (answer.value) {
                this.windowDataSetService.updateWindowDataSet({
                    Archived: !this.payload.Archived,
                    Documents: this.payload.Documents,
                    HasCustomSizing: this.payload.HasCustomSizing,
                    IsCOG: this.payload.IsCOG,
                    LEEDPoints: this.payload.LEEDPoints,
                    ProductCategoryId: this.payload.ProductCategoryId,
                    ProductCategorySubCategoryIds: this.payload.ProductCategorySubCategoryIds,
                    SightlineId: this.payload.SightlineId,
                    SiteLink: this.payload.SiteLink,
                    SystemDepthId: this.payload.SystemDepthId,
                    WindowDataSet: this.windowDataSet,
                }).subscribe(() => {
                    this.notificationsService.success('Successfully updated Window DataSet');
                    void this.router.navigate([`/window-data-sets`]);
                });
            }
        });
    }
}
