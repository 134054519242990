import { ModuleWithProviders, NgModule } from '@angular/core';

import { SharedModule } from '../common/shared.module';
import { FrameRoutingModule } from './frame-routing.module';

import { FrameService } from '@system-select/web-services';
import { FrameTypeService } from '@system-select/web-services';
import { FramingSystemService } from '@system-select/web-services';
import { FramesComponent } from './frame-list/frames.component';
import { FrameHeaderComponent } from './frame-header/frame-header.component';
import { FrameDetailComponent } from './frame-detail/frame-detail.component';
import { FrameBasicInfoComponent } from './frame-basic-info/frame-basic-info.component';
import { FrameComponent } from './frame/frame.component';
import { FramingSystemRoutingModule } from './framing-system-routing.module';
import { FramingSystemsComponent } from './framing-system-list/framing-system.component';
import { FramingSystemTypeService } from '@system-select/web-services';
import { FramingSystemDetailComponent } from './framing-system-detail/framing-system-detail.component';
import { FramingSystemTypeLayoutService } from '@system-select/web-services';
import { FramingSystemDetailDropdownComponent } from './framing-system-detail/framing-system-detail-dropdown/framing-system-detail-dropdown.component';

@NgModule({
  declarations: [
    FramesComponent,
    FrameHeaderComponent,
    FrameDetailComponent,
    FrameBasicInfoComponent,
    FrameComponent,
    FramingSystemsComponent,
    FramingSystemDetailComponent,
    FramingSystemDetailDropdownComponent,
  ],
  imports: [
    SharedModule,
    FrameRoutingModule,
    FramingSystemRoutingModule,
  ],
})
export class FrameModule {
    static forRoot(): ModuleWithProviders<FrameModule> {
        return {
            ngModule: FrameModule,
            providers: [
                FrameService,
                FrameTypeService,
                FramingSystemService,
                FramingSystemTypeService,
                FramingSystemTypeLayoutService,
            ],
        };
    }
}
