<div *ngIf="climateZone && climateZone.Id > 0">
  <div class="row">
    <div class="col-md-6">
      <app-climate-zone-basic-info [climateZone]="climateZone" [canEdit]="canEdit"></app-climate-zone-basic-info><br>
    </div>
    <div class="col-md-6">
    </div>
  </div>
  <a routerLink="/climate-zones" class="btn btn-default">Close</a>
</div>
<div *ngIf="climateZone && climateZone.Id === 0">
  <div class="row">
    <div class="col-md-6">
      <app-climate-zone-basic-info [climateZone]="climateZone"></app-climate-zone-basic-info>
    </div>
  </div>
</div>
