<div *ngIf="formCreated">
    <form
        class="miles-form padded"
        [formGroup]="glassForm"
        (ngSubmit)="formSubmitted()"
    >
        <h4>Glass</h4>
        <mt-dynamic-field
            [field]="abstractGlassControls.IgdbId"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.Name"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.PublishDate"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.LeedPoints"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.Thickness"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.Documents"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.GlassTypes"
            [form]="glassForm"
            (valueChanges)="checkForSilkScreen($event)"
        ></mt-dynamic-field>
        <mt-dynamic-field
            *ngIf="silkScreen"
            [field]="abstractGlassControls.SilkScreenColorId"
            [form]="glassForm"
        >
        </mt-dynamic-field>
        <mt-dynamic-field
            *ngIf="silkScreen"
            [field]="abstractGlassControls.SilkScreenPatternId"
            [form]="glassForm"
        >
        </mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.GlassColors"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.VendorId"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.Laminated"
            [form]="glassForm"
            (valueChanges)="checkForLaminate($event)"
        ></mt-dynamic-field>
        <div *ngIf="laminated">
            <label for="interlayerThickness">Interlayer Thickness: </label>
            <select
                class="form-control"
                id="interlayerThickness"
                [(ngModel)]="interlayerThickness"
                [ngModelOptions]="{ standalone: true }"
            >
                <option [value]=""></option>
                <option
                    *ngFor="let item of interlayerThicknesses"
                    [value]="item"
                >
                    {{ item }}"
                </option>
            </select>
        </div>
        <br />
        <mt-dynamic-field
            *ngIf="laminated"
            [field]="abstractGlassControls.InterlayerTypeId"
            [form]="glassForm"
        >
        </mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.ColorR"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.ColorG"
            [form]="glassForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractGlassControls.ColorB"
            [form]="glassForm"
        ></mt-dynamic-field>
        <div class="row">
            <div class="col-md-4">
                <fieldset>
                    <legend>Monolithic:</legend>
                    <mt-dynamic-field
                        [field]="abstractGlassControls.CanBeUsedForMonolithic"
                        [form]="glassForm"
                    >
                    </mt-dynamic-field>
                </fieldset>
            </div>
            <div class="col-md-4">
                <fieldset>
                    <legend>Double Pane:</legend>
                    <mt-dynamic-field
                        [field]="
                            abstractGlassControls.CanBeUsedForDoublePaneAsOutboardLayer
                        "
                        [form]="glassForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="
                            abstractGlassControls.CanBeUsedForDoublePaneAsInboardLayer
                        "
                        [form]="glassForm"
                    ></mt-dynamic-field>
                </fieldset>
            </div>
            <div class="col-md-4">
                <fieldset>
                    <legend>Triple Pane:</legend>
                    <mt-dynamic-field
                        [field]="
                            abstractGlassControls.CanBeUsedForTriplePaneAsOutboardLayer
                        "
                        [form]="glassForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="
                            abstractGlassControls.CanBeUsedForTriplePaneAsMiddleLayer
                        "
                        [form]="glassForm"
                    ></mt-dynamic-field>
                    <mt-dynamic-field
                        [field]="
                            abstractGlassControls.CanBeUsedForTriplePaneAsInboardLayer
                        "
                        [form]="glassForm"
                    ></mt-dynamic-field>
                </fieldset>
            </div>
        </div>
        <div>
            <label for="opticsFile">
                Optics File
                <span *ngIf="!showOpticsUpload" class="font-weight-normal">
                    <i class="fa fa-lg fa-check-circle-o text-success"></i>
                    <a
                        href
                        class="small p-l-sm"
                        (click)="
                            showOpticsUpload = true; $event.preventDefault()
                        "
                        >Overwrite?</a
                    >
                </span>
            </label>
        </div>
        <div *ngIf="showOpticsUpload">
            <input
                id="opticsFile"
                type="file"
                (change)="onFileChange($event)"
            />
        </div>
        <br />
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-flat btn-default"
            (click)="cancelClick()"
        >
            Cancel
        </button>
    </form>
</div>
