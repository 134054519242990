import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ExtraSearchParams, SearchParams, IEntitySearchParams } from '@mt-ng2/common-classes';
import { ClaimsService, ClaimValues } from '@mt-ng2/auth-module';
import { MtSearchFilterItem } from '@mt-ng2/search-filter-select-control';
import { IItemSelectedEvent, IColumnSortedEvent, SortDirection } from '@mt-ng2/entity-list-module';

import { ProductCategoryService } from '@system-select/web-services';
import { CustomSizingRuleService } from '@system-select/web-services';
import { ProductCategoriesEntityListConfig } from './product-categories.entity-list-config';
import { IProductCategory } from '../../model/interfaces/product-category';
import { entityListModuleConfig } from '../../common/shared.module';
import { ClaimTypes } from '../../model/ClaimTypes';

@Component({
    selector: 'app-product-categories',
    templateUrl: './product-categories.component.html',
})
export class ProductCategoriesComponent implements OnInit {
    productCategories: IProductCategory[];
    currentPage = 1;
    itemsPerPage = entityListModuleConfig.itemsPerPage;
    query = '';
    total: number;
    customSizingRules: MtSearchFilterItem[] = [];
    entityListConfig = new ProductCategoriesEntityListConfig();
    order = this.entityListConfig.getDefaultSortProperty();
    orderDirection: string = this.entityListConfig.getDefaultSortDirection();

    canAddProductCategory = false;

    constructor(
        private productCategoryService: ProductCategoryService,
        private customSizingRuleService: CustomSizingRuleService,
        private claimsService: ClaimsService,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.canAddProductCategory = this.claimsService.hasClaim(ClaimTypes.ProductCategories, [ClaimValues.FullAccess]);
        this.customSizingRuleService.getSearchFilterItems().subscribe((answer) => (this.customSizingRules = answer));
        this.getProductCategories();
    }

    private getSelectedFilters(filterObj: MtSearchFilterItem[]): number[] {
        return filterObj.filter((item) => item.Selected).map((item) => item.Item.Id);
    }

    private buildSearch(): ExtraSearchParams[] {
        const selectedCustomSizingRuleIds: number[] = this.getSelectedFilters(this.customSizingRules);
        const _extraSearchParams: ExtraSearchParams[] = [];

        _extraSearchParams.push(
            new ExtraSearchParams({
                name: 'CustomSizingRuleIds',
                valueArray: selectedCustomSizingRuleIds,
            }),
        );

        return _extraSearchParams;
    }

    getProductCategoriesCall(): Observable<HttpResponse<IProductCategory[]>> {
        const search = this.query;
        const _extraSearchParams: ExtraSearchParams[] = this.buildSearch();

        const searchEntity: IEntitySearchParams = {
            extraParams: _extraSearchParams,
            order: this.order,
            orderDirection: this.orderDirection,
            query: search && search.length > 0 ? search : '',
            skip: (this.currentPage - 1) * this.itemsPerPage,
            take: this.itemsPerPage,
        };

        const searchparams = new SearchParams(searchEntity);
        return this.productCategoryService.get(searchparams);
    }

    getProductCategories(): void {
        this.getProductCategoriesCall().subscribe((answer) => {
            this.productCategories = answer.body;
            this.total = +answer.headers.get('X-List-Count');
        });
    }

    search(query: string): void {
        this.currentPage = 1;
        this.query = query;
        this.getProductCategories();
    }

    filterSelectionChanged(): void {
        this.currentPage = 1;
        this.getProductCategories();
    }

    columnSorted(event: IColumnSortedEvent): void {
        this.order = event.column.sort.sortProperty;
        this.orderDirection = event.column.sort.direction === SortDirection.Desc ? 'desc' : 'asc';
        this.getProductCategories();
    }

    productCategorySelected(event: IItemSelectedEvent): void {
        window.open(`#/product-categories/${event.entity.Id as number}`);
    }
}
