<div *ngIf="windowDataSet && windowDataSet.Id > 0">
  <div class="row">
    <div class="col-md-9">
      <app-window-data-set-basic-info [windowDataSet]="windowDataSet" [canEdit]="canEdit" (onPayloadUpdate)="updatePayload($event)"></app-window-data-set-basic-info><br>
    </div>
  </div>
  <a routerLink="/window-data-sets" class="btn btn-default">Close</a>
  <button *ngIf="payload" class="btn btn-danger" (click)="archiveDataSet()">{{!payload.Archived ? 'Archive' : 'Unarchive'}}</button>
</div>
<div *ngIf="windowDataSet && windowDataSet.Id === 0">
  <div class="row">
    <div class="col-md-9">
      <app-window-data-set-basic-info [windowDataSet]="windowDataSet"></app-window-data-set-basic-info>
    </div>
  </div>
</div>
