<div *ngIf="formCreated">
    <form
        class="miles-form padded"
        [formGroup]="frameForm"
        (ngSubmit)="formSubmitted()"
    >
        <h4>Frame</h4>
        <mt-dynamic-field
            [field]="abstractFrameControls.Name"
            [form]="frameForm"
        ></mt-dynamic-field>
        <mt-dynamic-field
            [field]="abstractFrameControls.FrameTypeId"
            [form]="frameForm"
        ></mt-dynamic-field>
        <div class="m-t-md">
            <label for="thermFile">
                THERM File
                <span *ngIf="!showThermUpload" class="font-weight-normal">
                    <i class="fa fa-lg fa-check-circle-o text-success"></i>
                    <a
                        href
                        class="small p-l-sm"
                        (click)="
                            showThermUpload = true; $event.preventDefault()
                        "
                        >Overwrite?</a
                    >
                </span>
            </label>
        </div>
        <div *ngIf="showThermUpload">
            <input
                id="thermFile"
                type="file"
                (change)="onThermFileChange($event)"
                accept=".thm"
            />
        </div>
        <div class="m-t-md">
            <label for="meshFile">
                CR Mesh Data File
                <span *ngIf="!showMeshUpload" class="font-weight-normal">
                    <i class="fa fa-lg fa-check-circle-o text-success"></i>
                    <a
                        href
                        class="small p-l-sm"
                        (click)="showMeshUpload = true; $event.preventDefault()"
                        >Overwrite?</a
                    >
                </span>
            </label>
        </div>
        <div *ngIf="showMeshUpload">
            <input
                id="meshFile"
                type="file"
                (change)="onMeshFileChange($event)"
                accept=".csv"
            />
        </div>
        <br />
        <button
            type="submit"
            mtDisableClickDuringHttpCalls
            Class="btn btn-flat btn-success"
        >
            Save
        </button>
        <button
            type="button"
            class="btn btn-flat btn-default"
            (click)="cancelClick()"
        >
            Cancel
        </button>
    </form>
</div>
