import { ModuleWithProviders, NgModule } from '@angular/core';
import { ProductDocumentService } from '@system-select/web-services';
import { SharedModule } from '../common/shared.module';
import { ProductDocumentRoutingModule } from './product-document-routing.module';
import { ProductDocumentActionComponent } from './product-documents-repository/product-document-action.component';
import { ProductDocumentsRepositoryComponent } from './product-documents-repository/product-documents-repository.component';

// TODO: Check for Shared
@NgModule({
    declarations: [ProductDocumentsRepositoryComponent, ProductDocumentActionComponent],
    imports: [SharedModule, ProductDocumentRoutingModule],
})
export class ProductDocumentModule {
    static forRoot(): ModuleWithProviders<ProductDocumentModule> {
        return {
            ngModule: ProductDocumentModule,
            providers: [
                ProductDocumentService,
            ],
        };
    }
}
