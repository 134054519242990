<div class="miles-editable miles-card padded" *ngIf="!isEditing && frame.Id > 0" (mouseenter)="isHovered = true"
    (mouseleave)="isHovered = false" (click)="edit()" [style.cursor]="canEdit ? 'pointer' : 'default'">
    <h4>Frame Info
        <span class="pull-right" *ngIf="canEdit" [hidden]="!isHovered">
            <i class="fa fa-lg fa-edit"></i>
        </span>
    </h4>
    <mt-dynamic-view [config]="viewOnly"></mt-dynamic-view>
</div>
<frame-product *ngIf="isEditing && frame" [frame]="frame" (doneEditing)="endEditing()" (updatedFrame)="updateFrame($event)"></frame-product>
