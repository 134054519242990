<div class="row">
    <div class="col-md-7">
      <h2>Framing Systems</h2>
    </div>
  </div>
  <div>
    <mt-search-bar (searched)="search($event)"></mt-search-bar>
    <mt-search-filter-select [items]="framingSystemTypes" entity="Framing System Type" (selectionChanged)="getFramingSystems()"></mt-search-filter-select>
  </div>

  <mt-entity-list [entities]="framingSystems" [total]="total" [(currentPage)]="currentPage" (pageChanged)="getFramingSystems()"
    (itemSelectedEvent)="framingSystemSelected($event)"
    (columnSortedEvent)="columnSorted($event)"
    [entityListConfig]="entityListConfig">
  </mt-entity-list>

  <div *ngIf="canAddFramingSystem" class="fab-wrap-b-r">
    <a class="btn btn-primary btn-fab-lg" [routerLink]="['/framing-systems-detail']">
      <span class="fa fa-plus"></span>
    </a>
  </div>
