import { Component } from '@angular/core';
import { GlassService, GasTypeService, UserService, UserWindowTotalProductService, LeedReportService  } from '@system-select/web-services';
import { FileService } from '@system-select/common';
import moment from 'moment';
import { ISearchFilterDaterangeValue } from '@mt-ng2/search-filter-daterange-control';

@Component({
    selector: 'data-exports',
    styleUrls: ['./data-exports.component.css'],
    templateUrl: 'data-exports.component.html',
})

export class DataExportsComponent {
    startDate = moment('12/31/2022', 'MM/DD/YYYY', true).toDate();
    endDate: Date;

    constructor(
        private glassService: GlassService,
        private gasTypeService: GasTypeService,
        private userService: UserService,
        private userWindowTotalProductService: UserWindowTotalProductService,
        private leedReportService: LeedReportService,
    ) {
     }

    getGlassProductsCSV(): void {
        this.glassService.getGlassProductsCSVExport()
        .subscribe((csv: string) => {
            FileService.save(csv, 'glassproducts.csv', 'text/csv', false);
        });
    }

    getGasTypesCSV(): void {
        this.gasTypeService.getGasTypesCSVExport()
        .subscribe((csv: string) => {
            FileService.save(csv, 'gastypes.csv', 'text/csv', false);
        });
    }

    getUsersCSV(): void {
        this.userService.getUserCSVExport()
        .subscribe((csv) => {
            FileService.save(csv, 'users.csv', 'text/csv', false);
        });
    }

    getUserWindowTotalProductsCSV(): void {
        this.userWindowTotalProductService.getUserTotalProductsCSVExport()
        .subscribe((csv) => {
            FileService.save(csv, 'totalproducts.csv', 'text/csv', false);
        });
    }

    getLeedReportsCSV(): void {
        // Ensure either none or both dates are present for easier filtering
        if (this.startDate && !this.endDate) {
            this.endDate = new Date();
        }

        if (this.endDate && !this.startDate) {
            this.startDate = moment.utc('2022/12/31 00:00:00', 'YYYY/MM/DD HH:mm:ss', true).toDate();
        }

        let startDateUTC: Date = null;
        let endDateUTC: Date = null;

        if (this.startDate) {
            startDateUTC = moment(this.startDate).utc().toDate();
            endDateUTC = moment(this.endDate).utc().toDate();
        }

        this.leedReportService.exportLeedReportsCSV(startDateUTC, endDateUTC)
            .subscribe((csv) => {
                let fileName = 'LEED Reports Generated ';
                if (this.startDate) {
                    const startDateString = moment(this.startDate).format('MM-DD-YYYY');
                    const endDateString = moment(this.endDate).format('MM-DD-YYYY');
                    fileName += `${startDateString}_${endDateString}`;
                } else {
                    fileName += 'All Time';
                }

                FileService.save(csv, fileName, 'text/csv', false);
            });
    }

    onDateSelected(event: ISearchFilterDaterangeValue): void {
        this.startDate = event.startDate;
        this.endDate = event.endDate;
    }
}
