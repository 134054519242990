import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import { AuthService } from "@mt-ng2/auth-module";
import { NotificationsService } from "@mt-ng2/notifications-module";
import { UserService } from "@system-select/web-services";

import { FormGroup } from "@angular/forms";
import { common } from "@mt-ng2/common-functions";
import { DynamicField, DynamicLabel } from "@mt-ng2/dynamic-form";
import {
    IAuthUser,
    ICreateUserPayload,
    IUser,
    IUserRole,
} from "@system-select/model";
import { AuthEntityService } from "@system-select/web-services"; // added
import { AuthUserDynamicConfig } from "../../auth-entity/auth-user/auth-user.dynamic-config";
import { UserDynamicConfig } from "../user.dynamic-config";

@Component({
    selector: "app-user-basic-info",
    templateUrl: "./user-basic-info.component.html",
})
export class UserBasicInfoComponent implements OnInit {
    @Input() user: IUser;
    @Input() canEdit: boolean;
    authUser: IAuthUser;
    roles: IUserRole[];
    additionalConfigs: any[] = [];
    isEditing: boolean;
    isHovered: boolean;
    userForm: any;
    formFactory: UserDynamicConfig<IUser>;
    viewOnly: DynamicLabel[] = [];
    formObject: DynamicField[] = [];

    constructor(
        private userService: UserService,
        private notificationsService: NotificationsService,
        private authEntitiyService: AuthEntityService,
        private authService: AuthService,
        private router: Router
    ) {}

    ngOnInit(): void {
        this.isEditing = false;
        if (this.isNewUser()) {
            this.authEntitiyService
                .getAllRoles() // added
                .subscribe((answer) => {
                    this.roles = answer.body as unknown as IUserRole[];
                    this.setConfig();
                });
        } else {
            this.setConfig();
        }
    }

    private isNewUser(): boolean {
        return this.user && this.user.Id && this.user.Id > 0 ? false : true;
    }

    getAdditionalConfigs(): AuthUserDynamicConfig<IAuthUser>[] {
        const pwConfigControls: string[] = [
            "SendResetEmail",
            "Password",
            "ConfirmPassword",
        ];
        const authUser = this.isNewUser() ? null : this.user.AuthUser;
        const pwConfig = new AuthUserDynamicConfig<IAuthUser>(
            authUser,
            null,
            pwConfigControls
        );
        const roleConfig = new AuthUserDynamicConfig<IAuthUser>(
            authUser,
            this.roles
        );
        return [pwConfig, roleConfig];
    }

    setConfig(): void {
        this.formFactory = new UserDynamicConfig<IUser>(this.user);
        this.additionalConfigs = this.getAdditionalConfigs();
        if (this.isNewUser()) {
            this.isEditing = true;
            const config = this.formFactory.getForCreate(
                this.additionalConfigs
            );
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        } else {
            const config = this.formFactory.getForUpdate();
            this.viewOnly = config?.viewOnly?.map((x) => new DynamicLabel(x));
            this.formObject = config.formObject?.map(
                (x) => new DynamicField(x)
            );
        }
    }

    edit(): void {
        if (this.canEdit) {
            this.isEditing = true;
        }
    }

    cancelClick(): void {
        if (this.isNewUser()) {
            void this.router.navigate(["/users"]);
        } else {
            this.isEditing = false;
        }
    }

    formSubmitted(form: FormGroup): void {
        let passwordmatch = true;
        if (this.isNewUser()) {
            if (!this.authService.matchPassword(form)) {
                passwordmatch = false;
            }
        }

        if (form.valid && passwordmatch) {
            this.formFactory.assignFormValues(
                this.user,
                form.value.User as IUser
            );
            if (this.isNewUser()) {
                const data: ICreateUserPayload = {
                    Password: form.value.AuthUser.Password,
                    SendEmail: form.value.AuthUser.SendResetEmail || false,
                    User: this.user,
                    Username: form.value.AuthUser.Username,
                    UserRoleId: form.value.AuthUser.RoleId,
                };
                // handle new user save
                this.userService.createUser(data).subscribe((answer) => {
                    void this.router.navigate([`/users/${answer}`]);
                    this.userService.emitChange(this.user);
                    this.success();
                });
            } else {
                // handle existing user save
                this.userService
                    .updateVersion(this.user)
                    .subscribe((answer) => {
                        answer
                            ? ((this.user.Version = answer),
                              (this.isEditing = false),
                              this.success(),
                              this.userService.emitChange(this.user),
                              this.setConfig())
                            : this.error();
                    });
            }
        } else {
            if (!passwordmatch) {
                this.error("Passwords do not match");
            } else {
                common.markAllFormFieldsAsTouched(form);
                this.error();
            }
        }
    }

    error(msg?: string): void {
        if (!msg) {
            this.notificationsService.error(
                `Save failed.  Please check the form and try again.`
            );
        } else {
            this.notificationsService.error(msg);
        }
    }

    success(): void {
        this.notificationsService.success("Saved Successfully");
    }

    updateVersion(version): void {
        this.user.Version = version;
    }
}
