import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';

import { IUser } from '@system-select/model';
import { UserService } from '@system-select/web-services';

@Component({
  selector: 'app-user-header',
  templateUrl: './user-header.component.html',
})
export class UserHeaderComponent implements OnInit, OnDestroy {
  user: IUser;
  header: string;
  subscriptions: Subscription = new Subscription();

  constructor(
    private userService: UserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.subscriptions.add(this.userService.changeEmitted$.subscribe((user) => {
      this.setHeader(user);
    }));
    const id = +this.route.snapshot.paramMap.get('userId');
    if (id > 0) {
      this.userService.getById(id)
        .subscribe((user) => {
          this.setHeader(user);
        });
    } else {
      this.header = 'Add User';
      this.user = this.userService.getEmptyUser();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  setHeader(user: IUser): void {
    this.user = user;
    this.header = `User: ${this.user.FirstName} ${this.user.LastName}`;
  }

}
