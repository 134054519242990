export enum ClaimTypes {
    AppSettings = 1,
    Users = 2,
    Customers = 3,
    Customers_CanChangeStatus = 5,
    UserRoles = 4,
    Glasses = 6,
    FramingSystemCategories = 7,
    FramingSystems = 8,
    Frames = 9,
    ClimateZones = 10,
    WindowDataSets = 11,
    TotalProducts = 12,
    GlazingSystems = 13,
    Projects = 14,
    ProductCategories = 15,
    ProductDocuments = 16,
}
