import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthGuard, ClaimValues } from '@mt-ng2/auth-module';
import { ClimateZoneDetailComponent } from './climate-zone-detail/climate-zone-detail.component';
import { ClimateZonesComponent } from './climate-zone-list/climate-zones.component';
import { ClimateZoneHeaderComponent } from './climate-zone-header/climate-zone-header.component';

import { ClaimTypes } from '../model/ClaimTypes';
import { ClimateZoneService } from '@system-select/web-services';

const climateZoneEntityConfig = {
  claimType: ClaimTypes.ClimateZones,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  entityIdParam: 'climateZoneId',
  service: ClimateZoneService,
  title: 'ClimateZone Detail',
};

const climateZoneListRoleGuard = {
  claimType: ClaimTypes.ClimateZones,
  claimValues: [ClaimValues.ReadOnly, ClaimValues.FullAccess],
  title: 'ClimateZones',
};

const climateZoneAddRoleGuard = {
  claimType: ClaimTypes.ClimateZones,
  claimValues: [ClaimValues.FullAccess],
};

const climateZoneRoutes: Routes = [
  {
      canActivate: [AuthGuard],
      component: ClimateZonesComponent,
      data: climateZoneListRoleGuard,
      path: 'climate-zones',
  },
  {
      canActivate: [AuthGuard],
      children: [
        {
          component: ClimateZoneDetailComponent,
          path: '',
          pathMatch: 'full',
        },
      ],
      component: ClimateZoneHeaderComponent,
      data: climateZoneAddRoleGuard,
      path: 'climate-zones/add',
  },
  {
    canActivate: [AuthGuard],
    children: [
      {
        component: ClimateZoneDetailComponent,
        path: '',
        pathMatch: 'full',
      },

    ],
    component: ClimateZoneHeaderComponent,
    data: climateZoneEntityConfig,
    path: `climate-zones/:${climateZoneEntityConfig.entityIdParam}`,
  },
];

@NgModule({
  exports: [RouterModule],
  imports: [
    RouterModule.forChild(
      climateZoneRoutes,
    ),
  ],
})
export class ClimateZoneRoutingModule {

}
