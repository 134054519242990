<h2>Glazing System Builder</h2>

<div class="row">
    <div class="col-md-5">
        <div class="panel panel-default">
            <div class="panel-heading">Glazing System</div>
            <div class="panel-body glazing-header-panel">
                <div class="form-group">
                    <label># Layers</label>
                    <div class="ib-container v-align-middle">
                        <input type="text" class="form-control p-sm text-center" style="width: 40px" [(ngModel)]="numberOfLayers" disabled />
                        <div>
                            <div>
                                <button class="btn btn-xs btn-flat btn-default" (click)="addLayer(); tryCalculate()"><i class="fa fa-fw fa-arrow-up"></i></button>
                            </div>
                            <div>
                                <button class="btn btn-xs btn-flat btn-default" (click)="removeLayer(); tryCalculate()"><i class="fa fa-fw fa-arrow-down"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group">
                    <label>Height (mm)</label>
                    <input type="number" (change)="tryCalculate()" class="form-control" [(ngModel)]="height" />
                </div>
                <div class="form-group">
                    <label>Width (mm)</label>
                    <input type="number" (change)="tryCalculate()" class="form-control" [(ngModel)]="width" />
                </div>

                <hr />
            </div>
            <div class="panel-body glazing-detail-panel">
                <div class="form-group">
                    <label>Glass 1</label>
                    <select [(ngModel)]="glassLayers[0]" (change)="tryCalculate()" class="form-control">
                        <option *ngFor="let glass of glasses" [ngValue]="glass">{{glass.Name}}</option>
                    </select>
                </div>

                <ng-template ngFor let-layerNum let-i="index" [ngForOf]="repeatableLayers">
                    <div class="form-group">
                        <div class="row">
                            <div class="col-xs-6">
                                <label>Gap {{ layerNum - 1 }}</label>
                                <select [(ngModel)]="gapLayerTypes[i]" (change)="tryCalculate()" class="form-control">
                                    <option *ngFor="let gas of gasTypes" [ngValue]="gas">{{gas.Name}}</option>
                                </select>
                            </div>
                            <div class="col-xs-6">
                                <label>Width (mm)</label>
                                <input type="number" [(ngModel)]="gapLayerWidths[i]" (change)="tryCalculate()" class="form-control" />
                            </div>
                        </div>
                    </div>

                    <div class="form-group">
                        <label>Glass {{ layerNum }}</label>
                        <select [(ngModel)]="glassLayers[i + 1]" (change)="tryCalculate()" class="form-control">
                            <option *ngFor="let glass of glasses" [ngValue]="glass">{{glass.Name}}</option>
                        </select>
                    </div>
                </ng-template>
            </div>
            <!-- <div class="panel-body">
                <div class="row">
                    <div class="col-xs-4 col-xs-offset-4">
                        <button class="btn btn-success btn-block" (click)="calculate()">Calc</button>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="panel panel-default">
            <div class="panel-heading">Framing System</div>
            <div class="panel-body">
                <select [(ngModel)]="framingSystem" (change)="tryCalculate()" class="form-control">
                    <option *ngFor="let framingSystem of framingSystems" [ngValue]="framingSystem">{{framingSystem.Name}}</option>
                </select>
            </div>
        </div>
    </div>
    <div class="col-md-7">
        <div class="results-panel">
            <div class="panel panel-default">
                <div class="panel-heading">Center of Glass Results, based on Standard 2000mmx2000mm sizing</div>
                <div class="panel-body">
                    <table *ngIf="glazingResult" class="table table-bordered table-condensed table-small glazing-table">
                        <tr>
                            <th>Thickness (inches)</th>
                            <th>Visible Trans. (%)</th>
                            <th>Visible Refl. Out (%)</th>
                            <th>Visible Refl. In (%)</th>
                            <th>UV Trans. (%)</th>
                            <th>Solar Trans. (%)</th>
                            <th>Solar Refl. Out (%)</th>
                            <th>Winter U-factor</th>
                            <th>Summer U-factor</th>
                            <th>Shading Coeff.</th>
                            <th>Solar Heat Gain Coeff.</th>
                            <th>Relative Heat Gain</th>
                            <th>Light to Solar Gain</th>
                        </tr>
                        <tr>
                            <td>{{ mmToIn(glazingResult.Thickness) | number: '1.2-2' }}</td>
                            <td>{{ glazingResult.VisibleTransmittance | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.VisibleReflectanceOutside | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.VisibleReflectanceInside | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.UVTransmittance | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.SolarTransmittance | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.SolarReflectanceOutside | percent: '1.0-0' }}</td>
                            <td>{{ glazingResult.UFactorWinter | number: '1.2-2' }}</td>
                            <td>{{ '?' }}</td>
                            <td>{{ glazingResult.ShadingCoefficient | number: '1.2-2' }}</td>
                            <td>{{ glazingResult.Shgc | number: '1.2-2' }}</td>
                            <td>{{ '?' }}</td>
                            <td>{{ glazingResult.LightToSolarGain | number: '1.2-2' }}</td>
                        </tr>
                    </table>
                    <span class="font-style-italic" *ngIf="!glazingResult">Build a glazing system to receive performance results.</span>
                </div>
            </div>
            <div class="panel panel-default">
                <div class="panel-heading">Total Product Results</div>
                <div class="panel-body">
                    <div *ngIf="totalWindowResult">
                        <table class="table table-bordered table-condensed total-window-table">
                            <tr>
                                <th>U-factor</th>
                                <th>SHGC</th>
                                <th>VT</th>
                                <th>CR</th>
                            </tr>
                            <tr>
                                <td>{{ totalWindowResult.UValue | number: '1.2-2' }}</td>
                                <td>{{ totalWindowResult.Shgc | number: '1.2-2' }}</td>
                                <td>{{ totalWindowResult.VisibleTransmittance | number: '1.2-2' }}</td>
                                <td>{{ totalWindowResult.CondensationResistance !== null ? (totalWindowResult.CondensationResistance | number: '1.0-0') : 'N/A' }}</td>
                            </tr>
                        </table>
                        <p class="m-t-lg small">The Total Product results were simulated using 2 instances of the glazing system built on this page, along with the following framing specs:</p>
                        <div class="row">
                            <div class="col-xs-5">
                                <table class="table table-bordered table-condensed table-small">
                                    <tr>
                                        <th colspan="2" class="text-center">Window Properties</th>
                                    </tr>
                                    <tr>
                                        <th>Type</th>
                                        <td>Glazed Wall System</td>
                                    </tr>
                                    <tr>
                                        <th>Width</th>
                                        <td>2000 mm</td>
                                    </tr>
                                    <tr>
                                        <th>Height</th>
                                        <td>2000 mm</td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </div>
                    <span class="font-style-italic" *ngIf="!totalWindowResult">Build a glazing system to receive performance results.</span>
                </div>
            </div>
            <ngx-loading [show]="loadingResult"></ngx-loading>
        </div>
    </div>
</div>
