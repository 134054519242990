import { IEntityListConfig, EntityListConfig, EntityListColumn } from '@mt-ng2/entity-list-module';
import { IClimateZone } from '@system-select/model';

export class ClimateZonesEntityListConfig extends EntityListConfig {

    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
              new EntityListColumn({
                  name: 'Name',
              }),
              new EntityListColumn({
                accessorFunction: (climateZone: IClimateZone) => {
                    if (climateZone.ProductCategory) {
                        return climateZone.ProductCategory.Name;
                    }
                    return '';
                 },
                name: 'Product Category',
            }),
            ],
        };
        super(listConfig);
    }
}
