import { ModuleWithProviders, NgModule } from '@angular/core';

import { ProductCategoryRoutingModule } from './product-category-routing.module';
import { ProductCategoryAddComponent } from './product-category-add/product-category-add.component';
import { ProductCategoryBasicInfoComponent } from './product-category-basic-info/product-category-basic-info.component';
import { ProductCategoryDetailComponent } from './product-category-detail/product-category-detail.component';
import { ProductCategoriesComponent } from './product-category-list/product-categories.component';
import { ProductCategoryHeaderComponent } from './product-category-header/product-category-header.component';

import { CustomSizingRuleService } from '@system-select/web-services';
import { ProductCategoryService } from '@system-select/web-services';
import { SharedModule } from '../common/shared.module';
import { ProductSubCategoriesListComponent } from './product-sub-categories/product-sub-categories-list.component';
import { ProductSubCategoryComponent } from './product-sub-categories/product-sub-category.component';

@NgModule({
    declarations: [
        ProductCategoriesComponent,
        ProductCategoryHeaderComponent,
        ProductCategoryAddComponent,
        ProductCategoryDetailComponent,
        ProductCategoryBasicInfoComponent,
        ProductSubCategoriesListComponent,
        ProductSubCategoryComponent],
    imports: [SharedModule, ProductCategoryRoutingModule],
})
export class ProductCategoryModule {
    static forRoot(): ModuleWithProviders<ProductCategoryModule> {
        return {
            ngModule: ProductCategoryModule,
            providers: [
                CustomSizingRuleService,
                ProductCategoryService,
            ],
        };
    }
}
