import { EntityListConfig, SortDirection, EntityListColumn, EntityListDeleteColumn, IEntityListConfig } from '@mt-ng2/entity-list-module';
import { ICustomerPartial } from '../../model/partials/customer';

export class CustomersEntityListConfig extends EntityListConfig {
    constructor() {
        const listConfig: IEntityListConfig = {
            columns: [
                new EntityListColumn({
                    name: 'Name',
                    sort: {
                        defaultDirection: SortDirection.Asc,
                    },
                }),
                new EntityListColumn({
                    accessors: ['CustomerPhones'],
                    name: 'Phone',
                    pipes: ['primary', 'phone'],
                }),
                new EntityListColumn({
                    accessors: ['CustomerSource', 'Name'],
                    name: 'Source',
                }),
                new EntityListColumn({
                    accessors: ['CustomerStatus', 'Name'],
                    name: 'Status',
                }),
            ],
            delete: new EntityListDeleteColumn({
                columnHtml: (entity: ICustomerPartial) =>
                    entity.Id === 5008
                        ? '<div><i class="fa fa-2x fa-trash" aria-hidden="true"></i><i class="fa fa-2x fa-undo overlay-fa" data-fa-transform="shrink-8 down-1"></i></div>'
                        : '',
                confirm: {
                    confirmButtonText: 'Yes, I am sure!',
                    text: 'Are you sure you want to modify this record?',
                    title: 'Archive Status',
                },
                headerText: 'Archive',
            }),
            rowClass: (entity: ICustomerPartial) =>
                entity.Id === 5008 ? 'archived' : null,
        };
        super(listConfig);
    }
}
