import { IMetaItem } from "@mt-ng2/base-service";
import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';

import { IExpandableObject } from '../expandable-object';
import { IContact } from '../interfaces/contact';
import { IAddress } from '../interfaces/address';
import { IContactStatus } from '../interfaces/contact-status';

export interface IContactDynamicControlsParameters {
    formGroup?: string;
    addresses?: IAddress[];
    statuses?: IContactStatus[];
}

export class ContactDynamicControls {

    formGroup: string;
    addresses: IAddress[];
    statuses: IContactStatus[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private contact?: IContact, additionalParameters?: IContactDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'Contact';
        this.addresses = additionalParameters && additionalParameters.addresses || undefined;
        this.statuses = additionalParameters && additionalParameters.statuses || undefined;

        this.Form = {
            AddressId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Address',
                name: 'AddressId',
                options: this.addresses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.contact && this.contact.AddressId || null,
            }),
            Email: new DynamicField({
                formGroup: this.formGroup,
                label: 'Email',
                name: 'Email',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50), Validators.email ],
                validators: { 'required': true, 'maxlength': 50, 'email': true },
                value: this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(50) ],
                validators: { 'required': true, 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
            }),
            StatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Status',
                name: 'StatusId',
                options: this.statuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.contact && this.contact.StatusId || null,
            }),
            Title: new DynamicField({
                formGroup: this.formGroup,
                label: 'Title',
                name: 'Title',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
            }),
        };

        this.View = {
            AddressId: new DynamicLabel({
			    label: 'Address',
			    value: getMetaItemValue(this.addresses as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('AddressId') && this.contact.AddressId !== null ? this.contact.AddressId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Email: new DynamicLabel({
			    label: 'Email',
			    value: this.contact && this.contact.hasOwnProperty('Email') && this.contact.Email !== null ? this.contact.Email.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            FirstName: new DynamicLabel({
			    label: 'First Name',
			    value: this.contact && this.contact.hasOwnProperty('FirstName') && this.contact.FirstName !== null ? this.contact.FirstName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            LastName: new DynamicLabel({
			    label: 'Last Name',
			    value: this.contact && this.contact.hasOwnProperty('LastName') && this.contact.LastName !== null ? this.contact.LastName.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            StatusId: new DynamicLabel({
			    label: 'Status',
			    value: getMetaItemValue(this.statuses as unknown as IMetaItem[], this.contact && this.contact.hasOwnProperty('StatusId') && this.contact.StatusId !== null ? this.contact.StatusId : null) ?? "",
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Select,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
            Title: new DynamicLabel({
			    label: 'Title',
			    value: this.contact && this.contact.hasOwnProperty('Title') && this.contact.Title !== null ? this.contact.Title.toString() : '',
			    type: new DynamicFieldType({
			        fieldType: DynamicFieldTypes.Input,
			        inputType: null,
			        scale: null,
			    })
			}
            ),
        };

    }
}
