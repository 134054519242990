import { HashLocationStrategy, LocationStrategy } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { ErrorHandler, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";

import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { NgxMaskModule } from "ngx-mask";
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";

import { AuthModule } from "@mt-ng2/auth-module";
import {
    EnvironmentModule,
    EnvironmentModuleConfigToken,
} from "@mt-ng2/environment-module";
import { LoginModuleConfigToken, MtLoginModule } from "@mt-ng2/login-module";
import { NotificationsModule } from "@mt-ng2/notifications-module";

import { AppSettingsService } from "@system-select/web-services";
import { AppRoutingModule } from "./app-routing.module";
import { AppSettingsComponent } from "./app-settings.component";
import { AppComponent } from "./app.component";
import { LoginConfigOverride } from "./common/configs/login.config";
import { SharedModule } from "./common/shared.module";
import { CustomerModule } from "./customers/customer.module";
import { environment } from "./environments/environment";
import { AppNavModule } from "./nav/app-nav.module";
import { UserRoleModule } from "./user-roles/user-roles.module";
import { UserModule } from "./users/user.module";

import { ToastrModule } from "ngx-toastr";

import { GlassModule } from "./glasses/glass.module";

import { CookieModule } from "@mt-ng2/cookie";
import { MtDisableDuringHttpCallsModule } from "@mt-ng2/disable-during-http-calls";
import { BreckErrorHandler } from "@mt-ng2/errors-module";
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "ngx-loading";
import { ClimateZoneModule } from "./climate-zones/climate-zone.module";

import { DataExportsModule } from "./data-exports/data-exports.module";
import { FrameModule } from "./frames/frame.module";
import { ProductCategoryModule } from "./product-categories/product-category.module";
import { ProductDocumentModule } from "./product-documents/product-document.module";
import { WindowDataSetModule } from "./window-data-sets/window-data-set.module";

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, AppSettingsComponent],
    imports: [
        BrowserModule,
        NgbModule,
        SharedModule.forRoot(),
        HttpClientModule,
        NgProgressModule.withConfig({
            color: "#ff8b56",
            spinnerPosition: "left",
            thick: false,
        }),
        NgProgressHttpModule,
        CookieModule.forRoot(),
        EnvironmentModule,
        AuthModule.forRoot(),
        CustomerModule.forRoot(),
        UserModule.forRoot(),
        GlassModule.forRoot(),
        FrameModule.forRoot(),
        UserRoleModule.forRoot(),
        ClimateZoneModule.forRoot(),
        WindowDataSetModule.forRoot(),
        DataExportsModule.forRoot(),
        ProductCategoryModule.forRoot(),
        ProductDocumentModule.forRoot(),
        AppRoutingModule,
        NotificationsModule,
        NgxMaskModule.forRoot(),
        AppNavModule.forRoot(),
        ToastrModule.forRoot({
            positionClass: "toast-top-left",
        }),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.circle,
            backdropBackgroundColour: "rgba(0,0,0,0.15)",
            primaryColour: "#ffffff",
            secondaryColour: "#bbb",
            tertiaryColour: "#ffffff",
        }),
        MtLoginModule,
        MtDisableDuringHttpCallsModule.forRoot(),
    ],
    providers: [
    { provide: ErrorHandler, useClass: BreckErrorHandler },
    { provide: LoginModuleConfigToken, useValue: LoginConfigOverride },
    AppSettingsService,
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    { provide: EnvironmentModuleConfigToken, useValue: environment },
],
})
export class AppModule {}
