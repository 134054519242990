
import { ClaimTypes } from '../model/ClaimTypes';
import { ClaimValues } from '@mt-ng2/auth-module';
import { KeyboardKeys } from '@mt-ng2/keyboard-shortcuts-module';
import { NavSidebarRowItem, NavSidebarParentRowItem } from '@mt-ng2/nav-module';

// tslint:disable:object-literal-sort-keys
export const appNavMenu: (NavSidebarRowItem | NavSidebarParentRowItem)[] = [
    new NavSidebarParentRowItem({
        children: [
            new NavSidebarRowItem({
                addLink: '/users/add',
                addClaimType: ClaimTypes.Users,
                claimType: ClaimTypes.Users,
                content: 'Users',
                icon: 'fa fa-fw fa-user',
                link: '/users',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'U'],
                    description: 'go to Users',
                },
            }),
            new NavSidebarRowItem({
                addLink: '/roles/add',
                addClaimType: ClaimTypes.UserRoles,
                claimType: ClaimTypes.UserRoles,
                content: 'User Roles',
                icon: 'fa fa-fw fa-lock',
                link: '/roles',
                shortcut: {
                    keyBinding: [KeyboardKeys.Alt, KeyboardKeys.Shift, 'R'],
                    description: 'go to User Roles',
                },
            }),
        ],
        claimType: ClaimTypes.AppSettings,
        content: 'Administration',
        icon: 'fa fa-fw fa-university',
        settingsLink: '/settings',
        settingsClaimType: ClaimTypes.AppSettings,
    }),
    new NavSidebarRowItem({
        addLink: '/glasses/add',
        addClaimType: ClaimTypes.Glasses,
        claimType: ClaimTypes.Glasses,
        content: 'Glass Products',
        icon: 'fa fa-fw fa-window-maximize',
        link: '/glasses',
    }),
    new NavSidebarRowItem({
        addLink: '/climate-zones/add',
        addClaimType: ClaimTypes.ClimateZones,
        claimType: ClaimTypes.ClimateZones,
        content: 'Climate Zones',
        icon: 'fa fa-fw fa-sun-o',
        link: '/climate-zones',
    }),
    new NavSidebarRowItem({
        addLink: '/window-data-sets/add',
        addClaimType: ClaimTypes.WindowDataSets,
        claimType: ClaimTypes.WindowDataSets,
        content: 'Window Data Sets',
        icon: 'fa fa-fw fa-database',
        link: '/window-data-sets',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.ProductCategories,
        content: 'Product Categories',
        icon: 'fa fa-fw fa-database',
        link: '/product-categories',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.ProductCategories,
        content: 'Data Exports',
        icon: 'fa fa-fw fa-database',
        link: '/data-exports',
    }),
    new NavSidebarRowItem({
        claimType: ClaimTypes.ProductDocuments,
        claimValues: [ClaimValues.FullAccess],
        content: 'Product Documents',
        icon: 'fa fa-fw fa-newspaper-o',
        link: '/product-documents',
    }),
];
// tslint:enable:object-literal-sort-keys
