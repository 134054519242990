<div *ngIf="frame && frame.Id > 0">
    <div class="row">
      <div class="col-md-6">
        <app-frame-basic-info [frame]="frame" [canEdit]="canEdit"></app-frame-basic-info><br>
      </div>
      <div class="col-md-6">
      </div>
    </div>
    <a routerLink="/frames" class="btn btn-default">Close</a>
  </div>
  <div *ngIf="frame && frame.Id === 0">
    <div class="row">
      <div class="col-md-6">
        <app-frame-basic-info [frame]="frame"></app-frame-basic-info>
      </div>
    </div>
  </div>
